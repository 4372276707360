import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateHelper } from "@osapp/helpers";
import { Invoice } from "apps/idl/src/modules/facturation/models/invoice";
import { FacturationService } from "../../../../../modules/facturation/facturation.service";
import { ControlConfig } from "../../../shared/enums/ControlConfig";
import { EEtatsReglement } from "../../../shared/enums/EEtatsReglement";
import { EModesReglement } from "../../../shared/enums/EModesReglement";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";


@Component({
	selector: "di-mode-de-reglement",
	templateUrl: "./mode-de-reglement.component.html",
	styleUrls: ["./mode-de-reglement.component.scss"],
})
export class ModeDeReglementComponent implements OnInit {


	@Input() facture: Invoice;
	public modes = Object.values(EModesReglement);
	public selectedMode : string;

	public form: FormGroup;
	public controlsConfig: ControlConfig[];
	public isValid : boolean = false;

	public etats = Object.values(EEtatsReglement);
	public selectedEtat: string;

	constructor(
		private fb: FormBuilder,	
		private svcDrawerPopover: DrawerPopoverService,
		private svcInvoice : FacturationService
		) {}

	ngOnInit(): void {

		this.selectedEtat = !this.facture.statusPat || (this.facture.statusPat && this.facture.statusPat === EEtatsReglement.aRegler.status) ? EEtatsReglement.paye.status : this.facture.statusPat;
		this.selectedMode = !this.facture.statusPat || (this.facture.statusPat && this.facture.statusPat === EEtatsReglement.aRegler.status) ? EModesReglement.carte_bancaire : this.facture.modeReglementPat ?? '' ;

		this.controlsConfig = [
      { name: 'date', value: this.facture.datePat ?? DateHelper.toDateUrl(new Date()), validators : Validators.required },
			{ name: 'commentaire', value: this.facture.commentairePat ?? '' }
    ];

		this.form = this.fb.group({});
      this.controlsConfig.forEach(control => {
        this.form.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
			this.formValid();
	}

	public selectedEtatClick($event,etat)
	{
		this.selectedEtat = etat;
		this.selectedMode = (this.selectedEtat === EEtatsReglement.paye.status) ? this.facture.modeReglementPat ?? EModesReglement.carte_bancaire : '';
		this.formValid();

	}

	public selectedModeClick($event,mode)
	{
		this.selectedMode = mode;
		this.formValid();

	}

	private formValid()
	{
		this.isValid = !!this.selectedEtat && (this.selectedEtat !== EEtatsReglement.paye.status || (this.selectedMode !== null && this.selectedMode !== ''));
	}

	public handleSubmit(event: Event): void {
    event.preventDefault();
		if (this.form.valid) {
      const formValues = this.form.value;
      let formInvoice = {}
			formInvoice = { ...this.facture }
      formInvoice = {
        ...formInvoice,
        ...formValues
      }
			this.saveInvoice(formInvoice)
		}
	}
	
	private saveInvoice(transValue :any)
	{
		let invoice : Invoice;
		invoice = this.facture
		invoice.statusPat = this.selectedEtat;
		invoice.commentairePat = transValue.commentaire;
		invoice.modeReglementPat = this.selectedEtat === EEtatsReglement.paye.status ? this.selectedMode : '';
		invoice.datePat = transValue.date;
		this.svcInvoice.saveInvoice(invoice).subscribe({
			next: () => {
					this.svcDrawerPopover.close();
			},
			error: (error) => {
			}
		});
	}

	public onCancel() {
		this.svcDrawerPopover.close();
	}
}
