<lua-overlay [@drawerState]="drawerState" (click)="handleOverlayClick($event)">
  <div
    [@drawerState]="drawerState"
    class="lua-swipeable-drawer"
    (click)="$event.stopPropagation()"
  >
    <div *ngIf="title" class="drawer-header">
      <lua-bouton-icone
        *ngIf="showGoBackButton"
        iconName="arrow_back"
        color="CouleurPrimaire"
        (click)="handleGoBack($event)"
      ></lua-bouton-icone>
      <strong class="drawer-title">{{ title }}</strong>
      <lua-bouton-icone
        iconName="close"
        color="CouleurPrimaire"
        (click)="handleCloseButton($event)"
      ></lua-bouton-icone>
    </div>
    <div class="drawer-content">
      <ng-content></ng-content>
      <ng-container #drawerContent></ng-container>
    </div>
  </div>
</lua-overlay>
