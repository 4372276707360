<div class="di-form-retrocession">
	<main *ngIf="utilisateurs" class="form-retrocession-content">
		<header class="form-retrocession-header">
			<div class="titre">
				<div class="soustitre">
					<h1>{{retrocession?._id ? 'Modification de la' : 'Nouvelle'}} rétrocession</h1>
				</div>
			</div>
		</header>
		<form [formGroup]="retrocessionForm" (ngSubmit)="validForm($event)">
			<h2>Qui est le remplaçant ?</h2>
			<section class="user-list">
				<ng-container *ngFor="let utilisateur of utilisateurs">
					<lua-papier class="user" [ngClass]="mode" [mode]="outlined" [fullWidth]="true" [selectionnable]="true" [selected]="utilisateur === userSelected" (click)="handleClick($event,utilisateur)">
						<lua-objet-utilisateur [firstName]="utilisateur.firstName" [lastName]="utilisateur.lastName"
							[info]="utilisateur.email" [fullWidth]="true" [tags]="utilisateur.tags" [avatarColor]="utilisateur.avatarCouleur">
						</lua-objet-utilisateur>
					</lua-papier>
				</ng-container>
			</section>
			<h2 class="section-titre">Quelle est la période de remplacement ? </h2>
			<section class="periode">
				<lua-input 	[fullWidth]="true" 
										placeholder="Début de la période" 
										label="Début de la période" 
										type="date"
										formControlName="dateDebut"
										(change)="onChangeValueDateDebut()"></lua-input>
				<lua-input 	[fullWidth]="true" 
										placeholder="Fin de la période" 
										label="Fin de la période" 
										type="date"
										formControlName="dateFin"
										(change)="onChangeValueDateFin()"></lua-input>
			</section>
			<h2 class="section-titre">Quel est le pourcentage de rétrocession? </h2>
			<section class="pourcentage">
				<div class="input-item">
					<p class="flex-item">Actes et majorations réalisés</p>
					<div class="flex-item">
						<lua-input class="flex-item"
							formControlName="poucentageActeMajo"
							type="number"
							[fullWidth]="false" 
							min="0" max="100"
							(input)="validateInput($event)"></lua-input>
						<p class="flex-item">%</p>
					</div>
				</div>
				<div class="input-item">
					<p class="flex-item">Indemnités</p>
					<div class="flex-item">
						<lua-input class="flex-item"
							formControlName="pourcentageIndem"
							type="number"
							[fullWidth]="false" 
							min="0" max="100"
							(input)="validateInput($event)"></lua-input>
						<p class="flex-item flex-1">%</p>
					</div>
				</div>
			</section>
			<h2 class="section-titre">Commentaire</h2>
			<section class="Commentaire">
				<lua-input label="Saisir un commentaire sur la rétrocession" [multiline]="true" formControlName="commentaire"
							placeholder="Saisir un commentaire sur la rétrocession" [fullWidth]="true"></lua-input>
			</section>
			<div class="bouttons">
				<lua-action-principale [isDisabled]="!retrocessionForm.valid || !userSelected || !validDates"
					libelle="Enregistrer"
					(click)="validForm($event)"></lua-action-principale>
				<lua-bouton-texte [fullWidth]="true" libelle="Annuler" (click)="goBack()"></lua-bouton-texte>
			</div>
		</form>
	</main>
</div>