import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-icone',
  templateUrl: './icone.component.html',
  styleUrls: ['./icone.component.css'],
})
export class IconeComponent implements OnInit, OnChanges {
  @Input()
  public mode: 'filled' | 'outlined' | '' = 'filled';
  @Input() iconName?: string;
  @Input() customIconName?: string;
  @Input() color = 'Blanc';
  @Input() height?: string;
  @Input() width?: string;
  @Input() fontSize?: string;
  @Input() isIcon = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );

    if (this.width) {
      this.el.nativeElement.style.setProperty('--my-width', `${this.width}`);
    }
    if (this.height) {
      this.el.nativeElement.style.setProperty('--my-height', `${this.height}`);
    }
    if (this.fontSize) {
      this.el.nativeElement.style.setProperty(
        '--my-font-size',
        `${this.fontSize}`
      );
    }

    this.setModeSettings();

    if (this.customIconName) {
      this.iconName = '';
    }
  }

  private setModeSettings() {
    if (!this.isIcon && this.mode === 'filled') {
      this.el.nativeElement.style.setProperty(
        '--variation-settings',
        "'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48"
      );
    } else {
      this.el.nativeElement.style.setProperty(
        '--variation-settings',
        "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48"
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['color']) {
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--${this.color})`
      );
    }

    if (changes['mode']) {
      this.setModeSettings();
    }
  }

  public getClasses(): {[key: string]: boolean} {
    return {
      'material-icons': this.mode === 'filled' && this.isIcon,
      'material-icons-outlined': this.mode === 'outlined' && this.isIcon,
      'material-symbols-outlined': !this.isIcon,

      'icon-size': !!this.fontSize,
      'custom-icon-size': !!this.width && !!this.height,
    };
  }
}
