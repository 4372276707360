import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {ESize} from '../../enums/esize';

@Component({
  selector: 'lua-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input()
  public size = ESize.M;

  @Input()
  public color = 'CouleurPrimaire';

  @Input()
  public firstName = '';

  @Input()
  public lastName = '';

  @Input()
  public withTooltip = true;

  public firstNameLetter = '';
  public lastNameLetter = '';
  public fullName = '';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.firstNameLetter = this.firstName.charAt(0).toUpperCase();
    this.lastNameLetter = this.lastName.charAt(0).toUpperCase();
    this.fullName = `${this.firstName} ${this.lastName.toUpperCase()}`;
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.firstNameLetter = this.firstName.charAt(0).toUpperCase();
    this.lastNameLetter = this.lastName.charAt(0).toUpperCase();
    this.fullName = `${this.firstName} ${this.lastName.toUpperCase()}`;
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
    };
  }
}
