import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EEtatsFacture } from "../../../shared/enums/EEtatsFacture";
import { EEtatsReglement } from "../../../shared/enums/EEtatsReglement";

@Component({
	selector: "di-part-facturation",
	templateUrl: "./part-facturation.component.html",
	styleUrls: ["./part-facturation.component.scss"],
})
export class PartFacturationComponent implements OnInit,OnChanges{
	@Input() label: string = "";
	@Input() amount?: number;
	@Input() status: string = EEtatsFacture.aEnvoyer.status;
	@Input() labelStatus?: string;
	@Input() avoir: boolean = false;
	statusColor: string = '';
	statusStyle: string = '';
	statusText: string = '';
	statusClass: string = '';
	public showtags : boolean = true;
	ngOnInit(): void {
		this.updateStatusValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.updateStatusValues();
	}

	private updateStatusValues(): void {
		this.statusClass = this.getStatusClass();
		if(this.avoir || !this.amount || this.amount === 0)
		{
			this.showtags = false;
		}
	}
	
	getStatusStyle(): { color: string; style: string; text: string } {
		switch (this.status) {
			case "paid":
				return { color: "OK", style: "filled", text: "Payé" };
			case "sent":
				return { color: "Info", style: "outlined", text: "Envoyé" };
			case "paper":
				return { color: "Noir80", style: "outlined", text: "Papier" };
			case "rejected":
				return { color: "Error", style: "filled", text: "Rejet" };
			case "toSend":
			default:
				return { color: "Noir80", style: "outlined", text: "A envoyer" };
		}
	}
	getStatusClass(): string {
		if (this.avoir) return "negative-amount"
		switch (this.status) {
			case EEtatsFacture.paye.status:
			case EEtatsReglement.paye.status:
				return "status-paid";
			case EEtatsFacture.envoyer.status:
			case EEtatsReglement.envoyer.status:
				return "status-sent";
			case EEtatsFacture.papier.status:
				return "status-paper";
			case EEtatsFacture.rejet.status:
			case EEtatsReglement.impaye.status:
				return "status-rejet";
			case EEtatsFacture.aEnvoyer.status:
			case EEtatsReglement.aRegler.status:
				return "status-toSend";
			default:
				return "";
		}
	}
}
