<label
  class="lua-checkbox"
  [class.disabled]="disabled"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <input
    type="checkbox"
    [disabled]="disabled"
    [checked]="checked"
    (change)="onCheckboxChange()"
  />
  <span *ngIf="!iconName" class="checkmark"></span>
  <lua-icone
    *ngIf="iconName && checked"
    [iconName]="iconName"
    mode="filled"
    color="CouleurPrimaire"
    fontSize="20px"
    [isIcon]="false"
  >
  </lua-icone>

  <lua-icone
    *ngIf="iconName && !checked"
    mode="outlined"
    [iconName]="iconName"
    color="CouleurPrimaire"
    fontSize="20px"
    [isIcon]="false"
  >
  </lua-icone>
  <p *ngIf="label" class="label-text">{{ label }}</p>
</label>
