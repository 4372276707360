import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import {TabComponent} from '../tab/tab.component';

@Component({
  selector: 'lua-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.css'],
})
export class TabGroupComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  @Input() selectTab: (tabLabel: string) => void = () => {};

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);
    if (activeTabs.length === 0 && this.tabs.length > 0) {
      this.selectTab(this.tabs.first.label);
    }
  }

  handleTabClick(tab: TabComponent) {
    if (!tab.disabled) {
      this.tabs.toArray().forEach(t => (t.active = false));
      tab.active = true;
      this.selectTab(tab.label);
    }
  }
}
