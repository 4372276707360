import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-objet-cabinet',
  templateUrl: './objet-cabinet.component.html',
  styleUrls: ['./objet-cabinet.component.css'],
})
export class ObjetCabinetComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() name = '';

  @Input() address = '';

  @Input() lightColors = false;

  getClasses() {
    return {
      light: this.lightColors,
    };
  }
}
