import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-tuile',
  templateUrl: './tuile.component.html',
  styleUrls: ['./tuile.component.css'],
})
export class TuileComponent implements OnInit {
  @Input()
  disabled = false;

  @Input()
  iconName = '';

  @Input()
  label = '';

  @Input()
  badgeCount = 0;

  @Input()
  color = 'CouleurPrimaire';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  getClasses(): {[key: string]: boolean} {
    return {
      disabled: this.disabled,
    };
  }
}
