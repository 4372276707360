<div class="di-retrocession-item" (click)="selectRetrocession()">
	<lua-papier
		mode="outlined"
		[fullWidth]="true"
		[selected]="selected"
		[selectionnable]="true"
	>
		<div class="item-content">
			<div class="content-start">
				<lua-objet-utilisateur
					[firstName]="retrocession?.infirmier?.firstName"
					[lastName]="retrocession?.infirmier?.lastName"
					[info]="period"
					[avatarColor]="retrocession?.infirmier?.avatarCouleur"
				>
				</lua-objet-utilisateur>
			</div>
			<div class="end-item">
				<lua-icone
					iconName="arrow_forward_ios"
					color="CouleurPrimaire"
					fontSize="30px"
				></lua-icone>
			</div>
		</div>
	</lua-papier>
</div>
