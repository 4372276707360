<div class="panneau-patient-amo__container">
	<div class="container__content">
		<form [formGroup]="amoForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs">
				<div class="form-inputs form-inputs__bg-white">
					<lua-alerte *ngIf="!secured" [filled]="true" type="warning" [message]="securedMessage">
					</lua-alerte>
					<lua-input [fullWidth]="true" placeholder="Numéro de sécurité sociale" label="Numéro de sécurité sociale"
						formControlName="numeroSecuriteSociale" (input)="formatSSN()"></lua-input>
					<div class="form-inputs__cpam">
						<lua-input [fullWidth]="true" placeholder="CPAM" label="CPAM" formControlName="cpam"
							(valueChange)="onInputChange($event)" (click)="onInputClick()" (focusin)="onInputClick()"
							(keydown)="onInputClick()"></lua-input>
						<div *ngIf="showOptions" class="cpam__options">
							<div *ngFor="let option of filteredCpam" (click)="onOptionClick(option)" class="options__item">
								<span>{{ option.label }}</span>
								<span>{{ getFormatedId(option) }}</span>
							</div>
						</div>
					</div>
					<lua-switch (clickSwitch)="toggleTierPayant($event)" label="Pratiquer le tiers payant AMO"
						[isChecked]="tierPayant"></lua-switch>
					<lua-select class="mb-10" label="Le patient est" [options]="qualites" formControlName="qualite">
					</lua-select>
					<lua-select label="Pièce justificative" [options]="piecesJustificatives" formControlName="pieceJustificative"
						class="mb-10">
					</lua-select>
					<lua-filter-date-picker *ngIf="amoForm.contains('datePieceJustificative')"
						label="Date de la pièce justificative" formControlName="datePieceJustificative"></lua-filter-date-picker>
					<lua-select label="Garantie" [options]="garanties" formControlName="garantie">
					</lua-select>
					<h2 class="content__subtitle">Période de couverture</h2>
					<lua-filter-date-picker label="Début de couverture" formControlName="dateDebut"></lua-filter-date-picker>
					<lua-filter-date-picker label="Fin de couverture" formControlName="dateFin"></lua-filter-date-picker>
					<h2>Situation</h2>
					<lua-switch (clickSwitch)="toggleAld($event)" label="ALD" [isChecked]="ald"
						texteSecondaire="Affectation de longue durée"></lua-switch>
					<lua-select label="Situation" [options]="situations" formControlName="situation" class="mb-10">
					</lua-select>
					<ng-container formGroupName="situationFields">
						<lua-filter-date-picker *ngIf="situationFields.contains('dateAccidentTravail')"
							label="Date accident de travail" formControlName="dateAccidentTravail"></lua-filter-date-picker>
						<lua-select *ngIf="situationFields.contains('supportAccidentTravail')" label="Support"
							[options]="supportATOptions" formControlName="supportAccidentTravail" class="mb-10"></lua-select>
						<lua-input *ngIf="situationFields.contains('attestationAccidentTravail')" [fullWidth]="true"
							placeholder="Numéro de l'attestation" label="Numéro de l'attestation"
							formControlName="attestationAccidentTravail"></lua-input>
						<lua-input *ngIf="situationFields.contains('destinataireAccidentTravail')" [fullWidth]="true"
							placeholder="Destinataire" label="Destinataire" formControlName="destinataireAccidentTravail"></lua-input>
						<lua-filter-date-picker *ngIf="situationFields.contains('dateMaternite')" label="Date maternité"
							formControlName="dateMaternite"></lua-filter-date-picker>
						<lua-filter-date-picker *ngIf="situationFields.contains('datePreventionMaladie')"
							label="Date prévention maladie" formControlName="datePreventionMaladie"></lua-filter-date-picker>
						<lua-input *ngIf="situationFields.contains('numeroPreventionMaladie')" [fullWidth]="true"
							placeholder="Numéro prévention maladie" label="Numéro prévention maladie"
							formControlName="numeroPreventionMaladie"></lua-input>
						<lua-input *ngIf="situationFields.contains('infoPreventionMaladie')" [fullWidth]="true"
							placeholder="Information additionnelle" label="Information additionnelle"
							formControlName="infoPreventionMaladie"></lua-input>
						<lua-input *ngIf="
								situationFields.contains('montantSMGSoinsMedicauxGratuits')
							" [fullWidth]="true" placeholder="Montant SMG" label="Montant SMG" type="number"
							formControlName="montantSMGSoinsMedicauxGratuits"></lua-input>
					</ng-container>
					<h2>Scan Attestation</h2>
					<ng-container *ngFor="let image of this.listDoc">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteOldFile($event)"></di-carte-photo>
					</ng-container>
					<ng-container *ngFor="let image of this.listNewFile">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteNewFile($event)"></di-carte-photo>
					</ng-container>
					<lua-alerte *ngIf="isErrorPhoto" [filled]="true" [fullWidth]="true" type="error" iconName="info"
						[message]="errorMessage"></lua-alerte>
					<di-input-file *ngIf="listNewFile.length == 0 && listDoc.length == 0"
						(inputClick)="addAttestation($event)"></di-input-file>
				</div>
				<div class="form-inputs form-inputs__bg-grey">
					<h2>Adri</h2>
					<p>
						L'appel Adri auprès de la sécurité sociale est disponible. Cela
						permet de contrôler les droits du patient.
					</p>
					<lua-alerte *ngIf="adriDone && !erreurAdri" [filled]="false" [type]="adriDone" [message]="adriDoneMessage">
					</lua-alerte>
					<lua-alerte *ngIf="erreurAdri" [filled]="true" type="error" [message]="erreurAdri"></lua-alerte>
					<div #appelAdri>
						<lua-action-secondaire libelle="Appel Adri" (click)="callAdri()"
							[isDisabled]="adriOrCVInProgress"></lua-action-secondaire>
					</div>
				</div>
				<div class="form-inputs form-inputs__bg-grey">
					<h2>Carte vitale</h2>
					<p>
						Sécuriser votre tiers payant en lisant la carte vitale de votre
						patient.
					</p>

					<lua-alerte *ngIf="carteVitaleDone && !erreurCv" [filled]="false" [type]="carteVitaleDone"
						[message]="carteVitaleDoneMessage">
					</lua-alerte>
					<lua-alerte *ngIf="erreurCv" [filled]="true" type="error" [message]="erreurCv"></lua-alerte>
					<div #appelCV>
						<lua-action-secondaire libelle="Lecture carte vitale" (click)="readCarteVitale()"
							[isDisabled]="adriOrCVInProgress"></lua-action-secondaire>
					</div>
				</div>
			</div>
			<div class="content__form-buttons">
				<div class="form-buttons__buttons">
					<div #selectConvention>
						<lua-alerte *ngIf="isError" [filled]="true" type="error" [message]="errorMessage"></lua-alerte>
						<lua-action-principale libelle="Enregistrer" [fullWidth]="true" (click)="handleSubmit($event)"
							[isDisabled]="isFormValid()"></lua-action-principale>
					</div>
					<lua-bouton-texte [fullWidth]="true" libelle="Annuler" (click)="goBack()"></lua-bouton-texte>
				</div>
			</div>
		</form>
		<lua-divider-horizontal></lua-divider-horizontal>
		<div class="content__footer">
			<div class="footer__container">
				<p>{{ creationDateMessage }}</p>
				<lua-bouton-texte *ngIf="couverture" [fullWidth]="true" color="Error" libelle="Supprimer" startIcon="delete"
					(click)="deleteAmo($event)"></lua-bouton-texte>
			</div>
		</div>
	</div>
</div>