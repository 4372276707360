import {Component, ElementRef, Input, OnChanges, OnInit} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit, OnChanges {
  @Input()
  label = 'Label';

  @Input()
  size? = ESize.XS;

  @Input()
  color = 'CouleurPrimaire';

  @Input()
  mode: 'filled' | 'outlined' = 'filled';

  @Input()
  iconName = '';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.initValue();
  }

  ngOnChanges(): void {
    this.initValue();
  }

  private initValue() {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  getFontSize(): string {
    switch (this.size) {
      case ESize.XS:
        return '12px';
      case ESize.S:
        return '18px';
      case ESize.M:
        return '24px';
      default:
        return '12px';
    }
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'size-xs': this.size === ESize.XS,
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      filled: this.mode === 'filled',
      outlined: this.mode === 'outlined',
    };
  }
}
