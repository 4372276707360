<div class="di-groupe-liste-seance">
	<div class="di-groupe-liste-seance__container">
		<div class="container__content">
			<div class="di-liste-seance__container">
				<di-liste-seance 
					[visibleGroupedSeances]="groupedSeances" 
					[ordonnance]="ordonnance" 
					[patient]="patient" 
					[pagination]="true">
				</di-liste-seance>
				<div class="action-boutons">
					<lua-action-principale 
						libelle="Facturer" 
						[fullWidth]="true" 
						[isDisabled]="facturationStart || !isFacturable"
						(click)="facturer()">
					</lua-action-principale>
					<lua-bouton-texte 
						size="large" 
						[fullWidth]="true" 
						libelle="Annuler"
						(click)="onClickCancel()">
					</lua-bouton-texte>
				</div>
			</div>
		</div>
	</div>