import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';

@Component({
  selector: 'lua-swipeable-drawer',
  templateUrl: './swipeable-drawer.component.html',
  styleUrls: ['./swipeable-drawer.component.css'],
  animations: [
    trigger('drawerState', [
      state(
        'hidden',
        style({
          transform: 'translateY(100%)',
          visibility: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          visibility: 'visible',
        })
      ),
      transition('hidden => visible', [
        style({visibility: 'visible'}),
        animate('0.3s ease-out'),
      ]),
      transition('visible => hidden', [
        animate('0.3s ease-in', style({transform: 'translateY(100%)'})),
        style({visibility: 'hidden'}),
      ]),
    ]),
  ],
})
export class SwipeableDrawerComponent implements OnChanges {
  @ViewChild('drawerContent', {read: ViewContainerRef, static: false})
  drawerContent!: ViewContainerRef;

  @Input() visible = false;
  @Input() title? = '';
  @Input() height? = '90%';
  @Input() showGoBackButton? = false;
  @Output() onGoBackClick = new EventEmitter<Event>();
  @Output() onDrawerClose = new EventEmitter<Event>();

  drawerState: 'hidden' | 'visible' = 'hidden';

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['visible']) {
      this.drawerState = this.visible ? 'visible' : 'hidden';
    }
  }

  ngOnInit(): void {
    if (this.height) {
      this.el.nativeElement.style.setProperty('--my-height', `${this.height}`);
    }
  }

  open() {
    this.drawerState = 'visible';
  }

  close() {
    this.drawerState = 'hidden';
  }

  handleOverlayClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('lua-overlay')) {
      this.onDrawerClose.emit(event);
      this.close();
    }
  }

  handleGoBack(event: Event): void {
    this.onGoBackClick.emit(event);
  }

  handleCloseButton(event: Event): void {
    this.close();
    this.onDrawerClose.emit(event);
    this.visible = !this.visible;
  }

  setContent(content: Type<any>, inputs: any) {
    setTimeout(() => {
      if (this.height) {
        this.el.nativeElement.style.setProperty(
          '--my-height',
          `${this.height}`
        );
      }
      if (this.drawerContent) {
        this.drawerContent.clear();
        const componentRef = this.drawerContent.createComponent(content);
        Object.assign(componentRef.instance, inputs);
        this.open();
      }
    });
  }
}
