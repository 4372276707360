import { Component, Input, OnInit } from '@angular/core';
import { IContact } from '@osapp/model';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';

@Component({
  selector: 'di-selection-titulaire',
  templateUrl: './selection-titulaire.component.html',
  styleUrls: ['./selection-titulaire.component.scss'],
})
export class SelectionTitulaireComponent implements OnInit {

  @Input() onFacturer: (infirmierSelected: IContact) => {};
  @Input() infirmiers: IContact[] = [];
  public infirmierSelected: IContact;

  constructor(private svcDrawerPopover: DrawerPopoverService) { }

  ngOnInit() { }


  facturer() {
    if (!this.infirmierSelected) return;

    if (this.onFacturer) {
      this.svcDrawerPopover.close();
      this.onFacturer(this.infirmierSelected);
    }
  }


  cancel() {
    this.svcDrawerPopover.close();
  }

  onSelectionItem(infirmier: IContact) {
    this.infirmierSelected = infirmier;
  }

}
