<div class="lua-message-aucune-donnee">
  <lua-icone
    *ngIf="iconName"
    mode="outlined"
    [iconName]="iconName"
    color="Gris2"
    fontSize="40px"
  ></lua-icone>
  <h4 class="main-text">{{ mainText }}</h4>
  <small *ngIf="subText" class="sub-text">{{ subText }}</small>
  <div *ngIf="buttonLabel" class="boutton">
    <lua-action-secondaire
      [libelle]="buttonLabel"
      [size]="size"
      (click)="onAction($event)"
    ></lua-action-secondaire>
  </div>
</div>
