<div class="lua-tab-group">
  <div
    class="tab"
    *ngFor="let tab of tabs"
    (click)="handleTabClick(tab)"
    [ngClass]="{active: tab.active, disabled: tab.disabled}"
  >
    <strong>{{ tab.label }}</strong>
  </div>
</div>
<ng-content></ng-content>
