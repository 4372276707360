import {Component, Input, OnInit, ElementRef} from '@angular/core';

@Component({
  selector: 'lua-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent implements OnInit {
  private _open = false;
  @Input() title = '';
  @Input() color = '';
  @Input() iconOpen = 'keyboard_arrow_down';
  @Input() iconClose = 'keyboard_arrow_up';
  @Input() iconPosition: 'right' | 'left' = 'right';
  @Input() mode = 'default';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
    if (!this.iconOpen) this.iconOpen = 'keyboard_arrow_down';
    if (!this.iconClose) this.iconClose = 'keyboard_arrow_up';
    this.iconName = this.iconOpen;
  }

  get open(): boolean {
    return this._open;
  }
  set open(value: boolean) {
    this._open = value;
    this.updateIcon();
  }

  iconName: string = this.iconOpen;

  toggle() {
    this.open = !this.open;
  }

  updateIcon() {
    this.iconName = this.open ? this.iconClose : this.iconOpen;
  }
}
