import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IContact } from '@osapp/model';
import { IRetrocession } from 'apps/idl/src/modules/patients/model/IRetrocession';
import { PanneauService } from '../../../features/shared/services/panneau.service';

@Component({
	selector: 'di-panneau-list-retrocessions',
	templateUrl: './panneau-list-retrocessions.component.html',
	styleUrls: ['./panneau-list-retrocessions.component.scss'],
})
export class PanneauListRetrocessionsComponent implements OnInit {

	@Input() public retrocessions: IRetrocession[];
	@Input() public utilisateurs: IContact[];
	@Input() public onSelectRetrocession: (retrocession: IRetrocession) => void = () => { };
	@Input() public onCreateRetrocession: (idRetroToEdit?: string) => void = () => { };
	@Input() public selectedRetrocessionId: string;


	constructor(
		private svcPanneau: PanneauService,
	) { }

	ngOnInit() {

	}

	public displayRetrocessionDetails = (retrocession: IRetrocession) => {
		this.svcPanneau.close();
		if (this.onSelectRetrocession) {
			this.onSelectRetrocession(retrocession);
		}
	}

	public createNewRetrocession = () => {
		this.svcPanneau.close();
		if (this.onCreateRetrocession) {
			this.onCreateRetrocession();
		}
	}

}
