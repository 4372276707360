 <div class="lua-switch" (click)="onToggleChange()">
  <div class="label-container">
    <p *ngIf="label" [ngClass]="{ label: !isChecked,'label-checked': isChecked}">{{label}}</p>
    <small *ngIf="texteSecondaire" class="secondary-text">{{texteSecondaire}}</small>
  </div>
  <div class="switch-container">
    <label class="switch">
      <input type="checkbox" [checked]="isChecked" (change)="onToggleChange()" [disabled]="disabled">
      <span class="slider"></span>
    </label>
  </div>
</div>
