import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-appbar-item',
  templateUrl: './appbar-item.component.html',
  styleUrls: ['./appbar-item.component.css'],
})
export class AppBarItemComponent implements OnInit, OnChanges {
  @Input() menuOpen = false;
  @Input() selected = false;
  @Input() badgeNumber = 0;
  @Input() iconMode: 'filled' | 'outlined' = 'outlined';
  @Input() iconName = '';
  @Input() text = '';
  public colorIcon = '';

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.setIconColor();
    }
  }

  ngOnInit(): void {
    this.setIconColor();
  }
  setIconColor = () => {
    this.colorIcon = this.selected ? 'CouleurPrimaire' : 'Blanc';
  };
}
