import { Component, Input, OnInit } from '@angular/core';
import { Retrocession } from 'apps/idl/src/modules/patients/model/Retrocession';
import { DrawerPopoverService } from '../../shared/services/drawer-popover.service';
import { ConfirmationSuppressionComponent } from '../../shared/components/confirmation-suppression/confirmation-suppression.component';
import { RetrocessionService } from '../../shared/services/retrocession.service';
@Component({
  selector: 'di-menu-options-retrocession',
  templateUrl: './menu-options-retrocession.component.html',
  styleUrls: ['./menu-options-retrocession.component.scss'],
})
export class MenuOptionsRetrocessionComponent implements OnInit {

  @Input() retrocession: Retrocession
  @Input() onEditClick: (retro: Retrocession) => void;
  @Input() onDownloadClick: () => void;

  public showPopover: boolean = false;

  constructor(
    private svcDrawerPopover: DrawerPopoverService,
    private svcRetrocession: RetrocessionService,
  ) { }

  ngOnInit() { }

  handleEditClick(event: Event) {
    if (this.onEditClick) {
      this.onEditClick(this.retrocession);
    }
  }

  handleDownloadClick(event: Event) {
    if (this.onDownloadClick) {
      this.onDownloadClick();
    }
  }

  handleDeleteClick(event: Event) {
    this.showPopover = true;
    this.svcDrawerPopover.open(
      "Confirmation de suppression",
      "250px",
      event.currentTarget,
      ConfirmationSuppressionComponent,
      {
        onConfirm: () => this.handleConfirmDeleteRetrocession(),
        onCancel: () => this.closeMenu(),
      },
      () => (this.showPopover = false)
    );
  }

  handleConfirmDeleteRetrocession() {
    this.svcRetrocession.delete(this.retrocession).subscribe({
      next: () => {
        this.closeMenu();
        this.svcRetrocession.triggerRefreshRetrocessionList();
      },
      error: (error) => {
        console.error("Une erreur est survenue lors de la suppression de la rétrocession : ", error);
      }
    });
  }

  closeMenu(): void {
    this.showPopover = false;
    this.svcDrawerPopover.close();
  }

}
