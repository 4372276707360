<lua-overlay *ngIf="open && !isMobile" (onClick)="closePanel()"></lua-overlay> 
<div class="lua-panneau-desktop" [class.open]="open" >
   <div class="header">
      <lua-bouton-icone
      [iconName]="closeIconName"
         color="CouleurPrimaire"
         fontSize="40px"
         (click)="closePanel()"
         ></lua-bouton-icone>
      <strong *ngIf="title" >{{ title }}</strong>
      <div class="icon-container">
         <lua-bouton-icone 
         *ngIf="secondIconName"
         [iconName]="secondIconName" 
         mode="outlined"
         color="CouleurPrimaire"
         fontSize="40px"
         (click)="handleClickFirst($event)">
         </lua-bouton-icone>
         <div>
            <lua-bouton-icone
            *ngIf="thirdIconName"
            [iconName]="thirdIconName"
            mode="outlined"
            color="CouleurPrimaire"
            fontSize="40px"
            (click)="handleClickFirst($event)">
            </lua-bouton-icone>
         </div>
      </div>
   </div>
   <ng-container #contentContainer></ng-container>
</div>