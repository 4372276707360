<lua-overlay *ngIf="open" (onClick)="handlePopoverClose($event)"></lua-overlay> 
<div class="lua-popover" *ngIf="open" 
[ngClass]="{
  visible: open && isReady,
  hidden: !open || !isReady
}">
  <div *ngIf="title" class="popover-header">
    <lua-bouton-icone
      *ngIf="showGoBackButton"
      iconName="arrow_back"
      color="CouleurPrimaire"
      (click)="handleGoBack($event)"
    ></lua-bouton-icone>
    <strong class="popover-title">{{ title }}</strong>
    <lua-bouton-icone
      iconName="close"
      color="CouleurPrimaire"
      (click)="handlePopoverClose($event)"
    ></lua-bouton-icone>
  </div>
  <div class="popover-content">
    <ng-content></ng-content>
    <ng-container #popoverContent></ng-container>
  </div>
</div>
