<div class="lua-data-picker" 
[ngClass]="{ 
  horizontal: direction === 'horizontal', 
  vertical: direction === 'vertical', 
  'full-width': fullWidth }">
  <div *ngIf="label || secondaryLabel" class="text-container">
    <p class="label">{{ label }}</p>
    <small class="secondary-label">{{ secondaryLabel }}</small>
  </div>
  <lua-option-data-picker
    *ngFor="let option of options"
    [label]="option.label"
    [value]="option.value"
    [iconName]="option.iconName"
    [customIconName]="option.customIconName"
    [disabled]="option.disabled"
    [fullWidth]="fullWidth || direction === 'vertical'"
    [isSelected]="selectedOptions.includes(option.value)"
    (onClick)="onOptionClick(option.value)"
  ></lua-option-data-picker>
</div>
