import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css'],
})
export class SnackbarComponent implements OnInit {
  @Input() type: 'info' | 'success' | 'warning' | 'error' = 'info';
  @Input() position:
    | 'bottom center'
    | 'bottom left'
    | 'bottom right'
    | 'top right' = 'bottom center';
  @Input() message = '';
  @Input() iconName?: string;
  @Input() buttonText?: string;
  @Input() buttonIconName?: string;
  @Input() onClickAction?: Function;
  @Input() delay = 6000;

  isVisible = true;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.isVisible = false;
    }, this.delay);
  }

  getClasses() {
    switch (this.position) {
      case 'bottom left':
        return 'bottom-left';
      case 'bottom right':
        return 'bottom-right';
      case 'top right':
        return 'top-right';
      case 'bottom center':
      default:
        return 'bottom-center';
    }
  }
}
