import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LifeCycleObserverComponentBase } from '@osapp/helpers';
import { ILifeCycleEvent, IWorkspaceInfo, UserData } from '@osapp/model';
import { FlagService, Store, WorkspaceService } from '@osapp/services';
import { SecurityService } from '@osapp/services/security.service';
import { ConnexionService } from '../../../features/connexion/services/connexion.service';
import { AuthenticatorService } from '../../../features/shared/services/authenticator.service';
import { tap } from 'rxjs/operators';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';

@Component({
	templateUrl: './workspace-selection.page.html',
	styleUrls: ['./workspace-selection.page.scss'],
})
export class WorkspaceSelectionPage extends LifeCycleObserverComponentBase implements OnInit, OnDestroy, AfterViewInit {

	public workspaces: IWorkspaceInfo[];
	public selectedWorkspace: string;

	constructor(
		changeDetector: ChangeDetectorRef,
		private svcSecurity: SecurityService,
		private svcWorkspace: WorkspaceService,
		private svcConnexion: ConnexionService,		
		private svcAuthenticator: AuthenticatorService,
		private svcStore: Store,
		private svcFlag: FlagService
	) {
		super(null, changeDetector);
	}

	ngOnInit(): void {
		if(!UserData.current)
		{
			this.disconnect();
			return;
		}else{
			this.workspaces = this.svcWorkspace.getWorkspaces().sort((a, b) => a.name.localeCompare(b.name))
			this.svcConnexion.waitForConnexionFlags();
		}
	}

	protected onLifeCycleEvent(event: ILifeCycleEvent): void {
		throw new Error('Method not implemented.');
	}

	public selectWorkspace(event: Event, workspaceId: string): void {
		this.svcStore.initWorkspaceDatabase(workspaceId).pipe(
			tap(() => {
				this.selectedWorkspace = workspaceId;
				this.svcFlag.setFlagValue(EPermissionsFlag.isLoaded, true);
				UserData.current.workspaceInfos = UserData.current.workspaceInfos.filter((workspace: IWorkspaceInfo) => workspace.id === workspaceId);
				this.svcConnexion.selectWorkspace(workspaceId);
			})
		).subscribe()
	}

	public disconnect(): void {
		this.svcAuthenticator.setAuthenticationStatus(false);
		this.svcSecurity.disconnect().subscribe();
	}

}
