import { Component, OnInit } from "@angular/core";
import { DeviceService } from "../../features/shared/services/device.service";
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: "di-parametres",
	templateUrl: "./parametres.page.html",
	styleUrls: ["./parametres.page.scss"],
})
export class ParametresPage extends DestroyableComponentBase implements OnInit {
	public isMobileView: boolean;
	public showMenu = true;
	public showPageDroite = false;
	public showUtilisateurs = true;
	public showCabinet: boolean = false;

	public resetUsersList$: Subject<void> = new Subject<void>();

	constructor(private svcDevice: DeviceService) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)
		).subscribe((flag: boolean) => {
			this.isMobileView = flag;
			this.updateViewForDevice();
		});

		this.updateViewForDevice();
	}

	private updateViewForDevice() {
		if (this.isMobileView) {
			this.showMenu = true;
			this.showPageDroite = false;
			this.showUtilisateurs = false;
		} else {
			this.showMenu = true;
			this.showPageDroite = this.showUtilisateurs;
			this.showUtilisateurs = true;
		}
	}

	get isUtilisateursSelected(): boolean {
		return !this.isMobileView && this.showUtilisateurs;
	}

	handleSelectionUtilisateurs() {
		if (this.isMobileView) {
			this.showMenu = false;
			this.showPageDroite = true;
		}
		this.showUtilisateurs = true;
		this.showCabinet = false;

		this.resetUsersList$.next();
	}

	handleSelectionCabinet() {
		if (this.isMobileView) {
			this.showMenu = false;
			this.showPageDroite = true;
		}
		this.showUtilisateurs = false;
		this.showCabinet = true;
	}

	handleRetourParametres = () => {
		this.showPageDroite = false;
		this.showMenu = true;
	}
}
