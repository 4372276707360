<div class="lua-avatar">
  <div class="user-icon" [ngClass]="{
    'size-s': this.size === 'small',
    'size-m': this.size === 'medium',
    'size-l': this.size === 'large'
  }">
  <span *ngIf="firstNameLetter">{{ firstNameLetter }}{{ lastNameLetter }}</span>  
    <lua-icone *ngIf="!firstNameLetter" color="Blanc" iconName="Question_Mark"  mode="outlined" fontSize="14px"></lua-icone>
  </div>

  

  <ng-container *ngIf="withTooltip">
    <div class="arrow-up"></div>
    <div class="tooltip">
      {{ fullName }}
    </div>
  </ng-container>
</div>
