<mat-form-field class="lua-input" appearance="outline" [ngClass]="{
	'full-width': fullWidth,
	'input-small': size === 'small',
	'input-medium': size === 'medium',
	'input-large': size === 'large',
	error: isError,
	disabled: disabled
}">
	<mat-label>{{ label }}</mat-label>
	<input *ngIf="!multiline && autoFocus" class="lua-input" autofocus [placeholder]="placeholder" [(ngModel)]="value"
		type="{{ type }}" [attr.min]="type === 'number' || type === 'date' ? min : null"
		[attr.max]="type === 'number' || type === 'date' ? max : null" matInput (input)="onInput($event)"
		[disabled]="disabled" autocapitalize="off" autocomplete="off" />
	<input *ngIf="!multiline && !autoFocus" class="lua-input" [(ngModel)]="value" type="{{ type }}"
		[attr.min]="type === 'number' || type === 'date' ? min : null"
		[attr.max]="type === 'number' || type === 'date' ? max : null" [placeholder]="placeholder" matInput
		(input)="onInput($event)" [disabled]="disabled" autocapitalize="off" autocomplete="off" />
	<textarea *ngIf="multiline && autoFocus" class="lua-input textarea-full-width" autofocus [placeholder]="placeholder"
		[(ngModel)]="value" matInput [rows]="numberOfLines" (input)="onInput($event)" [style.resize]="textareaResize"
		(keypress)="preventNewLines($event)" [disabled]="disabled" autocapitalize="off" autocomplete="off"></textarea>
	<textarea *ngIf="multiline && !autoFocus" class="lua-input textarea-full-width" [placeholder]="placeholder"
		[(ngModel)]="value" matInput [rows]="numberOfLines" (input)="onInput($event)" [style.resize]="textareaResize"
		(keypress)="preventNewLines($event)" [disabled]="disabled" autocapitalize="off" autocomplete="off"></textarea>
	<lua-bouton-icone [mode]="modeIcon" *ngIf="iconEnd" matSuffix [iconName]="iconEnd" (onClick)="onClickIcon($event)"
		[isDisabled]="disabled">
	</lua-bouton-icone>
</mat-form-field>
<small *ngIf="isError && helperText" class="input-helper-text">{{
	helperText
	}}</small>