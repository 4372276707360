import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccordionComponent} from './components/accordions/accordion.component';
import {AlerteComponent} from './components/alertes/alerte.component';
import {AppBarItemComponent} from './components/appbar-item/appbar-item.component';
import {AvatarComponent} from './components/avatar/avatar.component';
import {BadgeComponent} from './components/badges/badge/badge.component';
import {ActionPrincipaleComponent} from './components/boutons/action-principale/action-principale.component';
import {ActionSecondaireComponent} from './components/boutons/action-secondaire/action-secondaire.component';
import {BoutonIconeComponent} from './components/boutons/bouton-icone/bouton-icone.component';
import {BoutonTexteComponent} from './components/boutons/bouton-texte/bouton-texte.component';
import {BoutonToolbarComponent} from './components/boutons/bouton-toolbar/bouton-toolbar.component';
import {FloatingComponent} from './components/boutons/floating/floating.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {ColorItemComponent} from './components/color-picker/color-item/color-item.component';
import {ColorPickerComponent} from './components/color-picker/color-picker/color-picker.component';
import {DataPickerComponent} from './components/data-picker/data-picker/data-picker.component';
import {OptionDataPickerComponent} from './components/data-picker/option-data-picker/option-data-picker.component';
import {DividerHorizontalComponent} from './components/dividers/divider-horizontal/divider-horizontal.component';
import {SwipeableDrawerComponent} from './components/drawers/swipeable-drawer/swipeable-drawer.component';
import {FilterDatePickerComponent} from './components/filtres/filter-date-picker/filter-date-picker.component';
import {FiltreGroupeComponent} from './components/filtres/filtre-groupe/filtre-groupe.component';
import {FiltresComponent} from './components/filtres/filtres/filtres.component';
import {InputFilledComponent} from './components/formulaire/input-filled/input-filled.component';
import {InputTimeComponent} from './components/formulaire/input-time/input-time.component';
import {InputComponent} from './components/formulaire/input/input.component';
import {SelectComponent} from './components/formulaire/select/select.component';
import {IconeComponent} from './components/icones/icone.component';
import {ListItemComponent} from './components/list-items/list-item/list-item.component';
import {LoaderCirculaireComponent} from './components/loaders/loader-circulaire/loader-circulaire.component';
import {LoaderHorizontalComponent} from './components/loaders/loader-horizontal/loader-horizontal.component';
import {TuileComponent} from './components/menus/tuile/tuile.component';
import {ActionEncoursComponent} from './components/messages/action-encours/action-encours.component';
import {AucuneDonneeComponent} from './components/messages/aucune-donnee/aucune-donnee.component';
import {ErreurComponent} from './components/messages/erreur/erreur.component';
import {InfoComponent} from './components/messages/info/info.component';
import {SuccessComponent} from './components/messages/success/success.component';
import {ObjetCabinetComponent} from './components/objets/objet-cabinet/objet-cabinet.component';
import {ObjetPatientComponent} from './components/objets/objet-patient/objet-patient.component';
import {ObjetUtilisateurComponent} from './components/objets/objet-utilisateur/objet-utilisateur.component';
import {ObjetWorkspaceComponent} from './components/objets/objet-workspace/objet-workspace.component';
import {OverlayComponent} from './components/overlay/overlay.component';
import {PanneauDesktopComponent} from './components/panneaux/panneau-desktop/panneau-desktop.component';
import {PopoverComponent} from './components/popover/popover.component';
import {RadioButtonComponent} from './components/radio-button/radio-button.component';
import {RechercheComponent} from './components/recherches/recherche/recherche.component';
import {SeparateurGroupeComponent} from './components/separateur-groupe/separateur-groupe.component';
import {SnackbarComponent} from './components/snackbar/snackbar.component';
import {StepperComponent} from './components/stepper/stepper/stepper.component';
import {PapierComponent} from './components/surfaces/papier/papier.component';
import {SwitchComponent} from './components/switches/switch/switch.component';
import {TableauEnteteComponent} from './components/tableaux/tableau-entete/tableau-entete.component';
import {TabGroupComponent} from './components/tabs/tab-group/tab-group.component';
import {TabComponent} from './components/tabs/tab/tab.component';
import {TagComponent} from './components/tags/tag/tag.component';
import {LightingUpAngularComponent} from './lighting-up-angular.component';

@NgModule({
  declarations: [
    LightingUpAngularComponent,
    ActionPrincipaleComponent,
    InputComponent,
    InputFilledComponent,
    BoutonTexteComponent,
    ActionSecondaireComponent,
    PapierComponent,
    AlerteComponent,
    LoaderCirculaireComponent,
    ActionEncoursComponent,
    AucuneDonneeComponent,
    ErreurComponent,
    BadgeComponent,
    StepperComponent,
    IconeComponent,
    BoutonIconeComponent,
    TuileComponent,
    DividerHorizontalComponent,
    AvatarComponent,
    TagComponent,
    FilterDatePickerComponent,
    RadioButtonComponent,
    CheckboxComponent,
    PanneauDesktopComponent,
    SeparateurGroupeComponent,
    FloatingComponent,
    ListItemComponent,
    ObjetUtilisateurComponent,
    RechercheComponent,
    ObjetWorkspaceComponent,
    SwitchComponent,
    ObjetPatientComponent,
    ObjetCabinetComponent,
    BoutonToolbarComponent,
    SelectComponent,
    FiltreGroupeComponent,
    OptionDataPickerComponent,
    DataPickerComponent,
    OverlayComponent,
    TableauEnteteComponent,
    SwipeableDrawerComponent,
    SnackbarComponent,
    PopoverComponent,
    TabGroupComponent,
    TabComponent,
    InputTimeComponent,
    LoaderHorizontalComponent,
    AccordionComponent,
    FiltresComponent,
    ColorItemComponent,
    SuccessComponent,
    InfoComponent,
    ColorPickerComponent,
    AppBarItemComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatSelectModule,
    BrowserModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
  ],
  exports: [
    LightingUpAngularComponent,
    ActionPrincipaleComponent,
    InputComponent,
    InputFilledComponent,
    BoutonTexteComponent,
    ActionSecondaireComponent,
    PapierComponent,
    AlerteComponent,
    LoaderCirculaireComponent,
    ActionEncoursComponent,
    AucuneDonneeComponent,
    ErreurComponent,
    BadgeComponent,
    StepperComponent,
    IconeComponent,
    BoutonIconeComponent,
    TuileComponent,
    DividerHorizontalComponent,
    AvatarComponent,
    TagComponent,
    FilterDatePickerComponent,
    RadioButtonComponent,
    CheckboxComponent,
    PanneauDesktopComponent,
    SeparateurGroupeComponent,
    FloatingComponent,
    ObjetUtilisateurComponent,
    RechercheComponent,
    ObjetWorkspaceComponent,
    ObjetPatientComponent,
    ObjetCabinetComponent,
    BoutonToolbarComponent,
    SwitchComponent,
    FiltreGroupeComponent,
    SelectComponent,
    ListItemComponent,
    OptionDataPickerComponent,
    DataPickerComponent,
    OverlayComponent,
    TableauEnteteComponent,
    SwipeableDrawerComponent,
    SnackbarComponent,
    PopoverComponent,
    TabGroupComponent,
    TabComponent,
    InputTimeComponent,
    LoaderHorizontalComponent,
    AccordionComponent,
    FiltresComponent,
    ColorItemComponent,
    SuccessComponent,
    InfoComponent,
    ColorPickerComponent,
    AppBarItemComponent,
  ],
  // providers: [
  //   { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  // ]
})
export class LightingUpAngularModule {
  constructor() {}
}
