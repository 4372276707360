import { Component, Input, OnInit } from '@angular/core';
import { EEtatsFacture } from '../../../../shared/enums/EEtatsFacture';

@Component({
  selector: 'di-filtre-tiers-payant',
  templateUrl: './filtre-tiers-payant.component.html',
  styleUrls: ['./filtre-tiers-payant.component.scss'],
})
export class FiltreTiersPayantComponent implements OnInit {

  public etatsFacture = Object.values(EEtatsFacture);
  @Input() etatsSelected: string[] = [];
  @Input() onFilter: (etatsSelected: string[]) => void;

  public etatsSelectedFinal: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.etatsSelectedFinal = [...this.etatsSelected];
  }

  public afficher() {
    if (this.onFilter) {
      this.onFilter(this.etatsSelectedFinal);
    }
  }


  public onSelection(event: Event, etat: string) {
    if (this.etatsSelectedFinal.includes(etat)) {
      this.etatsSelectedFinal = this.etatsSelectedFinal.filter(x => x !== etat);
    }
    else {
      this.etatsSelectedFinal.push(etat);
    }
  }

}
