import { Component, Input, OnInit } from '@angular/core';
import { EControleEtat } from '../../../shared/enums/EControleEtat';
import { ITagSoin } from 'apps/idl/src/anakin/models/ITagSoin';

@Component({
  selector: 'di-donnee-controlee',
  templateUrl: './donnee-controlee.component.html',
  styleUrls: ['./donnee-controlee.component.scss'],
})
export class DonneeControleeComponent implements OnInit {

  @Input() etat: EControleEtat;
  @Input() libellePrincipal: string;
  @Input() libelleSecondaire: string;
  @Input() libelleErreur: string;
  @Input() checked: boolean;
  @Input() switchOnly: boolean;
  @Input() iconEnd: string = "chevron_right";
  @Input() bold: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tag?: ITagSoin;
  @Input() onToggleSwitch: (value: boolean) => (void);
  @Input() onClick: () => (void);
  /**
   * Indique si l'on veut afficher le bouton switch
   */
  @Input() switch: boolean = false;

  public icon: string;
  public color: string;
  public mode: string;

  private readonly etatConfig = {
    "OK": {
      icon: "check_circle",
      color: "OK",
      mode: "filled",
    },
    "Warning": {
      icon: "warning",
      color: "Warning",
      mode: "filled",
    },
    "Error": {
      icon: "cancel",
      color: "Error",
      mode: "filled",
    },
    "Disabled": {
      icon: "circle",
      color: "Gris2",
      mode: "outlined",
    }
  };

  constructor() { }

  ngOnInit() {
    this.getEtat();
  }

  getEtat() {
    if (!this.etat) return;

    const config = this.etatConfig[this.etat];
    this.icon = config.icon;
    this.color = config.color;
    this.mode = config.mode;
  }

  toggleSwitch(newValue: boolean) {
    if (this.onToggleSwitch) {
      this.onToggleSwitch(newValue);
    }
  }

  public onClickLigne() {
    if (this.onClick)
      this.onClick();
  }

}
