<button class="lua-bouton-texte" 
	[ngClass]="{
		'full-width': fullWidth,
		'size-s': size === 'small',
		'size-m': size === 'medium',
		'size-l': size === 'large'
	}"
	[type]="type"
	[disabled]="isDisabled"
	mat-flat-button>
	<lua-icone 
		*ngIf="startIcon" 
		class="start-icon" 
		mode="outlined" 
		[iconName]="startIcon" 
		[color]="color"
		[fontSize]="fontSizeMap[size] || '20px'">
	</lua-icone>
	{{libelle}}
	<lua-icone *ngIf="endIcon" class="end-icon" mode="outlined" [iconName]="endIcon" [color]="color" [fontSize]="fontSizeMap[size] || '20px'">
	</lua-icone>
</button>