import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
})
export class TabComponent implements OnInit {
  @Input() label = '';
  @Input() disabled = false;
  @Input() active = false;

  constructor() {}

  ngOnInit(): void {}

  getClasses() {
    return {
      disabled: this.disabled,
      active: this.active,
    };
  }
}
