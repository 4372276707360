import { Component, Input, OnInit } from '@angular/core';
import { IContact } from '@osapp/model';
import { Site } from '@osapp/modules/sites/models/site';
import { DateObject } from '../../../models/retrocession/DateObject';
import { Retrocession } from 'apps/idl/src/modules/patients/model/Retrocession';
import { DateHelper } from '@osapp/helpers';

@Component({
  selector: 'di-impression-retrocession',
  templateUrl: './impression-retrocession.component.html',
  styleUrls: ['./impression-retrocession.component.scss'],
})
export class ImpressionRetrocessionComponent implements OnInit {

  @Input() retrocession!: Retrocession;
  @Input() cabinet!: Site;
  @Input() remplacant!: IContact;
  @Input() journees!: { date: Date, dateObject: DateObject }[];
  @Input() nombreJournees!: number;
  @Input() totalActesMajorations!: number;
  @Input() totalIndemnites!: number;
  @Input() totalRetrocession!: number;

  public dateRetrocession: string;
  public nomCabinet: string;
  public adresseCabinet: string;
  public telephoneCabinet: string;
  public emailCabinet: string;
  public nomPrenomRemplacant: string;
  public adresseRemplacant: string;
  public finessRemplacant: string;

  constructor() { }

  ngOnInit() {
    const dateFin: Date | null = this.retrocession?.dateFin ? new Date(this.retrocession.dateFin) : null;
    this.nomCabinet = this.cabinet?.name || '';
    this.nomPrenomRemplacant = (this.remplacant?.lastName || '') + ' ' + (this.remplacant?.firstName || '');
    this.adresseRemplacant = this.getAddress();
    this.finessRemplacant = this.remplacant?.finess || '';
    this.dateRetrocession = dateFin ? dateFin.getDate() + ' ' + DateHelper.C_MONTH_NAMES[dateFin.getMonth()] + ' ' + dateFin.getFullYear() : '';
  }

  public getAddress(): string {
    const { street, zipCode, city } = this.remplacant || {};
    const addressParts = [
      street,
      [zipCode, city].filter(Boolean).join(' ')
    ].filter(Boolean);
    return addressParts.join(', ');
  }
}