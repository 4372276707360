import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lua-filtre-groupe',
  templateUrl: './filtre-groupe.component.html',
  styleUrls: ['./filtre-groupe.component.css'],
})
export class FiltreGroupeComponent implements OnInit {
  @Input() filters: string[] = [];
  @Input() defaultFilter = '';
  @Output() filterSelected = new EventEmitter<string>();

  selectedFilter: string | null = null;

  constructor() {}

  ngOnInit(): void {
    this.defaultFilter && (this.selectedFilter = this.defaultFilter);
  }

  onFilterClick(filter: string) {
    this.selectedFilter = filter;
    this.filterSelected.emit(filter);
  }

  getClasses(filter: string) {
    return {
      selected: this.selectedFilter === filter,
    };
  }
}
