import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-tableau-entete',
  templateUrl: './tableau-entete.component.html',
  styleUrls: ['./tableau-entete.component.css'],
})
export class TableauEnteteComponent implements OnInit {
  @Input() entete = '';
  @Input() avecTri? = false;
  @Input() triee? = false;
  @Input() ordreCroissant? = false;
  @Input() clickEntete: (entente: string) => void = () => {};
  @Input() position: 'right' | 'left' | 'center' = 'right';

  constructor() {}
  ngOnInit(): void {}

  handleEnteteClick(entete: string): void {
    if (!this.triee) {
      this.triee = true;
      this.ordreCroissant = true;
    } else if (this.ordreCroissant) {
      this.ordreCroissant = false;
    } else {
      this.triee = false;
      this.ordreCroissant = false;
    }
    this.clickEntete(entete);
  }

  getClasses() {
    return {
      triee: this.triee,
    };
  }
}
