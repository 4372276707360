import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EEtatsReglement } from '../../../../shared/enums/EEtatsReglement';

@Component({
  selector: 'di-filtre-part-patient',
  templateUrl: './filtre-part-patient.component.html',
  styleUrls: ['./filtre-part-patient.component.scss'],
})
export class FiltrePartPatientComponent implements OnInit {

  constructor() { }

  @Input() etatsSelected: string[] = [];
  @Input() onFilter: (etatsSelected: string[]) => void;

  public etatsReglement = Object.values(EEtatsReglement);
  public etatsSelectedFinal: string[] = [];


  ngOnInit(): void {
    this.etatsSelectedFinal = [...this.etatsSelected];
  }

  public afficher() {
    if (this.onFilter) {
      this.onFilter(this.etatsSelectedFinal);
    }
  }


  public onSelection(event: Event, etat: string) {
    if (this.etatsSelectedFinal.includes(etat)) {
      this.etatsSelectedFinal = this.etatsSelectedFinal.filter(x => x !== etat);
    }
    else {
      this.etatsSelectedFinal.push(etat);
    }
  }


}
