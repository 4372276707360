import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lua-option-data-picker',
  templateUrl: './option-data-picker.component.html',
  styleUrls: ['./option-data-picker.component.css'],
})
export class OptionDataPickerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() label = '';
  @Input() value = '';
  @Input() isSelected = false;
  @Input() fullWidth = false;
  @Input() iconName = '';
  @Input() customIconName = '';
  @Input() disabled = false;
  @Output() onClick = new EventEmitter<string>();

  handleClick(option: string) {
    if (!this.disabled) {
      this.onClick.emit(option);
    }
  }

  getColorIcon() {
    return this.isSelected ? 'CouleurPrimaire' : 'Noir80';
  }

  getClasses() {
    return {
      selected: this.isSelected,
      'full-width': this.fullWidth,
      'with-icon-padding': this.iconName || this.customIconName,
      disabled: this.disabled,
    };
  }
}
