import {Component, Input, OnInit} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-objet-workspace',
  templateUrl: './objet-workspace.component.html',
  styleUrls: ['./objet-workspace.component.css'],
})
export class ObjetWorkspaceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() title = '';
  @Input() iconName = '';
  @Input() customIconName = '';
  @Input() iconWidth = '';
  @Input() iconHeight = '';
  @Input() iconFontSize = '';
  @Input() size = ESize.M;

  getClasses(): {[key: string]: boolean} {
    return {
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
    };
  }
}
