import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimeComponent),
      multi: true,
    },
  ],
})
export class InputTimeComponent implements ControlValueAccessor {
  @ViewChild('inputTimeContainer') inputTimeContainer!: ElementRef;

  @Input() value = '';
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() size = ESize.M;

  public hours: string[] = [];
  public minutes: string[] = [];
  public showTimePicker = false;
  public selectedHour = '';
  public selectedMinute = '';
  public hourSelected = false;
  public minutesSelected = false;
  private rawInput = '';
  private isFocused = false;
  private inputTimeout: any = null;

  constructor() {
    for (let i = 0; i < 24; i++) {
      this.hours.push(i < 10 ? `0${i}` : `${i}`);
    }
    for (let i = 0; i < 60; i++) {
      this.minutes.push(i < 10 ? `0${i}` : `${i}`);
    }
  }

  private onChange = (value: string) => {};

  private onTouched = () => {};

  writeValue(value: any): void {
    if (!value) return;
    this.value = value;
    this.updateSelectedTime(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.rawInput = inputValue;
    this.value = inputValue;
    if (this.inputTimeout) {
      clearTimeout(this.inputTimeout);
    }

    this.inputTimeout = setTimeout(() => {
      this.confirmValue();
    }, 2000);
  }

  onFocus(): void {
    this.isFocused = true;
  }

  private updateSelectedTime(value: string): void {
    if (value) {
      const [hour, minute] = value.split(':');
      if (hour && this.hours.includes(hour)) {
        this.selectedHour = hour;
        this.hourSelected = true;
      } else {
        this.selectedHour = '';
        this.hourSelected = false;
      }
      if (minute && this.minutes.includes(minute)) {
        this.selectedMinute = minute;
        this.minutesSelected = true;
      } else {
        this.selectedMinute = '';
        this.minutesSelected = false;
      }
    } else {
      this.selectedHour = '';
      this.selectedMinute = '';
      this.hourSelected = false;
      this.minutesSelected = false;
    }
  }

  toggleTimePicker(event: Event): void {
    this.showTimePicker = !this.showTimePicker;
  }

  selectHour(hour: string): void {
    this.selectedHour = hour;
    this.hourSelected = true;
    if (this.minutesSelected) {
      this.confirmValue();
    }
  }

  selectMinute(minute: string): void {
    this.selectedMinute = minute;
    this.minutesSelected = true;
    if (this.hourSelected) {
      this.confirmValue();
    }
  }

  private confirmValue(): void {
    if (this.rawInput) {
      const [hour, minute] = this.rawInput.split(':');
      if (hour && minute) {
        this.value = `${hour}:${minute}`;
        this.onChange(this.value);
        this.rawInput = this.value;
      } else {
        this.resetInput();
      }
    } else {
      this.resetInput();
    }
  }

  private resetInput(): void {
    this.value = '';
    this.rawInput = '';
    this.onChange(this.value);
  }

  onBlur(): void {
    this.confirmValue();
    this.isFocused = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (
      this.isFocused &&
      !this.inputTimeContainer.nativeElement.contains(event.target)
    ) {
      this.onBlur();
    }
  }
}
