import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-action-secondaire',
  templateUrl: './action-secondaire.component.html',
  styleUrls: ['./action-secondaire.component.css'],
})
export class ActionSecondaireComponent implements OnInit {
  @Input()
  public libelle = '';

  @Input()
  public color = 'Indigo';

  @Input()
  public fullWidth = true;

  @Input()
  public avecIcone = false;

  @Input()
  public nomIcone = 'add';

  @Input()
  public size? = ESize.M;

  @Input()
  public isDisabled = false;

  @Input()
  public positionIcone = 'R';

  @Output()
  onClick = new EventEmitter<Event>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
      'btn-desactive': this.isDisabled,
    };
  }
}
