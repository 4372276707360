<div class="entete-desktop-container">
	<div class="entete-desktop-navbar-items">
		<lua-action-principale [fullWidth]="true" libelle="Changer de workspace"
						(click)="switchWorkspace()"></lua-action-principale>
		<lua-objet-cabinet [name]="siteName" [address]="siteCity"></lua-objet-cabinet>
		<div #menuUtilisateur>
			<ng-container *ngIf="contact">
				<lua-avatar (click)="openMenu()" [firstName]="firstName" [lastName]="lastName" [withTooltip]="false"
					[color]="color">
				</lua-avatar>
			</ng-container>


		</div>
	</div>
</div>