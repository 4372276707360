<div (click)="onClickLigne()" class="di-donnee-controlee">
	<ng-container *ngIf="!switchOnly">
		<div class="container__start">
			<lua-icone [mode]="mode" [iconName]="icon" [color]="color" fontSize="20px">
			</lua-icone>
		</div>
		<div class="container__middle" [ngClass]="{'with-tag': tag}">
			<strong *ngIf="libellePrincipal && bold"  [ngClass]="{'Disabled' : disabled}">{{libellePrincipal}}</strong>
			<p *ngIf="libellePrincipal && !bold"  [ngClass]="{'Disabled' : disabled}">{{libellePrincipal}}</p>
			<small *ngIf="libelleSecondaire"  [ngClass]="{'Disabled' : disabled}">{{libelleSecondaire}}</small>
			<small *ngIf="libelleErreur" [class]="etat"  [ngClass]="{'Disabled' : disabled}">{{libelleErreur}}</small>
		</div>
		<div *ngIf="tag" class="container__tag">
			<lua-tag class="middle__tag" [label]="tag.label" [color]="tag.color" [mode]="tag.mode"></lua-tag>
		</div>
		<div class="container__end">
			<div class="container__icon-container">
				<lua-icone *ngIf="!switch && iconEnd" [iconName]="iconEnd" color="Gris2" fontSize="40px"></lua-icone>
				<lua-switch *ngIf="switch" (clickSwitch)="toggleSwitch($event)" [isChecked]="checked" [disabled]="disabled"></lua-switch>
			</div>
		</div>
	</ng-container>
	<lua-switch *ngIf="switchOnly" (clickSwitch)="toggleSwitch($event)" [label]="libellePrincipal"
		[isChecked]="checked" [disabled]="disabled"></lua-switch>
</div>