import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DateHelper, FileHelper, IdHelper } from '@osapp/helpers';
import { EPrefix, ETimetablePattern, IContact } from '@osapp/model';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { EInvoiceStatus } from 'apps/idl/src/modules/facturation/models/einvoice-status';
import { iif, of } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FacturationService } from '../../../../../modules/facturation/facturation.service';
import { Invoice } from '../../../../../modules/facturation/models/invoice';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../../modules/patients/services/patients.service';
import { DeviceService } from '../../../shared/services/device.service';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { PanneauService } from '../../../shared/services/panneau.service';
import { ConfirmationAnnulationComponent } from '../confirmation-annulation/confirmation-annulation.component';
import { EtatTiersPayantComponent } from '../etat-tiers-payant/etat-tiers-payant.component';
import { ModeDeReglementComponent } from '../mode-de-reglement/mode-de-reglement.component';


interface IFspItem {
	blob: Blob;
	name: string;
	url: string;
}
@Component({
	selector: 'di-panneau-detail-facture',
	templateUrl: './panneau-detail-facture.component.html',
	styleUrls: ['./panneau-detail-facture.component.scss'],
})
export class PanneauDetailFactureComponent extends DestroyableComponentBase implements OnInit {

	@Input() invoice?: Invoice;
	@Input() patient?: IPatient;
	public total: number = 0;
	public fullLastName: string;
	public birthDate: string;
	public dateInvoice: string;
	@Input() infirmier: IContact;
	public amcInfo: string;
	public amoInfo: string;
	public isMobile: boolean;
	showPopover: boolean = false;
	isBtnCancelDisabled: boolean = true;
	isAvoir: boolean = false;
	public showTag: boolean = false;
	public tag: { label: string; color: string; mode: string };
	public isCancelled: boolean = false;
	public avoir?: Invoice;
	invoiceCanceled: Invoice;
	dateInvoiceCanceled: string;
	authorCancelInvoice: IContact;


	constructor(
		private svcPatients: PatientsService,
		private svcPanneau: PanneauService,
		private svcContact: ContactsService,
		private router: Router,
		private svcFacturation: FacturationService,
		private svcDevice: DeviceService,
		private readonly svcFilesystem: FilesystemService,
		private readonly ioFileOpener: FileOpener,
		private svcDrawerPopover: DrawerPopoverService
	) {
		super()
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
		this.svcFacturation.invoiceChanges$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
			this.initValuePanneau();
		});
		this.initValuePanneau();
	}

	private initValuePanneau() {
		this.svcFacturation.getInvoice(this.invoice._id).pipe(
			takeUntil(this.destroyed$),
			tap((invoice: Invoice) => {
				this.invoice = invoice;
				this.total = this.invoice?.totalPartAMC + this.invoice?.totalPartAMO + this.invoice?.totalPartPatient;
				this.isCancelled = this.invoice?.status?.value === EInvoiceStatus.canceled;
				this.isBtnCancelDisabled = this.isCancelled;
				this.showTag = this.isCancelled;
				if (this.isCancelled) {
					this.tag = { label: "Annulée", color: "Raspberry", mode: "outlined" };
				}
				this.isAvoir = this.invoice.invoiceType === 'avoir';
			}),
			switchMap(_ => iif(
				() => this.isCancelled && !!this.invoice?.authorCancelId,
				this.svcContact.getContact(this.invoice.authorCancelId).pipe(tap((contact: IContact) => this.authorCancelInvoice = contact)),
				of(undefined)
			)),
			mergeMap((_) => {
				//Si c'est un avoir il faut récupérer les infos de la facture annulée
				if (this.isAvoir && this.invoice.invoiceSourceId) {
					return this.svcFacturation.getInvoice(this.invoice.invoiceSourceId)
						.pipe(
							tap((invoice: Invoice) => {
								this.invoiceCanceled = invoice;
								this.dateInvoiceCanceled = DateHelper.transform(this.invoiceCanceled.date, ETimetablePattern.dd_MM_yyyy_HH_mm_slash);
							})
						)
				}

				return of(null);
			}),
			switchMap(_ => iif(
				() => this.invoice.avoirInvoiceId !== undefined,
				this.svcFacturation.getInvoice(this.invoice.avoirInvoiceId),
				of(undefined)
			)),
			tap((avoir: Invoice) => {
				if (avoir) {
					this.avoir = avoir;
				}
			})
		).subscribe();

		this.fullLastName = this.svcPatients.getFullNamePatient(this.patient);
		this.dateInvoice = DateHelper.transform(this.invoice.date, ETimetablePattern.dd_MM_yyyy_HH_mm_slash);
		if (this.patient.birthDate) {
			let birthDateFormatted = new Date(this.patient.birthDate);
			this.birthDate = `${birthDateFormatted.getDate()} ${birthDateFormatted.toLocaleString('fr-FR', { month: 'long' })} ${birthDateFormatted.getFullYear()}`;
		}

		this.amoInfo = this.invoice.amoId ? IdHelper.extractIdWithoutPrefix(this.invoice.amoId, EPrefix.amo) : '-';
		this.amcInfo = this.invoice.amcId ? IdHelper.extractIdWithoutPrefix(this.invoice.amcId, EPrefix.amc) : '-';
	}

	public afficherInvoiceCanceled(invoiceAAfficher: Invoice): void {
		const panneauTitle = "Détail de la facture";
		const panneauContent = PanneauDetailFactureComponent;
		const panneauInputs = {
			invoice: invoiceAAfficher,
			patient: this.patient,
			infirmier: this.infirmier
		};
		this.svcPanneau.close();
		this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
	}

	public ouvrirDossierPatient(): void {
		this.svcPanneau.close();
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["patients", this.patient._id], { state: { tabSelected: "Informations" } })
		});
	}


	handleClick(event: Event, choix: string) {
		switch (true) {
			case choix === 'amo':
				this.openPanneauTier(choix);
				break;
			case choix === 'amc':
				this.openPanneauTier(choix);
				break;
			case choix === 'pp':
				this.openPanneauReglement();
				break;
		}
	}


	public openPanneauReglement(): void {

		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"Part Patient",
			"50%",
			event.currentTarget,
			ModeDeReglementComponent,
			{
				facture: this.invoice
			},
			() => (this.showPopover = false)
		);
	}

	public openPanneauTier(choix: string): void {
		let titre: string;
		switch (true) {
			case choix == "amo":
				titre = "Part Sécurité sociale";
				break
			case choix == "amc":
				titre = "Part Mutuelle";
				break
		}
		this.showPopover = true;
		this.svcDrawerPopover?.open(
			titre,
			"50%",
			event.currentTarget,
			EtatTiersPayantComponent,
			{ facture: this.invoice,
				typeTier: choix
			},
			() => (this.showPopover = false)
		);
	}

	public openAvoir(): void {
		const panneauTitle = "Détail de la facture";
		const panneauContent = PanneauDetailFactureComponent;
		const panneauInputs = {
			invoice: this.avoir,
			patient: this.patient,
			infirmier: this.infirmier
		};
		this.svcPanneau.close();
		this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
	}

	public async printFsp(): Promise<void> {
		try {
			// Assure que la méthode attendue est asynchrone et renvoie une Map<Invoice, IFspItem[]>
			const fsps = await this.svcFacturation.displayFspAnakin([this.invoice]);

			// Récupére la valeur de la Map correspondant à l'Invoice, qui est un tableau
			const fspItems = fsps ? fsps.get(this.invoice) : null;

			if (fspItems && fspItems.length > 0) {
				// Utilise le premier élément du tableau
				const fsp: IFspItem = fspItems[0];

				// Télécharge et ouvre le PDF correspondant
				await this.downloadPdfAsync(this.invoice, fsp);
			} else {
				console.error("Aucune FSP trouvé pour cette facture.");
			}
		} catch (error) {
			console.error("Erreur lors de la récupération ou du traitement des FSP : ", error);
		}
	}

	private async downloadPdfAsync(poInvoice: Invoice, poFsp: IFspItem): Promise<void> {
		const lsInvoicePdfName = `Facture n°${poInvoice.invoiceNumber} - ${poFsp.name}.pdf`;
		if (this.isMobile) {
			const lsPath = `Download/${lsInvoicePdfName}`;

			const lsUri = await this.svcFilesystem.createFileAsync(lsPath, poFsp.blob, Directory.ExternalStorage, true);
			await this.ioFileOpener.open(lsUri, "application/pdf");
		}
		else
			FileHelper.downloadBlob(poFsp.blob, lsInvoicePdfName);
	}

	public async cancelInvoice() {
		await this.svcFacturation.cancelActionAnakin(this.patient._id, this.invoice, null)
			.catch(error => {
				console.error("Erreur lors de l'annulation de la facture:", error);
			});

		this.svcFacturation.invoiceChange();
	}

	public openMenuConfirmationAnnulation(event: Event) {
		if (this.invoice?.status?.value === "canceled") return;

		this.svcDrawerPopover.open("Annulation facture", "200px", event.currentTarget, ConfirmationAnnulationComponent, {
			onConfirm: () => this.handleConfirm(),
			onCancel: () => this.handleCancel()
		});
	}


	private handleConfirm() {
		this.svcDrawerPopover.close();
		this.cancelInvoice();
	}

	private handleCancel() {
		this.svcDrawerPopover.close();
	}

	async printInvoice() {
		await this.svcFacturation.exportInvoicePdfAnakin(this.invoice, this.isMobile);
	}
}
