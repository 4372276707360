<div class="panneau-prersonalisation-workspace__container">
	<div class="container__content">
		<form [formGroup]="wsForm" (ngSubmit)="saveWSEdit()" class="formulaire">
			<p>Vous pouvez personnaliser le nom et la couleur de l’espace de travail pour faciliter la reconnaissance de l’espace sur lequel vous travaillez.</p>
			<lua-input formControlName="wsName" label="Nom affiché" placeholder="Nom affiché" [fullWidth]="true"></lua-input>
			<lua-color-picker *ngIf="selectedColor" label="Couleur de l’espace de travail"
					[selectedOptionsChange]="onOptionClick" [selectedColor]="selectedColor"></lua-color-picker>
			<div class="actionBtn">	
				<lua-action-principale libelle="Enregistrer" [isDisabled]="allValidForm()" (click)="saveWSEdit()"></lua-action-principale>
				<lua-bouton-texte (click)="onGoBackClick()" color="CouleurPrimaire" libelle="Annuler"></lua-bouton-texte>
			</div>
		</form>
	</div>
</div>