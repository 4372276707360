import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-bouton-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.css'],
})
export class FloatingComponent implements OnInit, OnChanges {
  @Input()
  iconName = '';

  @Input()
  right = '0px';

  @Input()
  bottom = '0px';

  @Input()
  isDisabled = false;

  @Output()
  onClick = new EventEmitter<Event>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.bottom) {
      this.el.nativeElement.style.setProperty('--my-bottom', `${this.bottom}`);
    }
    if (this.right) {
      this.el.nativeElement.style.setProperty('--my-right', `${this.right}`);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bottom) {
      this.el.nativeElement.style.setProperty('--my-bottom', `${this.bottom}`);
    }
    if (this.right) {
      this.el.nativeElement.style.setProperty('--my-right', `${this.right}`);
    }
  }

  getClasses() {
    return {
      disabled: this.isDisabled,
    };
  }

  onAction(event: Event): void {
    this.onClick.emit(event);
  }
}
