<div class="entete-desktop-container">
	<div class="entete-desktop-navbar-items">
		<lua-objet-cabinet [name]="siteName" [address]="siteCity"></lua-objet-cabinet>
		<div #menuUtilisateur>
			<ng-container *ngIf="contact">
				<lua-avatar (click)="openMenu()" [firstName]="firstName" [lastName]="lastName" [withTooltip]="false"
					[color]="color">
				</lua-avatar>
			</ng-container>
		</div>
	</div>
	<div *ngIf="workspacelist" class="switchWorkspaceBandeau">
		<div (click)="switchWorkspace()" class="switchAction">
			<lua-icone [isIcon]="true" customIconName="desmos" height="40px" width="40px"></lua-icone>
			<h4>{{nomWorkspace}}</h4>
			<lua-bouton-icone mode="outlined" iconName="sync_alt" color="Blanc"
			></lua-bouton-icone>
		</div>
		<div class="personalisationWS">
			<lua-bouton-icone iconName="tune" color="Blanc"
			(click)="openPanneauPersonalisationWS()"></lua-bouton-icone>
		</div>
		
	</div>
</div>