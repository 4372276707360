<div class="lua-accordion">
	<div class="accordion-header" (click)="toggle()" [ngClass]="{'accordion-header-left': iconPosition === 'left', 'accordion-header-right': iconPosition === 'right', 'accordion-header-content': title.length === 0 }">
		<lua-icone *ngIf="iconPosition === 'left'" color="Noir" [iconName]="iconName" fontSize="22px" mode="filled"></lua-icone>
		<ng-content *ngIf="title.length === 0" select="[header]"></ng-content>
		<ng-container *ngIf="title.length > 0">
			<ng-container *ngIf="mode === 'small'; else defaultHeader">
				<h4>{{title}}</h4>
			</ng-container>
			<ng-template #defaultHeader>
				<h2>{{title}}</h2>
			</ng-template>
		</ng-container>
		<lua-icone *ngIf="iconPosition === 'right'" color="Noir" [iconName]="iconName" fontSize="22px" mode="filled"></lua-icone>
	</div>
	<div class="accordion-body" *ngIf="open" [ngClass]="{'accordion-body-left': iconPosition === 'left' }">
		<ng-content></ng-content>
	</div>
</div>