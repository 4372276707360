<div class="lua-tag" 
[ngClass]="{
  'size-xs': size === 'extra small',
  'size-s': size === 'small',
  'size-m': size === 'medium',
  filled: mode === 'filled',
  outlined: mode === 'outlined'
}">
  <lua-icone
    class="icon"
    *ngIf="iconName"
    [mode]="'outlined'"
    [iconName]="iconName"
    [color]="color"
    [fontSize]="getFontSize()"
  ></lua-icone>
  <div class="label">
    {{ label }}
  </div>
</div>
