<div class="lua-filter-date-picker">
  <small *ngIf="dateSelected">{{ label }}</small>
  <button 
  mat-button 
  (click)="openPicker()" 
  [ngClass]="{
    'full-width': fullWidth,
    active: dateSelected
  }" 
  type="button">
    <mat-icon>calendar_today</mat-icon>
    <p *ngIf="dateSelected">{{ displayDate }}</p>
    <p *ngIf="!dateSelected">{{ label }}</p>
  </button>

  <div class="hidden-input">
    <input
      matInput
      [matDatepicker]="singlePicker"
      (dateChange)="onDateChange($event)"
      [min]="min || null"
      [max]="max || null"
    />
    <mat-datepicker #singlePicker></mat-datepicker>
  </div>

  <div class="hidden-input" *ngIf="isRange">
    <mat-date-range-input [rangePicker]="rangePicker" #rangeInput [min]="min || null" [max]="max || null">
      <input matStartDate (dateChange)="onDateChange($event)" />
      <input matEndDate (dateChange)="onDateChange($event)" />
    </mat-date-range-input>
    <mat-date-range-picker #rangePicker></mat-date-range-picker>
  </div>
</div>
