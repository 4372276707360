import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'lua-data-picker',
  templateUrl: './data-picker.component.html',
  styleUrls: ['./data-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataPickerComponent),
      multi: true,
    },
  ],
})
export class DataPickerComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['resetSelection'] &&
      changes['resetSelection'].currentValue === true
    ) {
      this.selectedOptions = [];
      this.resetSelection = false;
    }
  }

  ngOnInit(): void {}

  @Input() options: {
    value: string;
    label: string;
    iconName: string;
    customIconName: string;
    disabled: boolean;
  }[] = [];
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input() multiSelection = false;
  @Input() fullWidth = false;
  @Input() label = '';
  @Input() secondaryLabel = '';
  @Input() resetSelection = false;

  @Output() selectedOptionsChange = new EventEmitter<string[]>();

  disabled = false;
  @Input() selectedOptions: string[] = [];

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: string[]): void {
    this.selectedOptions = value || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onOptionClick(option: string) {
    if (this.multiSelection) {
      if (this.selectedOptions?.includes(option)) {
        this.selectedOptions = this.selectedOptions?.filter(
          opt => opt !== option
        );
      } else {
        this.selectedOptions?.push(option);
      }
    } else {
      if (this.selectedOptions?.includes(option)) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions = [option];
      }
    }

    this.onChange(this.selectedOptions);
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  getClasses() {
    return {
      horizontal: this.direction === 'horizontal',
      vertical: this.direction === 'vertical',
      'full-width': this.fullWidth,
    };
  }
}
