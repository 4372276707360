<button
  [ngClass]="{
    'full-width': fullWidth,
    'size-s': size === 'small',
    'size-m': size === 'medium',
    'size-l': size === 'large',
    'btn-desactive': isDisabled
  }"
  class="lua-action-secondaire"
  mat-stroked-button
  [disabled]="isDisabled"
  type="button"
>
  <lua-icone
    *ngIf="avecIcone && positionIcone === 'L'"
    mode="outlined"
    [iconName]="nomIcone"
    [color]="color"
    fontSize="30px"
  >
  </lua-icone>
  <div class="libelle">
    {{ libelle }}
  </div>
  <lua-icone
    *ngIf="avecIcone && positionIcone === 'R'"
    mode="outlined"
    [iconName]="nomIcone"
    [color]="color"
    fontSize="30px"
  >
  </lua-icone>
</button>
