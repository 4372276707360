<ng-container>
	<form [formGroup]="accordPrealableDemandeForm">
		<div class="content__form-inputs">
			<div class="form-inputs">
				<h2>Acte(s) concerné(s)</h2>
				<di-acte *ngFor="let acte of actes" 
					[acte]="acte" 
					[noCheckForce]="true"></di-acte>
				<h2 class="mt-30">Demande d'accord préalable</h2>
				<lua-input 
						label="Date du de la demande" 
						type="date" 
						[fullWidth]="true"
						formControlName="dateDemande">
				</lua-input>
				<lua-select 
					class="mb-10" 
					label="Demandeur" 
					[options]="infirmiers" 
					placeholder="Demandeur"
					formControlName="demandeur">
				</lua-select>
				<lua-switch 
					label="Actes urgents" 
					texteSecondaire="Dans ce cas, vous pouvez générer les soins"
					(clickSwitch)="toggleActeUrgents($event)" 
					[isChecked]="actesUrgents">
				</lua-switch>
				<lua-switch 
					label="Actes en série" 
					(clickSwitch)="toggleActeEnSerie($event)"
					[isChecked]="actesEnSerie">
				</lua-switch>
				<lua-input 
					[multiline]="true" 
					label="Éléments médicaux justifiant les actes" 
					[fullWidth]="true" 
					class="mb-30"
					formControlName="justification">
				</lua-input>
				<lua-alerte *ngIf="erreurImpression" 
					[filled]="true" 
					type="error" 
					[message]="erreurImpression">
				</lua-alerte>
				<lua-alerte *ngIf="warningImpression" 
					[filled]="true" 
					type="warning" 
					[message]="warningImpression">
				</lua-alerte>
				<lua-bouton-texte 
					class="mb-30" 
					libelle="Imprimer la demande" 
					startIcon="print" 
					[fullWidth]="true"
					(click)="handleSubmitDemande($event)" 
					[isDisabled]="!accordPrealableDemandeForm.valid">
				</lua-bouton-texte>
			</div>
		</div>
	</form>
	<form [formGroup]="accordPrealableRetourForm">
		<div class="content__form-inputs">
			<div class="form-inputs">
				<h2>Retour d'accord préalable</h2>
				<lua-input 
					label="Date du retour" 
					type="date" 
					[fullWidth]="true" 
					class="mb-10" 
					formControlName="dateRetour"
					[disabled]="!accord">
				</lua-input>
				<lua-radio-button 
					label="OUI, demande d'actes acceptée" 
					(click)="selectRetourDemande(true)"
					[checked]="selectedRetourDemande" 
					[disabled]="!accord">
				</lua-radio-button>
				<lua-radio-button 
					label="NON, demande d'actes refusée" 
					(click)="selectRetourDemande(false)"
					[checked]="selectedRetourDemande === false" 
					[disabled]="!accord" 
					class="mb-10">
				</lua-radio-button>
				<di-carte-photo *ngIf="fileRetour" 
					[photoFile]="fileRetour" 
					[edit]="true"
					(delete)="deleteOldFile($event)">
				</di-carte-photo>
				<di-carte-photo *ngIf="newFileRetour" 
					[photoFile]="newFileRetour" 
					[edit]="true"
					(delete)="deleteNewFile($event)">
				</di-carte-photo>
				<lua-alerte *ngIf="isErrorPhoto" 
					[filled]="true" 
					[fullWidth]="true" 
					type="error" 
					iconName="info"
					[message]="errorMessage">
				</lua-alerte>
				<di-input-file *ngIf="!newFileRetour && !fileRetour && accord"
					(inputClick)="addDocument($event)">
				</di-input-file>
				<lua-action-principale 
					libelle="Enregistrer" 
					[fullWidth]="true"
					[isDisabled]="!accordPrealableRetourForm.valid || !accord"
					(click)="handleSubmitRetour($event)">
				</lua-action-principale>
				<lua-bouton-texte 
					[fullWidth]="true" 
					libelle="Annuler" 
					(click)="handleCancel()">
				</lua-bouton-texte>
			</div>
		</div>
	</form>
	<div class="content__footer">
		<div class="footer__container" *ngIf="accord">
			<lua-divider-horizontal></lua-divider-horizontal>
			<lua-bouton-texte 
					[fullWidth]="true" 
					color="Error" 
					libelle="Supprimer" 
					startIcon="delete"
					(click)="deleteAP($event)">
			</lua-bouton-texte>
		</div>
	</div>
</ng-container>