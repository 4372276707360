import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateHelper, GuidHelper } from '@osapp/helpers';
import { EPrefix, IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { takeUntil } from 'rxjs/operators';
import { IRetrocession } from '../../../../modules/patients/model/IRetrocession';
import { Retrocession } from '../../../../modules/patients/model/Retrocession';
import { DeviceService } from '../../../features/shared/services/device.service';
import { RetrocessionService } from '../../../features/shared/services/retrocession.service';

@Component({
	selector: 'di-form-retrocession',
	templateUrl: './form-retrocession.component.html',
	styleUrls: ['./form-retrocession.component.scss'],
})
export class FormRetrocessionComponent extends DestroyableComponentBase implements OnInit {

	@Input() retrocession?: Retrocession
	@Input() utilisateurs: IContact[];

	public isMobileView: boolean = false;
	public userSelected: IContact;
	public model: Retrocession;
	public retrocessionForm: FormGroup;
	public remplacantTag = {
		tagLabel: "Remplaçant",
		tagColor: "CouleurPrimaire",
		tagMode: "filled",
	};
	public validDates: boolean = false;

	constructor(
		private svcDevice: DeviceService,
		private fb: FormBuilder,
		private svcRetrocession: RetrocessionService
	) {
		super();
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});

		let dateDebut = new Date();
		dateDebut.setDate(1);

		//On sélectionne dans la liste l'infirmier que l'on veut modifier
		if (this.retrocession && this.retrocession.infirmier) {
			this.userSelected = this.retrocession.infirmier;
		}

		this.retrocessionForm = this.fb.group({
			dateDebut: [this.retrocession ? DateHelper.toDateUrl(this.retrocession.dateDebut) : DateHelper.toDateUrl(dateDebut), Validators.required],
			dateFin: [this.retrocession ? DateHelper.toDateUrl(this.retrocession.dateFin) : "", Validators.required],
			poucentageActeMajo: [this.retrocession ? this.retrocession.pourcentageActesMajorations : "80", [Validators.required, Validators.min(0), Validators.max(100)]],
			pourcentageIndem: [this.retrocession ? this.retrocession.pourcentageIndemnites : "100", [Validators.required, Validators.min(0), Validators.max(100)]],
			commentaire: [this.retrocession ? this.retrocession.commentaire ?? "" : ""],
		});
		if (!this.retrocession)
			this.initDateFin();
		else
			this.model = this.retrocession
		this.verifyDates();
	}

	private initDateFin() {
		const dateDebutValue = this.retrocessionForm.get('dateDebut')?.value;
		if (!DateHelper.isDate(dateDebutValue)) return;
		let dateFin = new Date(dateDebutValue);
		dateFin.setMonth(dateFin.getMonth() + 1);
		dateFin.setDate(0);
		this.retrocessionForm.get('dateFin')?.setValue(DateHelper.toDateUrl(dateFin));
	}

	public onChangeValueDateDebut() {
		this.initDateFin();
		this.verifyDates();
	}

	public onChangeValueDateFin() {
		this.verifyDates();
	}

	public verifyDates(): void{
		const dateDebut: Date = new Date(this.retrocessionForm.get('dateDebut').value);
		const dateFin: Date = new Date(this.retrocessionForm.get('dateFin').value); 
		this.validDates = dateDebut <= dateFin; 
	}

	public handleClick(event: Event, user: IContact) {
		this.userSelected = user;
	}

	public goBack(): void {
		this.svcRetrocession.triggerRefreshRetrocessionList();
	}

	validateInput(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		let value = Number(inputElement.value);

		if (value < 0) {
			inputElement.value = '0';
		} else if (value > 100) {
			inputElement.value = '100';
		}
	}


	public validForm(event: Event): void {
		event.preventDefault();
		if (this.retrocessionForm.valid) {
			const formValues = this.retrocessionForm.value;
			let formRetro = {}
			if (this.model) {
				formRetro = { ...this.model }
			} else {
				formRetro = {
					_id: ''
				}
			}
			formRetro = {
				...formRetro,
				...formValues
			}
			this.createOrUpdateRetrocession(formRetro)
		}
	}

	private createOrUpdateRetrocession(retroValue: any) {
		let retrocession: IRetrocession;
		if (retroValue._id === '') {
			this.svcRetrocession.generateNumber().subscribe((numberRetrocession: number) => {
				retrocession = {
					_id: `${EPrefix.retrocession}${GuidHelper.newGuid()}`,
					createurId: ContactsService.getContactIdFromUserId(UserData.current._id),
					infirmierId: this.userSelected._id,
					dateCreation: new Date(),
					dateDebut: retroValue.dateDebut,
					dateFin: retroValue.dateFin,
					pourcentageActesMajorations: Number(retroValue.poucentageActeMajo),
					pourcentageIndemnites: Number(retroValue.pourcentageIndem),
					commentaire: retroValue.commentaire,
					estImprime: false,
					numeroRetrocession: numberRetrocession,
				};
				this.saveRetrocession(retrocession);
			})

		}
		else {
			retrocession = this.model;
			retrocession.infirmierId = this.userSelected._id;
			retrocession.dateCreation = new Date();
			retrocession.dateDebut = retroValue.dateDebut;
			retrocession.dateFin = retroValue.dateFin;
			retrocession.pourcentageActesMajorations = Number(retroValue.poucentageActeMajo);
			retrocession.pourcentageIndemnites = Number(retroValue.pourcentageIndem);
			retrocession.estImprime = false;
			retrocession.commentaire = retroValue.commentaire;
			delete retrocession.infirmier;
			this.saveRetrocession(retrocession);

		}
	}

	private saveRetrocession(retrocession: IRetrocession): void {
		this.svcRetrocession.createOrUpdate(retrocession).subscribe({
			next: () => {
				this.svcRetrocession.triggerRefreshRetrocessionList(retrocession._id);
			},
			error: (error) => {
			}
		});
	}
}
