import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-loader-horizontal',
  templateUrl: './loader-horizontal.component.html',
  styleUrls: ['./loader-horizontal.component.css'],
})
export class LoaderHorizontalComponent implements OnChanges {
  @Input() color = 'Raspberry';
  @Input() progressBar = false;
  @Input() onGoingProgress? = false;
  @Input() initialProgress = 0;
  @Input() targetProgress = 100;
  @Input() speed = 1;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialProgress'] || changes['targetProgress']) {
      this.updateProgress();
    }
    if (changes['color']) {
      this.el.nativeElement.style.setProperty(
        '--loader-color',
        `var(--${this.color})`
      );
    }
    if (changes['speed']) {
      this.el.nativeElement.style.setProperty(
        '--loader-speed',
        `${this.speed}s`
      );
    }
  }

  updateProgress() {
    const progressRatio = (this.initialProgress / this.targetProgress) * 100;
    this.el.nativeElement.style.setProperty(
      '--loader-progress',
      `${progressRatio}%`
    );
  }

  manageProgress() {
    if (
      this.progressBar &&
      this.onGoingProgress &&
      this.initialProgress < this.targetProgress
    ) {
      this.initialProgress += 1;
      this.updateProgress();
    }
  }

  ngOnInit(): void {
    setInterval(() => this.manageProgress(), 150);
    this.el.nativeElement.style.setProperty(
      '--loader-color',
      `var(--${this.color})`
    );
    this.el.nativeElement.style.setProperty('--loader-speed', `${this.speed}s`);
    this.updateProgress();
  }

  getClasses() {
    return {
      indeterminateMode: !this.progressBar,
    };
  }
}
