import { Component, Input, OnInit } from '@angular/core';
import { IViewCountResult } from '@osapp/model/IViewCountResult';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { forkJoin } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'di-menu-mois',
	templateUrl: './menu-mois.component.html',
	styleUrls: ['./menu-mois.component.scss'],
})
export class MenuMoisComponent extends DestroyableComponentBase implements OnInit {
	@Input() public onMonthSelected: (month: number, year: number) => void = () => {};

	public selectedYear: number;
	public selectedMonth: number;
	public years: Map<number, Map<number, { montantTotal: number; invoiceCount: number }>> = new Map();
	public yearsArray: {
		year: number;
		months: {
			month: number;
			montantTotal: number;
			invoiceCount: number;
		}[];
	}[];

	constructor(private svcFacturation: FacturationService) { 
		super();
	}

	ngOnInit(): void {
		
		const now = new Date();
		this.selectedYear = now.getFullYear();
		this.selectedMonth = now.getMonth();
		const previousMonths: { year: number, month: number }[] = this.getPreviousMonths();
		this.getPreviousMonthData(previousMonths);
		this.svcFacturation.invoiceChanges$
			.pipe(
				takeUntil(this.destroyed$))
				.subscribe(() => {
					this.getPreviousMonthData([{ year: now.getFullYear(), month: now.getMonth() }]);
				});
	}

	private getPreviousMonths(count = 12): { year: number, month: number }[] {
		const result: { year: number, month: number }[] = [];
		let year = this.selectedYear;
		let month = this.selectedMonth;
		for (let i = 0; i < count; i++) {
			result.push({ year, month });
			month--;
			if (month === -1) {
				month = 11;
				year--;
			}
		}
		return result;
	}

	private getPreviousMonthData(previousMonths: { year: number, month: number }[]) : void{
		const requests = previousMonths.map(({ year, month }) =>
			this.svcFacturation.getTotalHonorairesByMonth(month, year).pipe(
				map((result: IViewCountResult[]) => ({ year, month, result })),
				takeUntil(this.destroyed$)
			)
		);

		forkJoin(requests).subscribe((responses) => {
			const now = new Date();
			const currentYear = now.getFullYear();
			const currentMonth = now.getMonth();
			responses.forEach(({ year, month, result }) => {
				if (!this.years.has(year)) {
					this.years.set(year, new Map());
				}
				const yearMap = this.years.get(year);
				let montantTotal: number = 0;
				let invoiceCount: number = 0;
				result.forEach((viewCount: IViewCountResult) => {
					montantTotal += viewCount.value;
					invoiceCount++;
				});
				if (invoiceCount > 0 || (year === currentYear && month === currentMonth)) {
					yearMap?.set(month, { montantTotal, invoiceCount });
				}
			});
			this.yearsArray = Array.from(this.years.entries()).map(([year, monthsMap]) => {
				if(monthsMap.size === 0){
					return undefined;
				}
				return {
					year,
					months: Array.from(monthsMap.entries()).map(([month, data]) => ({
						month,
						...data
					}))
					.sort((a, b) => b.month - a.month),
				} 
			})
			.filter(Boolean)
			.sort((a, b) => b.year - a.year);
		});
	}

	public wrappedOnMonthSelected = (event: { month: number; year: number }) => {
		this.selectedMonth = event.month;
		this.selectedYear = event.year;
		this.onMonthSelected(event.month, event.year);
	}
}

