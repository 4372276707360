import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-message-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
  @Input() iconClass = 'material-icons-outlined';
  @Input() iconName = 'info';
  @Input() messageTitle = '';
  @Input() messageDetail = '';
  @Input() buttonLabel = '';
  @Input() buttonColor = 'CouleurPrimaire';
  @Input() buttonIconName = '';

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public size = ESize.M;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  onAction(event: Event) {
    this.onClick.emit(event);
  }
}
