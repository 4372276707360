<div class="lua-appbar-item" [class.selected]="selected">
  <div *ngIf="!menuOpen && iconName" class="icon centered" >
    <lua-badge *ngIf="badgeNumber > 0" [number]="badgeNumber" class="menu-closed">
      <div class="icone-badge">
      <lua-icone
        [mode]="iconMode"
        [iconName]="iconName"
        [color]="colorIcon"
        fontSize="25px"
      ></lua-icone>
    </div>
    </lua-badge>
    <lua-icone
      *ngIf="badgeNumber == 0"
      [mode]="iconMode"
      [iconName]="iconName"
      [color]="colorIcon"
      fontSize="25px"
    ></lua-icone>
  </div>

  <div *ngIf="menuOpen && iconName" class="menu-item">
    <div class="icon">
      <lua-badge *ngIf="badgeNumber > 0" [number]="badgeNumber">
        <div class="icone-badge">
          <lua-icone
          [mode]="iconMode"
          [iconName]="iconName"
          [color]="colorIcon"
          fontSize="25px"
        ></lua-icone>
        </div>
      </lua-badge>
      <lua-icone
        *ngIf="badgeNumber == 0"
        [mode]="iconMode"
        [iconName]="iconName"
        [color]="colorIcon"
        fontSize="25px"
      ></lua-icone>
    </div>
    <div class="text" [class.with-badge]="badgeNumber>0" >
      <p *ngIf="!selected">{{ text }}</p>
      <strong *ngIf="selected">{{ text }}</strong>
    </div>
  </div>
</div>