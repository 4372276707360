<div class="lua-message-success">
  <lua-icone
    mode="filled"
    [iconName]="iconName"
    color="OK"
    fontSize="30px"
  ></lua-icone>
  <h4>{{ messageTitle }}</h4>
  <small>{{ messageDetail }}</small>
  <div *ngIf="buttonLabel" class="boutton">
    <lua-action-secondaire
      [fullWidth]="false"
      [size]="size"
      [libelle]="buttonLabel"
      [color]="buttonColor"
      [avecIcone]="!!buttonIconName"
      [nomIcone]="buttonIconName"
      positionIcone="L"
      (click)="onAction($event)"
    >
    </lua-action-secondaire>
  </div>
</div>
