import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';

interface ColorOption {
  name: string;
  value: string;
}
@Component({
  selector: 'lua-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
})
export class ColorPickerComponent implements OnInit, OnChanges {
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  @Input() selectedOptionsChange: (colColorOptior: ColorOption[]) => void =
    () => {};
  @Input() fullWidth = false;
  @Input() label = '';
  @Input() secondaryLabel = '';
  @Input() colors = [
    {
      name: 'Indigo',
      value: 'Indigo',
    },
    {
      name: 'Raspberry',
      value: 'Raspberry',
    },
    {
      name: 'OrangeClair',
      value: 'OrangeClair',
    },
    {
      name: 'Warning',
      value: 'Warning',
    },
    {name: 'OK', value: 'OK'},
    {name: 'BleuVif', value: 'BleuVif'},
  ];
  @Input() selectedColor: ColorOption[] = [];

  onOptionClick(option: ColorOption) {
    if (this.selectedColor.includes(option)) {
      this.selectedColor = [];
    } else {
      this.selectedColor = [option];
    }
    this.selectedOptionsChange(this.selectedColor);
  }
  isSelected(option: ColorOption): boolean {
    return this.selectedColor.some(selected => selected.name === option.name);
  }
}
