<div class="lua-filtres" [ngClass]="classes">
  <small *ngIf="filtered">{{ label }}</small>
  <button type="button" (click)="onClick()">
    <lua-icone
      *ngIf="iconName"
      [iconName]="iconName"
      [color]="textColor"
      mode="outlined"
    ></lua-icone>

    <ng-container *ngIf="!filtered; else filtersContent">
      <p>{{ label }}</p>
    </ng-container>

    <ng-template #filtersContent>
      <p class="filter-item">{{ selectedFilters.join(', ') }}</p>
      <lua-tag
        *ngIf="selectedFilters.length > 1"
        [label]="selectedFilters.length.toString()"
        mode="outlined"
        [color]="textColor"
      ></lua-tag>
    </ng-template>
  </button>
</div>
