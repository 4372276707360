<div class="di-carte-facturation" (click)="handleClickCarte($event)">
	<div class="facturation-header">
		<div class="patient-info">

			<lua-objet-patient *ngIf="invoice?.patient" size="small" [firstName]="invoice?.patient?.firstName"
				[lastName]="fullLastNamePatient" [info]="adressePatient"></lua-objet-patient>
		</div>
		<div *ngIf="!isMobile" class="facturation-parts">
			<di-part-facturation [label]="invoice?.labelAMO ? invoice.labelAMO : 'AMO'"
				[amount]="invoice?.totalPartAMO ? invoice.totalPartAMO  : null"
				[status]="invoice?.statusAMO ? invoice.statusAMO : 'aEnvoyer'"
				[labelStatus]="invoice?.securisationData?.ref ? 'FSE n°' + invoice.securisationData?.ref : null"
				[avoir]="isAvoir"
				(click)="invoice.totalPartAMO > 0 ? !isAvoir && handleClick($event,'amo') : null"></di-part-facturation>

			<di-part-facturation [label]="invoice?.labelAMC ? invoice.labelAMC : 'AMC'"
				[amount]="invoice?.totalPartAMC ? invoice.totalPartAMC : null"
				[status]="invoice?.statusAMC ? invoice.statusAMC : 'aEnvoyer'" [avoir]="isAvoir"
				(click)="invoice.totalPartAMC > 0 ? !isAvoir && handleClick($event,'amc') : null"></di-part-facturation>

			<di-part-facturation label="Patient" [amount]="invoice?.totalPartPatient ? invoice.totalPartPatient : null"
				[status]="invoice?.statusPat ? invoice.statusPat : 'aRegler'" [avoir]="isAvoir"
				(click)="invoice?.totalPartPatient ? !isAvoir && handleClick($event,'pp') : null"></di-part-facturation>
		</div>
		<div class="total-amount">
			<small *ngIf="!isMobile">{{isAvoir ? "Avoir" : "Facture"}} n° {{invoice.invoiceNumber | number}}</small>
			<h3 [ngClass]="{'negative-amount' : isAvoir}">{{ isAvoir ? '-' : '' }}{{total | priceFormat:2}}€</h3>
			<lua-tag *ngIf="showTag" [label]="tag.label" [color]="tag.color" [mode]="tag.mode"></lua-tag>

		</div>
	</div>
	<lua-divider-horizontal *ngIf="isMobile" size="small"></lua-divider-horizontal>
	<div *ngIf="isMobile" class="facturation-parts">
		<di-part-facturation [label]="invoice?.labelAMO ? invoice.labelAMO : 'AMO'"
			[amount]="invoice?.totalPartAMO ? invoice.totalPartAMO  : null"
			[status]="invoice?.statusAMO ? invoice.statusAMO : 'aEnvoyer'"
			[labelStatus]="invoice?.securisationData?.ref ? 'FSE n°' + invoice.securisationData?.ref : null" [avoir]="isAvoir"
			(click)="invoice.totalPartAMO > 0 ? !isAvoir && handleClick($event,'amo') : null"></di-part-facturation>

		<di-part-facturation [label]="invoice?.labelAMC ? invoice.labelAMC : 'AMC'"
			[amount]="invoice?.totalPartAMC ? invoice.totalPartAMC : null"
			[status]="invoice?.statusAMC ? invoice.statusAMC : 'aEnvoyer'" [avoir]="isAvoir"
			(click)="invoice.totalPartAMC > 0 ? !isAvoir && handleClick($event,'amc') : null"></di-part-facturation>

		<di-part-facturation label="Patient" [amount]="invoice?.totalPartPatient ? invoice.totalPartPatient : null"
			[status]="invoice?.statusPat ? invoice.statusPat : 'aRegler'" [avoir]="isAvoir"
			(click)="invoice?.totalPartPatient ? !isAvoir && handleClick($event,'pp') : null"></di-part-facturation>

	</div>

	<div class="patient-additional-info">
		<di-infirmier [showMenu]="false" *ngIf="invoice?.infirmier" [infirmier]="invoice.infirmier" [date]="dateInvoice"
			color="CouleurPrimaire"></di-infirmier>
		<di-infirmier *ngIf="!invoice?.infirmier" color="Noir20" [showMenu]="false"></di-infirmier>
	</div>
</div>