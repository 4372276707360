<button 
	[ngClass]="{
		'full-width': fullWidth,
		'btn-desactive': isDisabled
	}" 
	[type]="type" 
	class="lua-action-principale" 
	mat-raised-button 
	[disabled]="isDisabled">
	<lua-icone *ngIf="startIcon" class="startIcone" mode="outlined" [iconName]="startIcon" [color]="color"
		fontSize="30px">
	</lua-icone>
	{{libelle}}
	<lua-icone *ngIf="endIcon" class="endIcone" mode="outlined" [iconName]="endIcon" [color]="color" fontSize="30px">
	</lua-icone>
</button>