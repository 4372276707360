
import { IPicture } from '../../../model/picture/IPicture';
import { ISite } from './isite';

export class Site implements ISite {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public name: string;
	/** @implements */
	public picture?: IPicture;
	/** @implements */
	public email?: string;
	/** @implements */
	public phone?: string;
	/** @implements */
	public street?: string;
	/** @implements */
	public zipCode?: string;
	/** @implements */
	public city?: string;
	/** @implements */
	public isDROM?: boolean = false;

	//#endregion

	//#region METHODS

	constructor(poSite?: ISite) {
		if (poSite) {
			this._id = poSite._id;
			this._rev = poSite._rev;
			this.name = poSite.name;
			this.picture = poSite.picture;
			this.email = poSite.email;
			this.phone = poSite.phone;
			this.street = poSite.street;
			this.zipCode = poSite.zipCode;
			this.city = poSite.city;
			this.isDROM = poSite.isDROM;
		}
	}


	//#endregion

}
