import {Component, Input, OnInit} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-objet-patient',
  templateUrl: './objet-patient.component.html',
  styleUrls: ['./objet-patient.component.css'],
})
export class ObjetPatientComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() firstName = '';

  @Input() lastName = '';

  @Input() info = '';

  @Input() customIconName = '';

  @Input() iconWidth = '';

  @Input() iconHeight = '';

  @Input() iconFontSize = '';

  @Input() deceased = false;

  @Input() size = ESize.M;

  @Input() gender: 'homme' | 'femme' = 'homme';

  showIcon() {
    return this.size === ESize.M;
  }

  getIconColor() {
    return this.deceased
      ? 'Noir'
      : !this.gender
        ? 'Gris2'
        : this.gender === 'homme'
          ? 'Bleu'
          : 'Raspberry2';
  }

  getClasses() {
    return {
      deceased: this.deceased,
    };
  }
}
