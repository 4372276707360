import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DeviceService } from '../../../features/shared/services/device.service';
import { StringHelper } from '@osapp/helpers';
import { IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { DateObject } from '../../../models/retrocession/DateObject';

@Component({
    selector: 'di-list-seances-retrocession',
    templateUrl: './list-seances-retrocession.component.html',
    styleUrls: ['./list-seances-retrocession.component.scss'],
})
export class ListSeancesRetrocessionComponent extends DestroyableComponentBase implements OnInit, OnChanges {
    @Input() public journees: { date: Date, dateObject: DateObject }[];
    @Input() public pourcentageActesMajorations: number;
    @Input() public pourcentageIndemnites: number;
    @Input() public infirmier: IContact;

    public isMobile: boolean = false;
    public infirmierFirstName: string = "";
    
    constructor(private svcDevice: DeviceService) {
        super();
     }
    
    ngOnInit(): void {
        this.svcDevice.isMobile$.pipe(
            takeUntil(this.destroyed$)
        ).subscribe((flag: boolean) => {
            this.isMobile = flag;
        });
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(this.infirmier?.firstName){
            this.infirmierFirstName = StringHelper.toTitleCase(this.infirmier.firstName);
        }
    }
}