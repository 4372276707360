import {ElementRef} from '@angular/core';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lua-action-principale',
  templateUrl: './action-principale.component.html',
  styleUrls: ['./action-principale.component.css'],
})
export class ActionPrincipaleComponent implements OnInit {
  @Input()
  public isDisabled = false;

  @Input()
  public libelle = '';

  @Input()
  public startIcon = '';

  @Input()
  public endIcon = '';

  @Input()
  public color = 'Blanc';

  @Input()
  public colorBackgroundImage = 'DegradeOrisha3Couleurs';

  @Input()
  public fullWidth = false;

  @Input()
  public type = 'button'; //exemple : type="submit" pour les formulaires, ou type="reset" si on veut clean tout un formulaire

  @Output()
  onClick = new EventEmitter<Event>();

  constructor(private el: ElementRef) {}

  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      'btn-desactive': this.isDisabled,
    };
  }

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
    this.el.nativeElement.style.setProperty(
      '--my-color-background',
      `var(--${this.colorBackgroundImage})`
    );
  }
}
