<div
  class="lua-bouton-toolbar"
  [ngClass]="{
    default: labelType === 'none',
    horizontal: labelType === 'horizontal',
    vertical: labelType === 'vertical',
    primary: primary,
    disabled: disabled
  }"
  (click)="handleClick($event)"
>
  <lua-icone
    [iconName]="iconName"
    [mode]="mode"
    [color]="primary ? 'Blanc' : 'CouleurPrimaire'"
    fontSize="20px"
    width="18px"
    height="18px"
  >
  </lua-icone>
  <small *ngIf="labelType !== 'none'" class="label">{{ label }}</small>
</div>
