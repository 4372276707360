import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'lua-message-action-en-cours',
  templateUrl: './action-encours.component.html',
  styleUrls: ['./action-encours.component.css'],
})
export class ActionEncoursComponent implements OnInit {
  @Input() mainText = '';
  @Input() subText = '';
  @Input() loaderSize = 60;
  @Input() loaderColor = '';
  @Input() loaderSpeed = 1;

  constructor() {}

  ngOnInit(): void {}
}
