<mat-form-field 
class="lua-input-filled" 
[ngClass]="{
	'full-width': fullWidth,
	isError: isError,
	multiline: isMultiline
}" 
appearance="fill">
	<mat-label>{{libelle}}</mat-label>
	<input  *ngIf=" !isMultiline && isAutoFocus" class="lua-input-filled" autofocus [(ngModel)]="value" type="{{type}}" matInput
		(input)="onChange($event)">
	<input *ngIf=" !isMultiline && !isAutoFocus" class="lua-input-filled" [(ngModel)]="value" type="{{type}}" matInput
		(input)="onChange($event)">
		<textarea *ngIf="isMultiline && isAutoFocus" class="lua-input-filled" autofocus [(ngModel)]="value" matInput (input)="onChange($event)"></textarea>
		<textarea *ngIf="isMultiline && !isAutoFocus" class="lua-input-filled" [(ngModel)]="value" matInput (input)="onChange($event)"></textarea>

	<lua-bouton-icone *ngIf="iconName && value" matSuffix [iconName]="iconName" [color]="buttonColor" 
	(onClick)="onClikIcon($event)"></lua-bouton-icone>
</mat-form-field>