<div class="di-list-retrocessions">
	<ng-container *ngIf="!isMobile">
		<h1 class="title">Rétrocessions</h1>
	</ng-container>
	<div class="add-button">
		<lua-action-secondaire
			libelle="Rétrocession"
			[fullWidth]="false"
			[avecIcone]="true"
			nomIcone="add"
			positionIcone="L"
			size="large"
			(click)="navigateToCreateRetrocession()"
		>
		</lua-action-secondaire>
	</div>
	<ng-container *ngIf="yearsArray && yearsArray.length > 0">
		<ng-container *ngFor="let yearObj of yearsArray">
			<div class="select-retrocession" *ngFor="let monthObj of yearObj.months">
				<di-separateur-date [date]="monthObj.dateSeparateur"></di-separateur-date>
				<ng-container *ngFor="let retrocession of monthObj.retrocessions">
					<div class="retrocession-item">
						<di-retrocession-item 
							[retrocession]="retrocession" 
							[selected]="selectedRetrocessionId === retrocession._id"
							(retrocessionSelected)="wrappedOnRetrocessionSelected($event)"
							>
						</di-retrocession-item>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!yearsArray || yearsArray.length === 0">
		<lua-message-aucune-donnee mainText="Aucune rétrocession" subText="Les rétrocessions apparaitront ici."></lua-message-aucune-donnee>
	</ng-container>
</div>