<div class="di-retrocession">
	<div *ngIf="(!isMobileView || (isMobileView && !selectedRetrocession && mode === 'list')) && utilisateurs || mode === 'list'" 
		class="list-retrocessions-container" 
		[ngClass]="{'listMode': isMobileView && mode === 'list'}">
		<di-list-retrocessions 
			[retrocessions]="retrocessions" 
			[selectedRetrocessionId] = "selectedRetrocession ? selectedRetrocession._id : null"
			[utilisateurs] = "utilisateurs"
			(onCreateRetrocession)="createNewRetrocession()"
			(onSelectRetrocession)="displayRetrocessionDetails($event)"
		></di-list-retrocessions>
	</div>

	<main class="retrocession-content" *ngIf="!isMobileView || (isMobileView && selectedRetrocession) || mode === 'form'">
		<ng-container *ngIf="mode === 'details'">
			<ng-container *ngIf="selectedRetrocession">
				<di-detail-retrocession 
					[retrocession]="selectedRetrocession" 
					(onEditRetrocession)="createNewRetrocession($event)"					
					[retrocessions]="retrocessions" 
					[selectedRetrocessionId] = "selectedRetrocession ? selectedRetrocession._id : null"
					[utilisateurs] = "utilisateurs"
					[onCreateRetrocession]="createNewRetrocession"
					[onSelectRetrocession]="displayRetrocessionDetails">
				</di-detail-retrocession>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!selectedRetrocession && !isMobileView && mode !== 'form'">
			<lua-message-aucune-donnee
				mainText="Aucune rétrocession sélectionnée"
				subText="Le détail de la rétrocession sélectionnée sera affichée ici."
			>
			</lua-message-aucune-donnee>
		</ng-container>
		<ng-container *ngIf="mode === 'form'">
			<di-form-retrocession [utilisateurs] = "utilisateurs" [retrocession]="mode === 'form' ? selectedRetrocession : null"></di-form-retrocession>
		</ng-container>
	</main>
</div>