import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-bouton-icone',
  templateUrl: './bouton-icone.component.html',
  styleUrls: ['./bouton-icone.component.css'],
})
export class BoutonIconeComponent implements OnInit {
  @Input()
  public iconName = '';

  @Input()
  public size = ESize.M;

  @Input()
  public color = 'CouleurPrimaire';

  @Input()
  public isIcon = false;

  @Input()
  public mode: 'filled' | 'outlined' = 'filled';

  @Input()
  public isDisabled = false;

  @Output()
  public onClick = new EventEmitter<MouseEvent>();

  public fontSize = '22px';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    switch (this.size) {
      case ESize.S:
        this.fontSize = '16.5px';
        break;
      case ESize.M:
        this.fontSize = '22px';
        break;
      case ESize.L:
        this.fontSize = '27.5px';
        break;
      default:
        this.fontSize = '22px';
        break;
    }
    this.el.nativeElement.style.setProperty('--my-bg-color', 'none');
    this.el.nativeElement.style.setProperty(
      '--my-bg-color-hover',
      `var(--${this.color}10)`
    );
    this.el.nativeElement.style.setProperty(
      '--my-bg-color-active',
      `var(--${this.color}20)`
    );
  }

  public getClasses() {
    return {
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
      disabled: this.isDisabled,
    };
  }

  public handleClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
