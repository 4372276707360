import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lua-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css'],
})
export class OverlayComponent implements OnInit {
  @Input()
  public forceAboveAll = false;

  @Output()
  public onClick = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event): void {
    this.onClick.emit(event);
  }
}
