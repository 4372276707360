<div class="di-selection-titulaire">
  <div class="selection-titulaire__content">
    <p>
      Vous allez facturer un remplaçant, il est nécessaire de sélectionner le titulaire télétransmis.
    </p>
    <div class="selection-titulaire__list">
      <ng-container *ngIf="infirmiers">
        <div class="selection-titulaire__infirmier" *ngFor="let infirmier of infirmiers; let i = index">
          <lua-objet-utilisateur 
            [ngClass]="{disabled: !infirmier.finess}"
            [firstName]="infirmier.firstName" 
            [lastName]="infirmier.lastName.toUpperCase()"
            [info]="!infirmier.finess ? 'N° Finess manquant' : ''"
            [avatarColor]="!infirmier.finess ? 'Gris' : infirmier.avatarColor">
          </lua-objet-utilisateur>
          <lua-radio-button 
            [disabled]="!infirmier.finess" 
            (click)="onSelectionItem(infirmier)">
          </lua-radio-button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="selection-titulaire__buttons">
    <lua-action-principale 
      libelle="Facturer" 
      [fullWidth]="true" 
      [isDisabled]="!infirmierSelected"
      (click)="facturer()">
    </lua-action-principale>
    <lua-bouton-texte 
      size="large" 
      [fullWidth]="true" 
      libelle="Annuler"
      (click)="cancel()">
    </lua-bouton-texte>
  </div>
</div>