import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css'],
})
export class BadgeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() number = 0;
}
