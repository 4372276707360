import { Injectable, ComponentRef, ViewContainerRef } from '@angular/core';
import { ImpressionTourneeComponent } from '../../tournees/components/impression-tournee/impression-tournee.component';
import { ImpressionRetrocessionComponent } from '../../../pages/retrocession/impression-retrocession/impression-retrocession.component';
import { Site } from '@osapp/modules/sites/models/site';
import { ETimetablePattern, IContact } from '@osapp/model';
import { DateObject } from '../../../models/retrocession/DateObject';
import { Retrocession } from 'apps/idl/src/modules/patients/model/Retrocession';
import { DateHelper, EXTENSIONS_AND_MIME_TYPES } from '@osapp/helpers';
import html2pdf from 'html2pdf.js';
import { PlatformService } from '@osapp/services';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { SnackbarService } from './snackbar.service';
import { Device } from '@capacitor/device';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LoaderService } from './loader.service';

@Injectable({ providedIn: 'root' })
export class ImpressionService {
    private impressionViewRef!: ComponentRef<ImpressionTourneeComponent | ImpressionRetrocessionComponent>;

    constructor(
        private svcPlatform: PlatformService,
        private readonly svcFilesystem: FilesystemService,
        private svcSnackbar: SnackbarService,
        private ioFileOpener: FileOpener,
        private svcLoader: LoaderService
    ) { }

    createTourneePrintView(
        viewContainerRef: ViewContainerRef,
        tourneeDate: string,
        nurseName: string,
        sortedSeancesEvents
    ): HTMLElement {
        this.impressionViewRef = viewContainerRef.createComponent(ImpressionTourneeComponent);
        const instance: ImpressionTourneeComponent = this.impressionViewRef.instance as ImpressionTourneeComponent;
        Object.assign(instance, { tourneeDate, nurseName, sortedSeancesEvents })
        return this.impressionViewRef.location.nativeElement;
    }

    createRetrocessionPrintView(
        viewContainerRef: ViewContainerRef,
        retrocession: Retrocession,
        cabinet: Site,
        remplacant: IContact,
        journees: { date: Date, dateObject: DateObject }[],
        nombreJournees: number,
        totalActesMajorations: number,
        totalIndemnites: number,
        totalRetrocession: number
    ): HTMLElement {
        this.impressionViewRef = viewContainerRef.createComponent(ImpressionRetrocessionComponent);
        const instance: ImpressionRetrocessionComponent = this.impressionViewRef.instance as ImpressionRetrocessionComponent;
        Object.assign(instance, { retrocession, cabinet, remplacant, journees, nombreJournees, totalActesMajorations, totalIndemnites, totalRetrocession });
        return this.impressionViewRef.location.nativeElement;
    }

    destroyPrintView(): void {
        if (this.impressionViewRef) {
            this.impressionViewRef.destroy();
            const element = this.impressionViewRef.location.nativeElement;
            if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }

    async printPdf(printElement: HTMLElement, fileName: string, directoryName: string, isMobileView: boolean): Promise<void> {
        try {
            this.svcLoader.showLoader("Création de l'impression en cours...");
            const currentDate: Date = new Date();
            const dateImpression: string = `Imprimé le ${DateHelper.transform(currentDate, ETimetablePattern.dd_MM_yyyy_slash)} à ${DateHelper.transform(currentDate, ETimetablePattern.HH_mm)}`;
            const options = {
                margin: [10, 10, 15, 10],
                filename: `${fileName}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1.5 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: ['css', 'legacy'] },
            };

            const pdf = await html2pdf()
                .set(options)
                .from(printElement)
                .toPdf()
                .get('pdf');

            const totalPages = pdf.internal.getNumberOfPages();
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const footerHeight = 10;

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);

                pdf.setDrawColor(0);
                pdf.setLineWidth(0.5);
                pdf.line(10, pageHeight - footerHeight - 5, pageWidth - 10, pageHeight - footerHeight - 5);

                pdf.setFontSize(12);
                pdf.setTextColor(159, 159, 159);
                pdf.text(dateImpression, 10, pageHeight - 5);

                pdf.setFontSize(12);
                pdf.setTextColor(0, 0, 0);
                pdf.text(`Page ${i}/${totalPages}`, pageWidth / 2, pageHeight - 5, { align: 'center' });
            }

            const lsPdfOutput: ArrayBuffer = pdf.output('arraybuffer');
            const loUint8Array = new Uint8Array(lsPdfOutput);

            if (isMobileView && this.svcPlatform.isMobileApp) {
                const info = await Device.getInfo();
                const hasPermission = await this.svcFilesystem.checkAndRequestFilePermission();
                if (!hasPermission) {
                    if (info.manufacturer?.toLowerCase() === 'xiaomi') {
                        this.svcSnackbar.showToast(
                            "warning",
                            "bottom center",
                            "Sur les appareils Xiaomi, veuillez vérifier les permissions de stockage dans les paramètres du téléphone.",
                            null, null, null, "5000"
                        );
                    } else {
                        this.svcSnackbar.showToast(
                            "error",
                            "bottom center",
                            "Permission refusée. Impossible d'écrire dans le stockage.",
                            null, null, null, "5000"
                        );
                    }
                    return;
                }

                const pathExport = `Download/${directoryName}/${fileName}.pdf`;

                try {
                    // Mobile: sauvegarder dans le répertoire spécifique
                    const psPdfPath = await this.svcFilesystem.createFileAsync(
                        pathExport,
                        loUint8Array,
                        Directory.ExternalStorage,
                        true
                    );

                    // Ouvrir le fichier PDF
                    await this.ioFileOpener.open(psPdfPath, EXTENSIONS_AND_MIME_TYPES.pdf.mimeType);

                    // Afficher une notification de succès
                    this.svcSnackbar.showToast(
                        "success",
                        "bottom center",
                        `Export du document réussi dans le dossier : ${pathExport}`,
                        null, null, null, "5000"
                    );
                } catch (error) {
                    console.error("Erreur lors de l'export du document :", error);
                    this.svcSnackbar.showToast(
                        "error",
                        "bottom center",
                        "Erreur lors de l'export du document. Veuillez réessayer.",
                        null, null, null, "5000"
                    );
                }

            } else {
                // Ouvrir le PDF dans un nouvel onglet
                pdf.output('dataurlnewwindow');
                // Navigateur: télécharger le fichier
                await pdf.save(options.filename);
            }
        } catch (error) {
            console.error("Erreur lors de l'impression :", error);
            this.svcSnackbar.showToast("error", "bottom center", "Une erreur s'est produite lors de l'impression.", null, null, null, "4000");
        } finally {
            // Masquer le loader même en cas d'erreur
            this.destroyPrintView();
            this.svcLoader.hideLoader();
        }
    }
}