<div *ngIf="isVisible" class="lua-snackbar" [ngClass]="position ? position.replace(' ', '-') : 'bottom-center'">
  <lua-alerte
    [filled]="true"
    [type]="type"
    [message]="message"
    [iconName]="iconName"
    [buttonText]="buttonText"
    [buttonIconName]="buttonIconName"
    [onClickAction]="onClickAction"
  ></lua-alerte>
</div>
