<div class="di-input-file">
	<div class="scan-container" (click)="onAddFile($event)">
		<div class="bouton_action">
			<lua-bouton-icone *ngIf="isMobile" (onClick)="takePicture($event)" class="ligne-contact__mail-icone"
				iconName="Photo_Camera" mode="outlined"></lua-bouton-icone>
			<lua-bouton-icone (onClick)="onAddFile($event)" class="ligne-contact__phone-icone" iconName="download"
				mode="outlined"></lua-bouton-icone>
		</div>
		<p class="scan-text">{{ title }}</p>
	</div>
	<input type="file" #fileInput (change)="onFileSelected($event)" [accept]="isPhoto ? 'capture=camera' : 'image/*'"
		[attr.capture]="isPhoto ? 'environment' : null" class="hidden-input" />
</div>