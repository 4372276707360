<div #inputTimeContainer class="lua-input-time"[ngClass]="{tailleL: size === 'large', disabled: disabled}">
  <mat-form-field class="lua-input" appearance="outline" [ngClass]="{
	  'full-width': fullWidth,
	  'input-medium': size === 'medium',
	  'input-large': size === 'large',
	  disabled: disabled
  }">
  <input class="lua-input" [(ngModel)]="value" type="time" matInput placeholder="hh:mm" (focus)="onFocus()" (blur)="onBlur()"
		(input)="!disabled && onInput($event)" [disabled]="disabled" autocapitalize="off" autocomplete="off" />
    <lua-bouton-icone mode="outlined" matSuffix iconName="schedule" (onClick)="!disabled && toggleTimePicker($event)"
		[isDisabled]="disabled">
	</lua-bouton-icone>
</mat-form-field>
  <div *ngIf="showTimePicker" class="timePicker">
    <div class="hours">
      <strong class="timePicker-header">Hours</strong>
      <div
        *ngFor="let hour of hours"
        (click)="selectHour(hour)"
        [ngClass]="{selected: hour === selectedHour}"
      >
        {{ hour }}
      </div>
    </div>
    <div class="minutes">
      <strong class="timePicker-header">Minutes</strong>
      <div
        *ngFor="let minute of minutes"
        (click)="selectMinute(minute)"
        [ngClass]="{selected: minute === selectedMinute}"
      >
        {{ minute }}
      </div>
    </div>
  </div>
</div>
