import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArrayHelper } from '@osapp/helpers';
import { UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { WorkspaceService } from '@osapp/services';
import { takeUntil, tap } from 'rxjs/operators';
import { ColorOption } from '../../../../../models/IColorOption';
import { PanneauService } from '../../../services/panneau.service';

@Component({
	selector: 'di-panneau-personalisation-workspace-contact',
	templateUrl: './panneau-prersonalisation-workspace.component.html',
	styleUrls: ['./panneau-prersonalisation-workspace.component.scss'],
})
export class PanneauPersonalisationWorkspaceComponent extends DestroyableComponentBase {

	@Input() onUpdate: () => void;
	

	constructor(
		private fb: FormBuilder,
		private svcPanneau : PanneauService,
		private svcWorkspace : WorkspaceService
	) { 
		super();
	}

	public colors = [
		{
			name: "Indigo",
			value: "Indigo",
		},
		{
			name: "Raspberry",
			value: "Raspberry",
		},
		{
			name: "OrangeClair",
			value: "OrangeClair",
		},
		{
			name: "Warning",
			value: "Warning",
		},
		{ name: "OK", value: "OK" },
		{ name: "BleuVif", value: "BleuVif" },
	];
	public selectedColor : ColorOption [];
	public wsForm: FormGroup;
	
	ngOnInit(): void {
		this.wsForm = this.fb.group({
			wsName: [UserData.current.workspaceInfos[0].name],
		});
		this.selectedColor = UserData.current.workspaceInfos[0].color ? [{name:UserData.current.workspaceInfos[0].color,value:UserData.current.workspaceInfos[0].color}] : [{name:"CouleurPrimaire",value:"CouleurPrimaire"}]
	}
	
	onGoBackClick()
	{
		this.svcPanneau.close()
	}

	onOptionClick = (colColorOptior: any[]) => {
		if(colColorOptior.length == 0) 
			this.selectedColor= [];
		else
			this.selectedColor = colColorOptior;
	}

	saveWSEdit()
	{
		this.svcWorkspace.saveWorkspaceANAKIN(this.wsForm.get("wsName").value,this.selectedColor[0].value).pipe(
			takeUntil(this.destroyed$),
			tap(() => 
				{
					UserData.current.workspaceInfos[0].color = this.selectedColor[0].value;
					UserData.current.workspaceInfos[0].name = this.wsForm.get("wsName").value;
					if(this.onUpdate)
						this.onUpdate()
					this.svcPanneau.close();
				})
		).subscribe()
	}

	allValidForm() : boolean {
		return this.wsForm.invalid || !ArrayHelper.hasElements(this.selectedColor);
	}
}
