
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { DeviceService } from '../../../shared/services/device.service';
import { ISite } from '@osapp/modules/sites/models/isite';
import { SitesService } from '../../../../../../../../libs/osapp/src/modules/sites/services/sites.service';
import { UserData } from '@osapp/model';
import { catchError, tap } from 'rxjs/operators';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { of } from 'rxjs';
import { LoaderService } from '../../../shared/services/loader.service';
import { ContactsService } from '@osapp/services';

@Component({
  selector: 'di-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.scss'],
})
export class CabinetComponent extends DestroyableComponentBase implements OnInit {
  public texteSecondaireIsDROM: string = "Utiliser les montants DOM TOM pour la facturation";
  public phoneNumber: string = '';
  public isMobileView: boolean = false;
  public cabinetForm: FormGroup;
  public cabinet: ISite;
  @Input() onCancel: () => {};

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private svcSite: SitesService,
    private svcDevice: DeviceService,
    private svcSnackbar: SnackbarService,
    private svcLoader: LoaderService,
    private svcContact: ContactsService,
  ) {
    super();
  }

  ngOnInit() {
    this.svcDevice.isMobile$.subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });

    this.initCabinet();
    this.initFormCabinet();
  }

  initFormCabinet() {
    this.cabinetForm = this.fb.group({
      name: [this.cabinet.name ?? "", Validators.required],
      phone: [this.cabinet.phone ? this.formatPhoneNumber(this.cabinet.phone) : ""],
      email: [this.cabinet.email ?? ""],
      street: [this.cabinet.street ?? ""],
      zipCode: [this.cabinet.zipCode ?? ""],
      city: [this.cabinet.city ?? ""],
    });

    this.controlValidationForm();
  }

  initCabinet() {
    if (!this.cabinet) {
      this.initCabinetDefault();
      const currentSiteId = UserData.currentSite._id;
      this.getCabinet(currentSiteId).then(() => {
        this.initFormCabinet();
      });
    }
  }

  initCabinetDefault() {
    this.cabinet = {
      _id: '',
      name: '',
      isDROM: false
    };
  }

  toggleIsDrom(isDROM: boolean) {
    this.cabinet.isDROM = isDROM;
  }

  handleClickSaveBtn() {
    this.saveCabinet();
  }

  handleClickCancelBtn() {
    if (this.onCancel) {
      this.onCancel();
    }
  }


  public onPhoneInputFormat(event: Event) {
    let input = (event.target as HTMLInputElement).value;
    this.phoneNumber = this.formatPhoneNumber(input);
    this.cabinetForm.get('phone')?.setValue(this.phoneNumber, { emitEvent: false });
  }

  private formatPhoneNumber(input: string): string {
    input = input.replace(/\D/g, '');
    return input.match(/.{1,2}/g)?.join(' ') || '';
  }

  private removeSpacesFromPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/\s+/g, '');
  }

  private async getCabinet(idCabinet) {
    this.cabinet = await this.svcSite.getSite(idCabinet).toPromise();
  }

  private controlValidationForm(): void {
    Object.keys(this.cabinetForm.controls).forEach(field => {
      const control = this.cabinetForm.get(field);
      control?.updateValueAndValidity();
    });
  }

  private saveCabinet() {
    this.cabinet = { ...this.cabinet, ...this.cabinetForm.value };
    this.svcLoader.showLoader();

    this.svcSite.saveSite(this.cabinet)
      .pipe(
        catchError(poError => {
          console.error(`SITE-D.C:: Erreur mise à jour du site '${this.cabinet._id}' : `, poError);
          this.svcSnackbar.showToast("error", "bottom center", `La mise à jour du cabinet ${this.cabinet.name} a échouée. Veuillez réessayez ultérieurement.`, null, null, null, "4000");
          return of(null);
        }),
        tap(() => {
          //On force la mise à jour de la zone géo
          this.svcContact.initZoneGeo("")
          this.svcSnackbar.showToast("success", "bottom center", "Les paramètres du cabinet ont été mis à jour", "", "", "", "6000");
          this.svcLoader.hideLoader();
        })
      )
      .subscribe();
  }
}
