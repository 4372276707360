<div class="panneau-detail-transmission">
	<div *ngIf="currentStep === 1">
		<div class="panneau-detail-transmission__container">
			<div class="container__content">
				<di-recherche-patients [mobileViewForce]="true" [displayCount]="true"
					(onClickLigne)="onClickPatient($event)"></di-recherche-patients>
			</div>
		</div>
	</div>
	<div *ngIf="currentStep === 2">
		<div class="panneau-detail-transmission__container">
			<div class="container__content">
				<form [formGroup]="transmissionForm" (ngSubmit)="handleSubmit($event)">
					<div class="content__formulaire">
						<div class="formulaire__input">
							<lua-input [fullWidth]="true" placeholder="Quel est le titre ?" label="Titre (optionnel)"
								formControlName="titre"></lua-input>
						</div>
						<div class="formulaire__input">
							<lua-input [fullWidth]="true" label="Transmission, donnée, intervention, résultat"
								formControlName="description" multiline="true"></lua-input>
						</div>
						<ng-container *ngFor="let image of this.listDoc">
							<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteOldFile($event)"></di-carte-photo>
						</ng-container>
						<ng-container *ngFor="let image of this.listNewFile">
							<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteNewFile($event)"></di-carte-photo>
						</ng-container>
						<div class="formulaire__lua-bouton-texte">
							<di-input-file (inputClick)="handleFileSelected($event)"></di-input-file>
						</div>
						<lua-alerte *ngIf="isError" [filled]="true" [fullWidth]="true" type="error" iconName="info"
							[message]="errorMessage"></lua-alerte>

						<div class="formulaire__lua-action-principale">
							<lua-action-principale [fullWidth]="true" libelle="Enregistrer"
								(click)="handleSubmit($event)"></lua-action-principale>
						</div>
						<div class="formulaire__lua-bouton-texte">
							<lua-bouton-texte [fullWidth]="true" libelle="Annuler"
								(click)="!transmission ? stepPrecedent() : close() "></lua-bouton-texte>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>