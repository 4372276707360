import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  MatDatepicker,
  MatDateRangePicker,
  MatDateRangeInput,
} from '@angular/material/datepicker';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'lua-filter-date-picker',
  templateUrl: './filter-date-picker.component.html',
  styleUrls: ['./filter-date-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDatePickerComponent),
      multi: true,
    },
  ],
})
export class FilterDatePickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('singlePicker') singlePicker!: MatDatepicker<Date>;
  @ViewChild('rangePicker') rangePicker!: MatDateRangePicker<Date>;
  @ViewChild('rangeInput') rangeInput!: MatDateRangeInput<Date>;

  @Input() label = '';
  @Input() isRange = false;
  @Input() reset = false;
  @Input() fullWidth = true;
  @Input() max?: Date;
  @Input() min?: Date;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  dateSelected = false;
  displayDate = '';

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reset'] && changes['reset'].currentValue === true) {
      this.resetDatePicker();
    }
  }

  openPicker() {
    if (this.isRange) {
      if (this.rangePicker) {
        this.rangePicker.open();
      }
    } else {
      if (this.singlePicker) {
        this.singlePicker.open();
      }
    }
  }

  onDateChange(event: any) {
    let value: any;
    if (this.isRange) {
      const start = this.rangeInput.value?.start;
      const end = this.rangeInput.value?.end;

      if (start && end) {
        this.displayDate = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
        this.dateSelected = true;
        value = {start, end};
      } else {
        this.displayDate = this.label;
        this.dateSelected = false;
        value = null;
      }
    } else {
      const date = event.value;
      this.displayDate = date ? date.toLocaleDateString() : this.label;
      this.dateSelected = !!date;
      value = date;
    }
    this.valueChange.emit(value);
    // Update the model
    this.onChange(value);
    this.onTouched();
  }

  resetDatePicker(): void {
    this.displayDate = '';
    this.dateSelected = false;
    this.reset = false;

    // Reset the form control value
    this.onChange(null);
    this.onTouched();
  }

  public getClasses() {
    return {
      'full-width': this.fullWidth,
      active: this.dateSelected,
    };
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if (value) {
      if (this.isRange && value.start && value.end) {
        this.displayDate = `${value.start.toLocaleDateString()} - ${value.end.toLocaleDateString()}`;
        this.dateSelected = true;
      } else {
        this.displayDate = value.toLocaleDateString();
        this.dateSelected = true;
      }
    } else {
      this.resetDatePicker();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.isRange) {
      this.rangeInput.disabled = isDisabled;
    } else {
      this.singlePicker.disabled = isDisabled;
    }
  }
}
