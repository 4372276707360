import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper, DateHelper } from '@osapp/helpers';
import { IContact } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { NB_ITEM_TO_DISPLAY } from 'apps/idl/src/anakin/anakin.constants';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { ISeance } from 'apps/idl/src/model/ISeance';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { of } from 'rxjs';
import { debounceTime, merge, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { LoaderService } from '../../../shared/services/loader.service';
import { SeanceService } from '../../../shared/services/seance.service';

@Component({
  selector: 'di-liste-seance-ordonnance',
  templateUrl: './liste-seance-ordonnance.component.html',
  styleUrls: ['./liste-seance-ordonnance.component.scss'],
})
export class ListeSeanceOrdonnanceComponent extends DestroyableComponentBase implements OnInit {

  @Input() public ordonnance?: Traitement;
  @Input() public patient?: IPatient;
  @Input() public mode?: string;

  private groupIndex: number = 0;
  public noMoreSeances: boolean = false

  public seances: StoredSeance[] = [];
  public groupedSeances: { date: string, seances: ISeance[] }[] = [];
  public visibleGroupedSeances: { date: string, seances: ISeance[] }[] = [];

  public loadingSeances: boolean = false;

  public targetSeanceStartDate: Date;
  public targetSeanceId: string = "";

  constructor(
    private router: Router,
    private svcSeance: SeanceService,
    private svcLoader: LoaderService,
    private svcContact: ContactsService
  ) {
    super()
  }
	

  ngOnInit() {
		this.svcSeance.refreshSeanceList$.pipe(
      takeUntil(this.destroyed$),
      // Permet de gérer le cas où plusieurs séances sont réalisées d'un coup, pour exécuter loadListSeances une seule fois
      debounceTime(200)
    ).subscribe(() => {
      this.loadListSeances();
    });
    this.loadListSeances();
  }

	private loadListSeances()
	{
    this.svcLoader.showLoader("Récupération des séances...");
    this.loadingSeances = true;
		this.initValueComposant();
		this.svcSeance.selectSeancesByTraitement(this.ordonnance._id).pipe(
			tap((seances: StoredSeance[]) => {
        this.seances = seances;
      }),
      switchMap((seances: StoredSeance[]) => {
        let infirmiersIds = seances.map(x => x.infirmierId);
        return this.svcContact.getContactsByIds(infirmiersIds);
      }),
      tap((contacts : IContact[]) => {
        this.seances.forEach((seance: StoredSeance) => {
          seance.infirmier = contacts.find((contact: IContact) => contact._id === seance.infirmierId);
        });
      }),
      tap((_) => {
        this.manageSeances();
        this.svcLoader.hideLoader();
        this.loadingSeances = false;
      })
		).subscribe()
	}

	private initValueComposant() {
		this.seances = [];
		this.groupedSeances = [];
		this.visibleGroupedSeances = [];
		this.groupIndex = 0;
	}

  private manageSeances() {
    if (!ArrayHelper.hasElements(this.seances)) return;

    this.groupSeancesByDate();
    this.loadMoreSeances();
  }

  private groupSeancesByDate(): void {
    const seancesByDate: { [key: string]: ISeance[] } = {};
    const today = new Date();
  

    this.seances.forEach(seance => {
      const dateKey = this.getFormattedDate(new Date(seance.startDate), today);
      if (!seancesByDate[dateKey]) {
        seancesByDate[dateKey] = [];
      }
      seancesByDate[dateKey].push(seance);
    });


    this.groupedSeances = Object.keys(seancesByDate).map(date => ({
      date: date,
      seances: seancesByDate[date]
    }));
  }

  public getFormattedDate(date: Date, today: Date): string {
   
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

    return isToday
        ? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
        : date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}

  public loadMoreSeances(): void {
    // Permet de recevoir la startDate de la séance qui vient d'être mis à jour / supprimée
    this.svcSeance.seanceUpdated$.pipe(
      merge(of(null)),
      take(1) 
    ).subscribe((seanceStartDate: Date) => {
      this.loadNextSeancesGroup();
      if (seanceStartDate) {
        this.targetSeanceStartDate = new Date(seanceStartDate);
        this.svcSeance.clearSeanceUpdated();
        if (this.visibleGroupedSeances?.length === 0) return;
        let previousVisibleGroupedSeancesLength: number = 0; 
        const isSeanceInVisibleGroups = (): boolean => {
          if (this.visibleGroupedSeances.length === previousVisibleGroupedSeancesLength) return true;
          previousVisibleGroupedSeancesLength = this.visibleGroupedSeances.length; 
          return this.visibleGroupedSeances.some(group => {
            let previousSeanceDate: Date | null = null;
            return group.seances.some((seance: StoredSeance) => {
              this.targetSeanceId = seance._id;
              let startDate: Date = new Date(seance.startDate);
              if (previousSeanceDate === null) {
                previousSeanceDate = startDate; 
                return new Date(startDate) >= this.targetSeanceStartDate;
              }
              const isInInterval = this.targetSeanceStartDate >= previousSeanceDate &&
                this.targetSeanceStartDate <= startDate;
              previousSeanceDate = startDate;
              return isInInterval;
            });
          });
        }
        let foundSeance = isSeanceInVisibleGroups();
        while (!foundSeance) {
          this.loadNextSeancesGroup();
          foundSeance = isSeanceInVisibleGroups();
        }
      }else{
        this.targetSeanceStartDate = null;
        this.targetSeanceId = null;
      }
    });
  }

  private loadNextSeancesGroup(){
    const nextIndex = this.groupIndex + NB_ITEM_TO_DISPLAY;
    const nextGroups = this.groupedSeances.slice(this.groupIndex, nextIndex);

    this.visibleGroupedSeances = [...this.visibleGroupedSeances, ...nextGroups];
    this.groupIndex = nextIndex;
    const newNextIndex = this.groupIndex + NB_ITEM_TO_DISPLAY;

    const newNextGroups = this.groupedSeances.slice(this.groupIndex, newNextIndex);
    this.noMoreSeances = false;
    if (newNextGroups.length === 0) {
      this.noMoreSeances = true;
    }
  }

  public goBack() {
    this.router.navigate(['/ordonnances']);
  }
}