<div 
class="lua-alerte container" 
[ngClass]="{
	filled: filled,
	'info-filled': filled && type === 'info',
	info: type === 'info' && !filled,
	'error-filled': filled && type === 'error',
	error: type === 'error' && !filled,
	'warning-filled': filled && type === 'warning',
	warning: type === 'warning' && !filled,
	'success-filled': filled && type === 'success',
	success: type === 'success' && !filled,
	'full-width': fullWidth
  }">
	<div class="sub-container">
		<div class="icon-container">
			<lua-icone [mode]="filled ? 'outlined' : 'filled'" [iconName]="iconName || iconMap[type]" [color]="buttonColor"
				fontSize="22px"></lua-icone>
		</div>
		<div class="message" [innerHTML]="message"></div>
	</div>
	<div class="sub-container">
		<lua-action-secondaire *ngIf="buttonText" [libelle]="buttonText" [color]="buttonColor" [size]="buttonSize"
			(click)="handleClick()"></lua-action-secondaire>
		<lua-bouton-icone *ngIf="!buttonText && buttonIconName" [iconName]="buttonIconName" [color]="buttonColor"
			[size]="buttonIconSize" [mode]="filled ? 'outlined' : 'filled'" (onClick)="handleClick()"></lua-bouton-icone>
	</div>
</div>