import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lua-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css'],
})
export class RechercheComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}

  @Input() secondaryAction = false;
  @Input() iconeDefault = true;
  @Input() searchText = '';
  @Input() placeHolderText = 'Rechercher';
  @Input() fullWidth = false;
  @Output() onSecondaryActionClick = new EventEmitter<MouseEvent>();
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();

  onSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchText = inputElement.value;
    this.onValueChange.emit(this.searchText);
  }
  clearSearch() {
    this.searchText = '';
    this.onValueChange.emit(this.searchText);
  }

  handleSecondaryActionClick(event: MouseEvent) {
    this.onSecondaryActionClick.emit(event);
  }
  getClasses(): {[key: string]: boolean} {
    return {
      'has-text': this.searchText.length > 0,
      'full-width': this.fullWidth,
    };
  }
}
