import { Component, OnInit } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers';
import { EPrefix, IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { IRetrocession } from 'apps/idl/src/modules/patients/model/IRetrocession';
import { Observable } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { DeviceService } from '../../features/shared/services/device.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { RetrocessionService } from '../../features/shared/services/retrocession.service';

@Component({
	selector: 'di-retrocession',
	templateUrl: './retrocession.page.html',
	styleUrls: ['./retrocession.page.scss'],
})
export class RetrocessionPage extends DestroyableComponentBase implements OnInit {

	public isMobileView: boolean = false;
	public retrocessions: IRetrocession[] = [];

	public utilisateurs: IContact[];
	public mode: "form" | "list" | "details" = "details";
	public selectedRetrocession: IRetrocession;

	constructor(
		private svcDevice: DeviceService,
		private svcRetrocession: RetrocessionService,
		private svcLoader: LoaderService,
		private svcContacts: ContactsService
	) {
		super();
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});
		this.svcRetrocession.refreshRetrocessionList$.pipe(takeUntil(this.destroyed$)).subscribe((idRetroToSelect: string) => {
			this.getRetrocessions(idRetroToSelect);
		})
		this.getRetrocessions();
	}


	private getWorkspaceUsers(): Observable<IContact[]> {
		return this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			map((utilisateurs: IContact[]) => {
				return utilisateurs.filter(utilisateur => utilisateur.userId)
			}),
			tap((utilisateurs: IContact[]) => {
				this.utilisateurs = utilisateurs;
			})
		);
	}

	private selectRetrocessionByDefault(idRetroToSelect?: string): void {
		if (!idRetroToSelect) {
			//Si on a pas demandé à afficher une retro particulière
			//On sélectionne la dernière rétrocession existante de l'utilisateur courant
			const sortedRetrocessions = this.retrocessions.sort((a, b) => {
				const dateA = new Date(a.dateDebut).getTime();
				const dateB = new Date(b.dateDebut).getTime();
				return dateA - dateB;
			});
			const currentUserId = ContactsService.getContactIdFromUserId(UserData.current._id);
			this.selectedRetrocession = sortedRetrocessions.find((retrocession: IRetrocession) => retrocession.infirmierId === currentUserId);
		} else {
			//Sinon on sélectionne la rétrocession qui a été demandée
			this.selectedRetrocession = this.retrocessions.find(x => x._id === idRetroToSelect);
		}
	}

	public getRetrocessions(idRetroToSelect?: string): void {
		this.selectedRetrocession = null;
		this.svcLoader.showLoader("Chargement des rétrocessions...", "Blanc");
		this.getWorkspaceUsers().pipe(
			mergeMap(() => this.svcRetrocession.getAll()),
			map((retocessionsList: IRetrocession[]) => {
				retocessionsList.map((retrocession: IRetrocession) => {
					retrocession.infirmier = this.utilisateurs.find((utilisateur: IContact) => utilisateur._id === retrocession.infirmierId);
				})
				return retocessionsList;
			}),
			tap((retrocessions: IRetrocession[]) => {
				this.retrocessions = retrocessions;
				this.svcLoader.hideLoader();
				if (ArrayHelper.hasElements(this.retrocessions)) {
					this.selectRetrocessionByDefault(idRetroToSelect);
					this.mode = this.selectedRetrocession ? 'details' : 'list';
				} else if (this.isMobileView) {
					//En mobile il faut afficher la liste uniquement quand on a pas de rétrocession
					this.mode = 'list';
				}
			}),
			takeUntil(this.destroyed$)
		).subscribe()
	}

	public displayRetrocessionDetails = (retrocession: IRetrocession) => {
		this.selectedRetrocession = retrocession;
		this.mode = 'details';
	}

	public createNewRetrocession = (idRetroToEdit?: string) => {
		if (idRetroToEdit) {
			//Si on a une retrocession à modifier
			this.selectRetrocessionByDefault(idRetroToEdit);
		} else {
			//On reset la selection de la rétrocession car on veut en créer une nouvelle 
			//et non pas modifier celle qui été sélectionnée avant qu'on clique sur nouveau
			this.selectedRetrocession = null;
		}
		this.mode = 'form';
	}

}

