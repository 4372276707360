<div class="form-cabinet-container">
	<form [formGroup]="cabinetForm" (ngSubmit)="handleClickSaveBtn()" class="form-cabinet-form">
		<div class="form-cabinet-header">
				<h1>Cabinet</h1>
		</div>
		<div class="form-cabinet-body">
			<div class="gap-20 form-cabinet-infos-obligatoire">
				<lua-input 
          formControlName="name" 
          label="Nom (obligatoire)"
					placeholder="Quel est le nom du cabinet (obligatoire) ?" 
          [fullWidth]="true">
        </lua-input>				
			</div>
			<div class="gap-20 form-cabinet-infos-adresse">
        <div>
          <h2>Adresse</h2>
          <small>Ces informations sont affichées sur les factures</small>
        </div>
        <div class="infos-adresse">
          <div class="icone-adresse">
            <lua-icone					
              iconName="location_on" 
              mode="outlined"
              color="Noir">
            </lua-icone>
          </div>
          <div class="adresse">
            <lua-input 
              formControlName="street" 
              label="Adresse" 
              placeholder="Quel est l'adresse du cabinet ?"
              [fullWidth]="true">
            </lua-input>
            <lua-input 
              formControlName="zipCode" 
              type="text" 
              label="Code postal"
              placeholder="Quel est le code postal du cabinet ?" 
              [fullWidth]="true">
            </lua-input>
            <lua-input 
              formControlName="city" 
              label="Ville" 
              placeholder="Quel est la ville du cabinet ?"
              [fullWidth]="true">
            </lua-input>
          </div>
        </div>
			</div>
      <div class="gap-20 form-cabinet-infos-telmail">
        <h2>Contacter le cabinet</h2>
        <div>        
          <lua-icone					
            iconName="phone" 
            mode="outlined"
            color="Noir">
					</lua-icone>
          <lua-input 
            formControlName="phone" 
            label="Numéro de téléphone" 
            (input)="onPhoneInputFormat($event)"
            placeholder="Quel est le téléphone du cabinet ?" 
            [fullWidth]="true">
          </lua-input>
        </div>
        <div>
          <lua-icone					
            iconName="mail" 
            mode="outlined"
            color="Noir">
          </lua-icone>
          <lua-input 
            formControlName="email" 
            label="Adresse email" 
            placeholder="Quel est l'email du cabinet ?"
            [fullWidth]="true">
          </lua-input>
        </div>
      </div>
			<div class="gap-20 form-cabinet-infos-facturation">
				<h2>Facturation</h2>
				<lua-switch 
          (clickSwitch)="toggleIsDrom($event)" 
          label="Facturation DOM TOM" 
          [isChecked]="cabinet.isDROM"
					[texteSecondaire]="texteSecondaireIsDROM">
        </lua-switch>
			</div>
		</div>
		<div class="gap-20 form-cabinet-footer">
			<lua-action-principale 
        libelle="Enregistrer" 
        [isDisabled]="cabinetForm.invalid" 
        (click)="handleClickSaveBtn()"
				[fullWidth]="true">
      </lua-action-principale>
			<lua-bouton-texte 
        libelle="Annuler" 
        [fullWidth]="true" 
        (click)="handleClickCancelBtn()">
      </lua-bouton-texte>
		</div>
	</form>
</div>