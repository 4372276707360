import { Component, Input, OnInit } from "@angular/core";
import { Evenement } from "apps/idl/src/anakin/models/Evenement";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { AffectationSoinsComponent } from "../../../shared/components/affectation-soins/affectation-soins.component";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { EvenementService } from "../../../shared/services/evenement.service";
import { SeanceService } from "../../../shared/services/seance.service";
import { SnackbarService } from "../../../shared/services/snackbar.service";
import { EvenementFormComponent } from "../evenement-form/evenement-form.component";

@Component({
	selector: "di-menu-groupe-journee",
	templateUrl: "./menu-groupe-journee.component.html",
	styleUrls: ["./menu-groupe-journee.component.scss"],
})
export class MenuGroupeJourneeComponent implements OnInit {
	@Input() seances: StoredSeance[];
	@Input() momentLabel: string;
	@Input() date?:Date;


	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcSeance: SeanceService,
		private svcSnackbar: SnackbarService,
		private svcEvenement: EvenementService
	) {}

	private refreshSeanceSubject = new Subject<void>();
	refreshSeanceList$ = this.refreshSeanceSubject.asObservable();

	triggerRefreshSeanceList() {
		this.refreshSeanceSubject.next();
	}

	ngOnInit() {}
	onChangerInfirmierClick(event: Event) {
		const element: HTMLElement = event.currentTarget as HTMLElement;
		const anchorRect: DOMRect = element.getBoundingClientRect();
		this.svcDrawerPopover.open(
			"Affectation soins",
			"60%",
			null,
			AffectationSoinsComponent,
			{
				confirm: (selectedInfirmierId) => {
					this.confirmModifInfirmier(selectedInfirmierId);
				},
				cancel: () => this.closeMenu(),
			},
			null,
			anchorRect
		);
	}
	confirmModifInfirmier(selectedInfirmierId: string) {
		this.seances.map((seance) => {
			seance.infirmierId = selectedInfirmierId;
			this.updateSeance(seance);
		});
		this.closeMenu();
		this.svcSnackbar.showToast("success", "bottom center", "Séances modifiées","","","","6000");
	}
	private updateSeance(seance: StoredSeance) {
		this.svcSeance.updateSeance(seance).subscribe();
	}
	public closeMenu(): void {
		this.svcDrawerPopover.close();
	}

	onAjouterEvenementClick(event: Event) {
		const element: HTMLElement = event.currentTarget as HTMLElement;
		const anchorRect: DOMRect = element.getBoundingClientRect();
		this.svcDrawerPopover.open(
			"Évènement",
			"80%",
			event.target,
			EvenementFormComponent,
			{
				momentLabel: this.momentLabel,
				date : this.date,
				onSave: (eventToSave: Evenement) => {
					this.saveEvent(eventToSave)
				},
				onCancel: () => this.closeMenu(),
			},
			null,
			anchorRect
		);
	}

	
	saveEvent = (eventToSave: Evenement): void => {
	
		this.svcEvenement.createOrUpdate(eventToSave).pipe(
			
			finalize(() => {
				this.closeMenu()
			})
		).subscribe();
		
		
	  }
}
