import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'lua-panneau-desktop',
  templateUrl: './panneau-desktop.component.html',
  styleUrls: ['./panneau-desktop.component.css'],
})
export class PanneauDesktopComponent implements OnInit {
  @ViewChild('contentContainer', {read: ViewContainerRef, static: true})
  contentContainer!: ViewContainerRef;

  constructor() {}

  @Input() isMobile = false;
  @Input() title? = '';
  @Input() open = false;
  @Input() secondIconName = '';
  @Input() thirdIconName = '';
  @Output() firstClick = new EventEmitter<Event>();
  @Output() secondClick = new EventEmitter<Event>();
  @Output() panelClosed = new EventEmitter<void>();

  ngOnInit(): void {
    this.checkMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkMobile();
  }

  checkMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  get closeIconName(): string {
    return this.isMobile ? 'arrow_back' : 'Close';
  }
  openPanel() {
    this.open = true;
  }

  closePanel() {
    this.title = '';
    if (this.contentContainer) {
      this.contentContainer.clear();
    }
    this.open = false;
    this.panelClosed.emit();
  }

  handleClickFirst(event: Event): void {
    this.firstClick.emit(event);
  }
  handleClickSecond(event: Event): void {
    this.secondClick.emit(event);
  }

  setContent(content: Type<any>, inputs: any) {
    if (this.contentContainer) {
      this.contentContainer.clear();
      const componentRef = this.contentContainer.createComponent(content);
      Object.assign(componentRef.instance, inputs);
    }
  }
}
