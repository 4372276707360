<div class="di-carte-photo">
	<div class="image-container" *ngIf="!isFile">
		<!-- En mode edit, on a un container parent pour positionner l'icon delete -->
		<div *ngIf="edit" class="edit-image-container">
			<!-- Indicateur de chargement pendant le chargement de l'image -->
			<div *ngIf="isImageLoading" class="loading-spinner">
				<lua-loader-circulaire color="Indigo"></lua-loader-circulaire>
			</div>

			<!-- L'image, elle devient visible une fois qu'elle est chargée -->
			<img *ngIf="isImageAvailable" [src]="fileUrl" alt="Photo" (load)="onImageLoad()" [hidden]="isImageLoading" (click)="openOverlay()" />

			<div class="delete">
				<lua-bouton-icone iconName="Delete" size="medium" color="Blanc" [isIcon]="false" mode="filled"
					[isDisabled]="false" (click)="deletePhoto()"></lua-bouton-icone>
			</div>
		</div>
		<ng-container *ngIf="!edit">
			<!-- Indicateur de chargement pendant le chargement de l'image -->
			<div *ngIf="isImageLoading" class="loading-spinner">
				<lua-loader-circulaire color="Indigo"></lua-loader-circulaire>
			</div>

			<!-- L'image, elle devient visible une fois qu'elle est chargée -->
			<img *ngIf="isImageAvailable" [src]="fileUrl" alt="Photo" (load)="onImageLoad()" [hidden]="isImageLoading" (click)="openOverlay()" />
		</ng-container>
	</div>

	<!-- Overlay d'image agrandie -->
	<lua-overlay *ngIf="isOverlayOpen" [forceAboveAll]="true">
		<div class="overlay" (click)="closeOverlay()">
			<img [src]="fileUrl" class="overlay-image" />
			<div class="close-button">
				<lua-bouton-icone iconName="Close" size="medium" color="Blanc" [isIcon]="false" mode="filled"
					[isDisabled]="false" (click)="closeOverlay()"></lua-bouton-icone>
			</div>
		</div>
	</lua-overlay>

	<!-- Indicateur de fichier non image -->
	<div class="file-container" *ngIf="isFile">
		<div class="file-display">
			<a class="file-download" [href]="fileUrl" [download]="fileName">
				<lua-icone iconName="Draft" fontSize="25px" color="CouleurPrimaire" mode="outlined"></lua-icone>
				<p>{{ fileName }}</p>
			</a>
			<div class="endIcon">
				<lua-bouton-icone *ngIf="edit" iconName="Delete" size="medium" color="CouleurPrimaire" [isIcon]="false"
					mode="outlined" [isDisabled]="false" (click)="deletePhoto()"></lua-bouton-icone>
			</div>
		</div>

		<!-- Overlay pour fichier autre qu'image -->
		<div class="overlay" *ngIf="isOverlayOpen" (click)="closeOverlay()">
			<img [src]="fileUrl" class="overlay-image" />
			<div class="close-button">
				<lua-bouton-icone iconName="Close" size="medium" color="Blanc" [isIcon]="false" mode="filled"
					[isDisabled]="false" (click)="closeOverlay()"></lua-bouton-icone>
			</div>
		</div>
	</div>

	<!-- Affichage si l'image n'est pas disponible -->
	<div class="offline-container" *ngIf="!isFile && !isImageAvailable">
		<lua-icone *ngIf="!isFile && !isImageAvailable" iconName="Cloud_off" size="large" color="Noir80" [isIcon]="false"
			mode="outlined" [isDisabled]="true"></lua-icone>
	</div>
</div>