<div class="lua-option-data-picker" [ngClass]="{
    selected: isSelected,
    'full-width': fullWidth,
    'with-icon-padding': iconName || customIconName,
    disabled: disabled
  }" (click)="handleClick(value)">
	<lua-icone *ngIf="iconName || customIconName" [iconName]="iconName" [isIcon]="!!customIconName"
		[customIconName]="customIconName" [color]="isSelected ? 'CouleurPrimaire' : 'Noir80'" width="24px" height="24px"
		fontSize="24px"></lua-icone>
	<p class="label">{{ label }}</p>
</div>