import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'lua-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
})
export class SwitchComponent implements OnInit {
  @Input() isChecked = false;
  @Input() label = '';
  @Input() texteSecondaire = '';
  @Input() disabled = false;
  @Output() clickSwitch = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  onToggleChange(): void {
    if (!this.disabled) {
      this.isChecked = !this.isChecked;
      this.clickSwitch.emit(this.isChecked);
    }
  }

  getClasses(): {[key: string]: boolean} {
    return {
      label: !this.isChecked,
      'label-checked': this.isChecked,
    };
  }
}
