import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobUrlManager } from '@osapp/components/image/model/BlobUrlManager';
import { FileHelper } from '@osapp/helpers';
import { IGalleryFile } from '@osapp/model';
import { IDmsData } from '@osapp/modules/dms/model/IDmsData';
import { DmsService } from '@osapp/modules/dms/services/dms.service';
import { GalleryService } from '@osapp/services';
import { Subject, asyncScheduler, of, } from 'rxjs';
import { catchError, observeOn, takeUntil, tap } from 'rxjs/operators';


@Component({
	selector: 'di-carte-photo',
	templateUrl: './carte-photo.component.html',
	styleUrls: ['./carte-photo.component.scss'],
})
export class CartePhotoComponent implements OnInit, AfterViewInit, OnDestroy {

	isLargeScreen: boolean = false;
	isImageLoading: boolean = true;
	private readonly moBlobUrlManager = new BlobUrlManager();

	@Input() photoFile: IGalleryFile | File;
	@Input() edit: boolean = false;
	@Output() delete = new EventEmitter<File | IGalleryFile>();
	isFile: boolean = false;
	fileData: IDmsData;
	fileName: string;

	private destroy$ = new Subject<void>();


	fileUrl: string | ArrayBuffer = '';
	isOverlayOpen: boolean = false;
	isImageAvailable: boolean = true;


	constructor(
		private svcDms: DmsService,
		private readonly sanitizer: DomSanitizer,
		private readonly svcGallery: GalleryService) { }



	ngOnInit(): void {
		// this.isImageAvailable = false;
		this.isImageLoading = true;
	}

	ngAfterViewInit(): void {
		this.loadImage();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}



	private loadImage() {
		if (this.isGalleryFile(this.photoFile)) {
			this.svcDms.get(this.photoFile.guid).pipe(
				observeOn(asyncScheduler),
				tap((doc: IDmsData) => {
					this.fileData = doc;
					this.checkFileType();
					if (this.fileData.file.File) {
						if (this.isFile) {
							this.fileName = decodeURIComponent(escape(this.fileData.file.Name));
						}
						const lsValueToSanitize: string = this.fileData.file.File instanceof Blob ? this.moBlobUrlManager.createUrl(this.fileData.file.File) : this.fileData.file.File;
						this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(lsValueToSanitize) as string;
						this.isImageLoading = false
						this.isImageAvailable = true;
					}
				}),
				catchError(error => {
					this.isImageLoading = false
					this.isImageAvailable = false;
					return of(null);
				}),
				takeUntil(this.destroy$),
			).subscribe();
		} else {
			this.checkFileType();
			this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.photoFile as Blob)) as string;
			this.isImageAvailable = true;
			this.isImageLoading = false
		}
	}

	private isGalleryFile(file: any): file is IGalleryFile {
		return file && (file as IGalleryFile).guid !== undefined;
	}


	openOverlay(): void {
		this.isOverlayOpen = true;
	}

	onImageLoad(): void {
		this.isImageLoading = false;
	}

	closeOverlay(): void {
		this.isOverlayOpen = false;
	}

	deletePhoto(): void {
		this.delete.emit(this.photoFile);
	}

	checkFileType(): void {
		const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic', 'heif'];
		if (this.photoFile instanceof File || this.photoFile instanceof Blob) {
			const fileExtension = FileHelper.getExtensionFromMimeType(this.photoFile.type);
			this.isFile = !imageTypes.includes(fileExtension);
		}
		else {
			const fileExtension = FileHelper.getExtensionFromMimeType(this.fileData.file.MimeType);
			this.isFile = !imageTypes.includes(fileExtension);
		}
		if (this.isFile) {
			this.fileName = this.photoFile instanceof File ? this.photoFile.name : this.photoFile.file.Name
		}
	}
}
