import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'lua-color-item',
  templateUrl: './color-item.component.html',
  styleUrls: ['./color-item.component.css'],
})
export class ColorItemComponent implements OnInit {
  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  @Input() color = '';
  @Input() isSelected = false;
  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }
  onClick() {
    this.isSelected = !this.isSelected;
    this.cdr.detectChanges();
  }
}
