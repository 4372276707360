<div class="di-mode-de-reglement">
	<form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
		<h2>Etat</h2>
		<div class="liste_etats">
			<ng-container *ngFor="let etat of etats">
				<div class="etats">
					<lua-radio-button [label]="etat.labelTier" [fullWidth]="true" nameGroupe="etat"
						[checked]="etat.status === selectedEtat" (click)="selectedEtatClick($event,etat.status)"></lua-radio-button>
					<di-tag-suivi-facture [etat]="etat.status"></di-tag-suivi-facture>
				</div>
			</ng-container>
		</div>
		<div *ngIf="selectedEtat === 'paye'" class="liste_modes">
			<h2>Mode de règlement</h2>
			<ng-container *ngFor="let mode of modes">
				<lua-radio-button [label]="mode" [fullWidth]="true" nameGroupe="mode" [checked]="mode === selectedMode"
					(click)="selectedModeClick($event,mode)"></lua-radio-button>
			</ng-container>
		</div>
		<lua-input formControlName="date" type="date" label="Date" [fullWidth]="true"></lua-input>
		<lua-input formControlName="commentaire" label="Commentaire" [multiline]="true" [fullWidth]="true"></lua-input>
		<div class="actions">
			<lua-action-principale libelle="Enregistrer le règlement" [fullWidth]="true"
				[isDisabled]="!isValid || !form.valid" (click)="handleSubmit($event)"></lua-action-principale>
			<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true"
				(click)="onCancel()"></lua-bouton-texte>
		</div>
	</form>
</div>