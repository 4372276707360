<div class="lua-recherche" 
 [ngClass]="{
	'has-text': searchText.length > 0,
	'full-width': fullWidth
  }">
	<lua-icone [iconName]="'search'" [color]="'CouleurPrimaire'"></lua-icone>
	<input class="search-input" type="search" [placeholder]="placeHolderText" [value]="searchText"
		(input)="onSearch($event)" />
	<lua-bouton-icone *ngIf="searchText.length > 0" iconName="close" (click)="clearSearch()"></lua-bouton-icone>

	<ng-container *ngIf="secondaryAction">
		<ng-content *ngIf="!iconeDefault"></ng-content>
		<lua-bouton-icone *ngIf="iconeDefault" iconName="tune" color="CouleurPrimaire"
			(click)="handleSecondaryActionClick($event)"></lua-bouton-icone>
	</ng-container>
</div>