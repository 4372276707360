<mat-icon *ngIf="!isIcon" 
	class="lua-icon" 
	[ngClass]="{
		'material-icons': mode === 'filled' && isIcon,
		'material-icons-outlined': mode === 'outlined' && isIcon,
		'material-symbols-outlined': !isIcon,

		'icon-size': !!fontSize,
		'custom-icon-size': !!width && !!height
	}">{{ iconName }}</mat-icon>

<mat-icon *ngIf="isIcon" 
class="lua-icon" 
[ngClass]="{
	'material-icons': mode === 'filled' && isIcon,
	'material-icons-outlined': mode === 'outlined' && isIcon,
	'material-symbols-outlined': !isIcon,

	'icon-size': !!fontSize,
	'custom-icon-size': !!width && !!height
}" 
svgIcon="{{ customIconName }}">{{ iconName }}</mat-icon>