<div class="lua-filtre-groupe">
  <p
    *ngFor="let filter of filters"
    class="filtre-element"
    [ngClass]="{
      selected: selectedFilter === filter
    }"
    (click)="onFilterClick(filter)"
  >
    {{ filter }}
  </p>
</div>
