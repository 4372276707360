import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DateHelper, StringHelper } from '@osapp/helpers';
import { ETimetablePattern, IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { IRetrocession } from 'apps/idl/src/modules/patients/model/IRetrocession';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Retrocession } from '../../../../modules/patients/model/Retrocession';
import { MenuOptionsRetrocessionComponent } from '../../../features/retrocession/menu-options-retrocession/menu-options-retrocession.component';
import { ConfirmationSuppressionComponent } from '../../../features/shared/components/confirmation-suppression/confirmation-suppression.component';
import { DeviceService } from '../../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { LoaderService } from '../../../features/shared/services/loader.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { RetrocessionService } from '../../../features/shared/services/retrocession.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { StoredSeance } from '../../../models/StoredSeance';
import { PanneauListRetrocessionsComponent } from '../panneau-list-retrocessions/panneau-list-retrocessions.component';
import { from, Observable, of } from 'rxjs';
import { ImpressionService } from '../../../features/shared/services/impression.service';
import { ContactsService } from '@osapp/services';
import { SitesService } from '@osapp/modules/sites/services/sites.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { IActe } from 'apps/idl/src/model/IActe';
import { Majoration } from 'apps/idl/src/model/Majoration';
import { Indemnite } from 'apps/idl/src/modules/traitement/model/Indemnite';
import { DateObject } from '../../../models/retrocession/DateObject';
import { SeanceObject } from '../../../models/retrocession/SeanceObject';

@Component({
	selector: 'di-detail-retrocession',
	templateUrl: './detail-retrocession.component.html',
	styleUrls: ['./detail-retrocession.component.scss'],
})
export class DetailRetrocessionComponent extends DestroyableComponentBase implements OnInit {
  	@ViewChild('printContainer', { read: ViewContainerRef }) printContainer!: ViewContainerRef;

	@Input() retrocession: Retrocession;
	@Output() public onEditRetrocession = new EventEmitter<string>();

	@Input() public retrocessions: IRetrocession[];
	@Input() public utilisateurs: IContact[];
	@Input() public onSelectRetrocession: (retrocession: IRetrocession) => void = () => { };
	@Input() public onCreateRetrocession: (idRetroToEdit?: string) => void = () => { };

	public isMobileView: boolean = false;
	public seances: StoredSeance[];

	public nbJournee: number;
	public nbJourneeString: string;
	public nbSeances: number = 0;
	public totalActeMajo: number = 0;
	public totalIndem: number = 0;
	public totalRetro: number = 0;

	public showPopover: boolean = false;
	public infirmierFullName: string;
	private readonly C_RETROCESSION_DIRECTORY_NAME = "Rétrocessions";
	public journeesArray: { date: Date, dateObject: DateObject }[];
	public patients: IPatient[];

	constructor(
		private svcDevice: DeviceService,
		private svcSeances: SeanceService,
		private svcLoader: LoaderService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcRetrocession: RetrocessionService,
		private svcPanneau: PanneauService,
		private svcImpression: ImpressionService,
		private svcSite: SitesService,
		private svcContact: ContactsService,
		private svcPatient: PatientsService
	) {
		super();
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes['retrocession']) return;
		this.loadSeancesRetrocession();
		this.setInfirmierFullName();
	}

	setInfirmierFullName(): void {
		this.infirmierFullName = `${StringHelper.toTitleCase(this.retrocession.infirmier.firstName)} ${this.retrocession.infirmier.lastName.toUpperCase()}`;
	}

	editRetrocession(idRetrocession: string) {
		this.onEditRetrocession.emit(idRetrocession);
	}

	printRetrocession = (): void =>  {
		this.svcSite.getSite(UserData.currentSite._id).pipe(
			switchMap(cabinet =>
				this.svcContact.getContact(this.retrocession.infirmierId).pipe(
					map(infirmier => ({ cabinet, infirmier }))
				)
			),
			switchMap(({ cabinet, infirmier }) => {
				const printElement = this.svcImpression.createRetrocessionPrintView(
					this.printContainer,
					this.retrocession,
					cabinet,
					infirmier,
					this.journeesArray,
					this.nbJournee,
					this.totalActeMajo,
					this.totalIndem,
					this.totalRetro
				);
				const fileName = infirmier.finess
					? `retrocession_${infirmier.finess}_${DateHelper.transform(this.retrocession.dateFin, ETimetablePattern.yyyyMMdd)}`
					: `retrocession_${DateHelper.transform(this.retrocession.dateFin, ETimetablePattern.yyyyMMdd)}`;
				return from(
					this.svcImpression.printPdf(
						printElement,
						fileName,
						this.C_RETROCESSION_DIRECTORY_NAME,
						this.isMobileView
					)
				);
			}),
			switchMap(() => {
				const retrocessionToUpdate = { ...this.retrocession, estImprime: true };
				delete retrocessionToUpdate.infirmier;

				return this.svcRetrocession.createOrUpdate(retrocessionToUpdate).pipe(
					tap(() => this.svcRetrocession.triggerRefreshRetrocessionList(this.retrocession._id))
				);
			})
		).subscribe();
	}

	openPanneauListeRetrocession() {
		const panneauTitle = "Rétrocessions";
		const panneauContent = PanneauListRetrocessionsComponent;
		const panneauInputs = {
			retrocessions: this.retrocessions,
			selectedRetrocessionId: this.retrocession._id,
			utilisateurs: this.utilisateurs,
			onCreateRetrocession: this.onCreateRetrocession,
			onSelectRetrocession: this.onSelectRetrocession
		};
		this.svcPanneau.open(
			panneauTitle,
			panneauContent,
			panneauInputs
		);
	}


	public deleteRetrocession(event: Event) {
		this.svcDrawerPopover.open("Confirmation de suppression", "30%", event.currentTarget, ConfirmationSuppressionComponent, {
			onConfirm: () => this.handleConfirmDelete(),
			onCancel: () => this.handleCancelDelete()
		})
	}

	private handleConfirmDelete() {
		if (this.retrocession) {
			this.svcRetrocession.delete(this.retrocession).pipe(
				tap(() => {
					this.svcDrawerPopover.close();
					this.svcPanneau.close();
					this.svcRetrocession.triggerRefreshRetrocessionList();
				})
			).subscribe()
		}
	}

	private handleCancelDelete() {
		this.svcDrawerPopover.close();
	}

	openMenuOptions(event: Event) {
		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"",
			"250px",
			event.currentTarget,
			MenuOptionsRetrocessionComponent,
			{
				retrocession: this.retrocession,
				onEditClick: (retro: Retrocession) => {
					this.svcDrawerPopover.close();
					this.editRetrocession(retro._id);
				},
				onDownloadClick: () => {
					this.svcDrawerPopover.close();
					this.printRetrocession();
				}
			},
			() => (this.showPopover = false)
		);
	}

	private loadSeancesRetrocession(): void {
		this.svcLoader.showLoader("Récupération des séances ...");
		this.svcSeances.selectSeancesByRetrocession(this.retrocession).pipe(
			takeUntil(this.destroyed$),
			tap((seances: StoredSeance[]) => {
				this.seances = seances;
				this.calculeInfoRetrocession();
			}),
			switchMap(() => this.getPatients()),
			tap(() => this.sortSeances()),
			catchError((error) => {
				this.seances = [];
				this.calculeInfoRetrocession();
				return of([]); 
			}),
			finalize(() => {
				this.svcLoader.hideLoader();
			})
		).subscribe();
	}

	private calculeInfoRetrocession() {
		this.nbSeances = this.seances.length;
		const uniqueDays = new Set(
			this.seances.map((seance: StoredSeance) => new Date(seance.startDate).toISOString().split('T')[0])
		);
		this.nbJournee = uniqueDays.size;
		this.nbJourneeString = this.nbJournee > 0 ? `${this.nbJournee} journée${this.nbJournee > 1 ? 's' : ''} ` : "Aucune journée";

		this.totalActeMajo = 0;

		this.totalActeMajo = this.seances.reduce((total, seance) => {
			const actesTotal = seance.actes
				.filter(acte => acte.price)
				.reduce((sum, acte) => sum + acte.price * (this.retrocession.pourcentageActesMajorations / 100), 0);

			const majorationsTotal = seance.majorations
				.reduce((sum, majoration) => sum + majoration.price * (this.retrocession.pourcentageActesMajorations / 100), 0);

			return total + actesTotal + majorationsTotal;
		}, 0);

		this.totalIndem = this.seances.reduce((total, seance) => {
			const indemTotal = seance.indemnites
				.reduce((sum, indem) => sum + indem.price * (this.retrocession.pourcentageIndemnites / 100), 0);

			return total + indemTotal;
		}, 0);

		this.totalRetro = this.totalActeMajo + this.totalIndem;
	}

	private getPatients(): Observable<IPatient[]> {
		const patientIds: Set<string> = new Set();
		this.seances.forEach((seance: StoredSeance) => {
			patientIds.add(seance.patientId);
		});
		const patientIdsArray = Array.from(patientIds);
		return this.svcPatient.getPatientsByIds(patientIdsArray).pipe(
			tap((patients: IPatient[]) => {
				this.patients = patients;
			}),
		)
	}
	
	private sortSeances(): void {
		const datesMap: Map<string, DateObject> = new Map();
		this.seances.forEach((seance: StoredSeance) => {
			const seanceDate: Date = DateHelper.resetDay(new Date(seance.startDate));
			const seanceDateKey: string = seanceDate.toISOString();
			if (!datesMap.has(seanceDateKey)) {
				const newDateObject: DateObject = {
					patientIds: new Set<string>(),
					totalActesMajorations: 0,
					totalIndemnites: 0,
					total: 0,
					seances: []
				}
				datesMap.set(seanceDateKey, newDateObject);
			}
			const dateObject: DateObject = datesMap.get(seanceDateKey)!;
			this.updateDateObject(dateObject, seance);
		});
		this.journeesArray = Array.from(datesMap.entries())
			.map(([dateKey, dateObject]) => ({
				date: new Date(dateKey),
				dateObject
			}))
			.sort((a, b) => b.date.getTime() - a.date.getTime());
	}
	
	private updateDateObject(dateObject: DateObject, seance: StoredSeance): void {
		dateObject.seances.push(this.createSeanceObject(seance));
		dateObject.patientIds.add(seance.patientId);
		dateObject.totalActesMajorations += seance.actes.reduce((total: number, acte: IActe) => total + (acte.price * (this.retrocession.pourcentageActesMajorations / 100)), 0);
		dateObject.totalActesMajorations += seance.majorations.reduce((total: number, majoration: Majoration) => total + (majoration.price * (this.retrocession.pourcentageActesMajorations / 100)), 0);
		dateObject.totalIndemnites += seance.indemnites.reduce((total: number, indemnite: Indemnite) => total + (indemnite.price * (this.retrocession.pourcentageIndemnites / 100)), 0);
		dateObject.total = dateObject.totalIndemnites + dateObject.totalActesMajorations;
	}
	
	private createSeanceObject(seance: StoredSeance): SeanceObject {
		const patient: IPatient | undefined = this.patients.find((p: IPatient) => p._id === seance.patientId);
		const patientName: string = patient
			? `${this.svcPatient.getFullNamePatient(patient)} ${patient.firstName ?? ''}`.trim()
			: ""; 
		const moment: string = seance.moment ? StoredSeance.getLabelMoment(seance.moment) : "";
		let totalActesMajorations: number = seance.actes.reduce((total: number, acte: IActe) => total + acte.price, 0);
		totalActesMajorations += seance.majorations.reduce((total: number, majoration: Majoration) => total + majoration.price, 0);
		const totalIndemnites: number = seance.indemnites.reduce((total: number, indemnite: Indemnite) => total + indemnite.price, 0);
		const seanceObject: SeanceObject = {
			patientName,
			moment,
			totalActesMajorations,
			totalIndemnites,
		}
		return seanceObject;
	} 
}
