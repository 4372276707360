<div class="di-etat-tiers-payant">
	<form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
		<h2>Etat tiers payant</h2>
		<div class="liste_etats">
			<ng-container *ngFor="let etat of etats">
				<div class="etats">
					<lua-radio-button [label]="etat.labelTier" [fullWidth]="true" [checked]="etat.status === selectedEtat"
						(click)="selectedEtatClick($event,etat.status)"></lua-radio-button>
					<di-tag-suivi-facture [etat]="etat.status"></di-tag-suivi-facture>
				</div>
			</ng-container>
		</div>
		<lua-input formControlName="date" type="date" label="Date" [fullWidth]="true"></lua-input>
		<lua-input formControlName="commentaire" label="Commentaire" [multiline]="true" [fullWidth]="true"></lua-input>
		<div class="actions">
			<lua-action-principale libelle="Enregistrer" [isDisabled]="!selectedEtat || !form.valid" [fullWidth]="true"
				(click)="handleSubmit($event)"></lua-action-principale>
			<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true"
				(click)="onCancel()"></lua-bouton-texte>
		</div>
	</form>
</div>