import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRetrocession } from 'apps/idl/src/modules/patients/model/IRetrocession';

@Component({
	selector: 'di-retrocession-item',
	templateUrl: './retrocession-item.component.html',
	styleUrls: ['./retrocession-item.component.scss'],
})
export class RetrocessionItemComponent implements OnInit {
	@Input() public retrocession: IRetrocession;
	@Input() public selected: boolean = false;
	@Output() public retrocessionSelected = new EventEmitter<{ retrocession: IRetrocession }>();

	public period: string;

	constructor() {}

	ngOnInit(): void {
		const options: Intl.DateTimeFormatOptions = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric'
		};		
		const start = new Date(this.retrocession.dateDebut).toLocaleDateString('fr-FR', options);
		const end = new Date(this.retrocession.dateFin).toLocaleDateString('fr-FR', options);
		this.period = `${start} - ${end}`;
	}

	public selectRetrocession(): void {
		this.retrocessionSelected.emit({ retrocession: this.retrocession });
	}
	
}