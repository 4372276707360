import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ESize} from '../../../enums/esize';
@Component({
  selector: 'lua-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() public value = '';
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public iconEnd = '';
  @Input() public modeIcon: 'filled' | 'outlined' = 'filled';
  @Input() public type = 'text';
  @Input() min?: string;
  @Input() max?: string;
  @Input() public fullWidth = false;
  @Input() public isError = false;
  @Input() public helperText = '';
  @Input() public size = ESize.M;
  @Input() public multiline = false;
  @Input() public autoFocus = false;
  @Input() numberOfLines = 3;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickIcon = new EventEmitter<Event>();
  @Input() textareaResize = 'none';
  @Input() lineHeight = 24; // Approximate line height in pixels
  @Input() disabled = false;
  constructor() {}

  ngOnInit(): void {
    if (this.type === 'date') {
      this.min
        ? (this.min = new Date(this.min).toISOString().split('T')[0])
        : null;
      this.max
        ? (this.max = new Date(this.max).toISOString().split('T')[0])
        : null;
    }
  }

  preventNewLines(event: KeyboardEvent): void {
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea.value.split('\n');

    if (lines.length >= this.numberOfLines && event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onChange = (value: string) => {};

  onTouched = () => {};

  onClickIcon(event: MouseEvent): void {
    this.clickIcon.emit(event);
  }

  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      'input-small': this.size === ESize.S,
      'input-medium': this.size === ESize.M,
      'input-large': this.size === ESize.L,
      error: this.isError,
      disabled: this.disabled,
    };
  }
  onFocus() {
    const textAreaElement = document.querySelector('.lua-input');
    textAreaElement?.classList.add('focused');
  }

  onBlur() {
    const textAreaElement = document.querySelector('.lua-input');
    textAreaElement?.classList.remove('focused');
  }
}
