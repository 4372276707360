import {Component, Input, OnInit} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-divider-horizontal',
  templateUrl: './divider-horizontal.component.html',
  styleUrls: ['./divider-horizontal.component.css'],
})
export class DividerHorizontalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  public size? = ESize.L;

  getClasses(): {[key: string]: boolean} {
    return {
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
    };
  }
}
