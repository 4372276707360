import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lua-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css'],
})
export class RadioButtonComponent implements OnInit, OnChanges {
  public classes: {[key: string]: boolean} = {};
  constructor() {}

  ngOnInit(): void {
    this.updateClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClasses();
  }

  @Input() checked = false;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() label = '';
  @Input() nameGroupe = '';

  private updateClasses() {
    this.classes = {
      'full-width': this.fullWidth,
      checked: this.checked,
      disabled: this.disabled,
      'has-label': this.label !== '',
      'no-label': this.label === '',
    };
  }
}
