<div class="di-detail-retrocession">
	<main class="detail-retrocession-content">
		<header class="detail-retrocession-header">
			<div class="titre">
				<div class="soustitre">
					<h1>{{infirmierFullName}}</h1>
					<small>Rétrocession du {{retrocession.dateDebut | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}} au {{retrocession.dateFin | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}</small>
					<small>{{retrocession.pourcentageActesMajorations}}% sur les actes et majorations, {{retrocession.pourcentageIndemnites}}% sur indemnités</small>
					<strong *ngIf="retrocession.commentaire" class="commentaire">{{retrocession.commentaire}}</strong>
				</div>
				<div class="action-icons">
					<ng-container *ngIf="isMobileView">
						<lua-bouton-icone (onClick)="openMenuOptions($event)" iconName="more_vert" mode="outlined"></lua-bouton-icone>		
						<lua-bouton-icone (onClick)="openPanneauListeRetrocession()" iconName="menu" mode="outlined"></lua-bouton-icone>
					</ng-container>
					<ng-container *ngIf="!isMobileView">
						<lua-bouton-icone (onClick)="deleteRetrocession($event)" iconName="delete" color="Error" mode="outlined"></lua-bouton-icone>
						<lua-bouton-icone (onClick)="editRetrocession(retrocession._id)" iconName="edit" mode="outlined"></lua-bouton-icone>
						<lua-bouton-icone (onClick)="printRetrocession()" iconName="print" mode="outlined"></lua-bouton-icone>		
					</ng-container>
				</div>
			</div>
		</header>
		<section *ngIf="seances" class="info-seances-retrocession">
				<h2>{{nbJourneeString}}</h2>
				<ng-container *ngIf="!isMobileView">
					<div class="item-info">
						<strong>{{nbSeances}}</strong>
						<small>Séances réalisées</small>
					</div>
					<div class="item-info">
						<strong>{{totalActeMajo | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</strong>
						<small>Total actes réalisés {{retrocession.pourcentageActesMajorations}}%</small>
					</div>
					<div class="item-info">
						<strong>{{totalIndem | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</strong>
						<small> Total indemnités {{retrocession.pourcentageIndemnites}}%</small>
					</div>
				</ng-container>
			<div class="item-info">
				<h2>{{totalRetro | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</h2>
				<small>Montant total rétrocédé</small>
			</div>
		</section>
		<section class="list-seances-retrocession">
			<di-list-seances-retrocession 
				[journees]="journeesArray" 
				[pourcentageActesMajorations]="retrocession.pourcentageActesMajorations" 
				[pourcentageIndemnites]="retrocession.pourcentageIndemnites"
				[infirmier]="retrocession.infirmier"
				>
			</di-list-seances-retrocession>
		</section>
	</main>
</div>
<ng-container #printContainer>
</ng-container>