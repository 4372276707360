import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-message-aucune-donnee',
  templateUrl: './aucune-donnee.component.html',
  styleUrls: ['./aucune-donnee.component.css'],
})
export class AucuneDonneeComponent implements OnInit {
  constructor(private el: ElementRef) {}
  @Input() mainText = '';
  @Input() iconName = '';
  @Input() subText = '';
  @Input() buttonLabel = '';

  public size = ESize.L;

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  onAction(event: Event): void {
    this.onClick.emit(event);
  }

  ngOnInit(): void {}
}
