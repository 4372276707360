import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ESize} from '../../../enums/esize';

@Component({
  selector: 'lua-bouton-texte',
  templateUrl: './bouton-texte.component.html',
  styleUrls: ['./bouton-texte.component.css'],
})
export class BoutonTexteComponent implements OnInit {
  @Input() public libelle = '';
  @Input() public color = 'Indigo';
  @Input() public fullWidth = false;
  @Input() public isDisabled = false;
  @Input() public startIcon = '';
  @Input() public endIcon = '';
  //exemple : type="submit" pour les formulaires,
  //ou type="reset" si on veut clean tout un formulaire
  @Input() public type = 'button';

  @Input() public size = ESize.M;

  public fontSizeMap: {[key in ESize]?: string} = {
    [ESize.S]: '20px',
    [ESize.M]: '20px',
    [ESize.L]: '20px',
  };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      'size-s': this.size === ESize.S,
      'size-m': this.size === ESize.M,
      'size-l': this.size === ESize.L,
    };
  }

  getFontSize(): string {
    return this.fontSizeMap[this.size] || '20px';
  }
}
