import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPrefix, ETimetablePattern, IContact, IGroup, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, GroupsService } from '@osapp/services';
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { ITraitement } from 'apps/idl/src/model/ITraitement';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { IAccordPrealable } from 'apps/idl/src/modules/patients/model/IAccordPrealable';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { AMOP } from 'apps/idl/src/modules/patients/model/amo-p';
import { ESituation } from 'apps/idl/src/modules/patients/model/esituation.enum';
import { EUpdateMode } from 'apps/idl/src/modules/patients/model/eupdate-mode.enum';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { IConvention } from 'apps/idl/src/modules/patients/model/iconvention';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { AccordPrealableService } from 'apps/idl/src/modules/traitement/slides-traitement/accordPrealable/accordPrealable.service';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { Subject, Subscription, iif, merge, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../../../libs/osapp/src/helpers/arrayHelper';
import { DateHelper } from '../../../../../../../libs/osapp/src/helpers/dateHelper';
import { FacturationSvComponent } from '../../../features/facturation/components/facturation-sv/facturation-sv.component';
import { GroupeListeSeancesComponent } from '../../../features/shared/components/groupe-liste-seances/groupe-liste-seances-component';
import { PanneauAccordPrealableComponent } from '../../../features/shared/components/panneaux/panneau-accord-prealable/panneau-accord-prealable.component';
import { PanneauIdentitePatientComponent } from '../../../features/shared/components/panneaux/panneau-identite-patient/panneau-identite-patient.component';
import { PanneauOrdonnanceComponent } from '../../../features/shared/components/panneaux/panneau-ordonnance/panneau-ordonnance.component';
import { PanneauPatientAmcComponent } from '../../../features/shared/components/panneaux/panneau-patient-amc/panneau-patient-amc.component';
import { PanneauPatientAmoComponent } from '../../../features/shared/components/panneaux/panneau-patient-amo/panneau-patient-amo.component';
import { PanneauFormContactComponent } from '../../../features/shared/components/panneaux/panneau-prescripteur/panneau-form-contact.component';
import { EControleEtat } from '../../../features/shared/enums/EControleEtat';
import { DeviceService } from '../../../features/shared/services/device.service';
import { DrawerPopoverService } from '../../../features/shared/services/drawer-popover.service';
import { MedecinService } from '../../../features/shared/services/medecin.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';
import { StoredSeance } from '../../../models/StoredSeance';
import { ControleFacturation } from '../../../models/controle-facturation/ControleFacturation';
import { IControleBase } from '../../../models/controle-facturation/IControleBase';
import { SelectionTitulaireComponent } from '../../../features/facturation/components/selection-titulaire/selection-titulaire.component';
import { ITagSoin } from '../../../models/ITagSoin';
import { EMode } from '../../../features/shared/enums/EMode';
import { EProfile } from '../../../features/shared/enums/EProfile';

@Component({
  selector: 'di-controle-facturation',
  templateUrl: './controle-facturation.component.html',
  styleUrls: ['./controle-facturation.component.scss'],
})
export class ControleFacturationPage extends DestroyableComponentBase implements OnInit {
  public isMobile: boolean = false;
  /**
   * Liste de toutes les séances du traitement
  */
  public seances: StoredSeance[] = [];

  // Liste des séances affichées selon les critères (date de facturation + infirmier(s) sélectionné(s))
  public seancesDisplayed: StoredSeance[] = [];

  // Liste des séances facturables (ne contient aucune séance future)
  public seancesFacturables: StoredSeance[] = [];

  // Liste des séances facturables filtrées par les infirmiers sélectionnés
  public seancesFacturablesInfirmiers: StoredSeance[] = [];

  public idOrdonnance: string = "";
  public ordonnance: ITraitement; //v2 : Traitement = Ordonnance
  public patient: IPatient;
  public isFacturable: boolean = false;
  public infirmiers: IContact[];
  public convention: IConvention;
  public etablissementsById: Map<string, IAMC | IAMO> = new Map();
  public controlesFacturation: ControleFacturation = new ControleFacturation();
  public dateFacturation: Date = new Date();
  public facturationStart: boolean = false;

  public situations: Record<ESituation, string> = {
    [ESituation.assuranceMaladie]: "Assurance maladie",
    [ESituation.accidentTravail]: "Accident du travail",
    [ESituation.maternite]: "Maternité",
    [ESituation.preventionMaladie]: "Prévention maladie",
    [ESituation.soinsMedicauxGratuits]: "Soins médicaux gratuits",
  };

  public remplacantTag: ITagSoin = {
    label: "Remplaçant",
    color: "CouleurPrimaire",
    mode: EMode.filled,
  };

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;


  // Défini les conditions d'erreur du patient avec un ordre de priorité
  private erreursPatient = [
    { condition: (patient: IPatient) => !patient.birthDate, message: "Date de naissance non renseignée" },
    // { condition: (patient: IPatient) => !patient.carteVitale, message: "Carte vitale non lue" },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursDateOrdonnance = [
    { condition: (ordonnance: ITraitement) => !ordonnance.prescriptionDate, message: "Date de l'ordonnance non renseignée", etat: EControleEtat.ERROR },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];
  private erreursScanOrdonnance = [
    { condition: (ordonnance: ITraitement) => ordonnance.documents?.length === 0, message: "Aucun scan de l'ordonnance", etat: EControleEtat.WARNING },
    //TODO: à voir avec les analystes pour les conditions à ajouter
  ];

  private erreursAccordPrealable = [
    { condition: (ordonnance: ITraitement) => this.accordPrealable?.resultat !== true, message: "Demande d'accord préalable non validée", etat: EControleEtat.ERROR },
  ];

  private erreursPrescripteur = [
    { condition: (prescripteur: IContact) => prescripteur === undefined, message: "Prescripteur non renseigné", etat: EControleEtat.ERROR }
  ];

  private erreursNumeroPrescripteur = [
    { condition: (prescripteur: IContact) => prescripteur && !prescripteur.finess, message: "N° du prescripteur non renseigné", etat: EControleEtat.ERROR }
  ];

  private erreursAmo = [
    { condition: (couvAmo: AMOP) => !couvAmo, message: "Régime obligatoire non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmoNir = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.nir, message: "Numéro de sécurité social non renseigné", etat: EControleEtat.ERROR },
    //TODO : il faut mettre l'algo qui vérifie si le NIR est correct
    { condition: (couvAmo: AMOP) => couvAmo.nir?.length < 13, message: "Numéro de sécurité social incorrect", etat: EControleEtat.ERROR },
  ];

  private erreursAmoCouv = [
    // { condition: (couvAmo: AMOP) => !couvAmo || (!couvAmo.dateDebut && !couvAmo.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateDebut, message: "Date de début non renseignée", etat: EControleEtat.WARNING },
    // { condition: (couvAmo: AMOP) => !couvAmo.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmo: AMOP) => !couvAmo.isActiveAnakin, message: "Les couvertures ne sont plus valides", etat: EControleEtat.ERROR },
  ];

  private erreursAmoMode = [
    { condition: (couvAmo: AMOP) => !couvAmo || !couvAmo.updateMode || couvAmo.updateMode === EUpdateMode.manual, message: "Aucune lecture carte vitale ou ADRi", etat: EControleEtat.WARNING },
  ];

  private erreursAmc = [
    { condition: (couvAmc: AMCP) => !couvAmc, message: "Mutuelle non renseignée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => (couvAmc.gestionUnique && !couvAmc.mutnum) || (!couvAmc.gestionUnique && !couvAmc.amcId), message: "N° de télétransmission non renseigné", etat: EControleEtat.ERROR },
  ];

  private erreursAmcCouv = [
    { condition: (couvAmc: AMCP) => !couvAmc || (!couvAmc.dateDebut && !couvAmc.dateFin), message: "Dates de couverture non renseignées", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.isActive, message: "Date de fin dépassée", etat: EControleEtat.ERROR },
    { condition: (couvAmc: AMCP) => !couvAmc.dateFin, message: "Date de fin non renseignée", etat: EControleEtat.WARNING },
    { condition: (couvAmc: AMCP) => !couvAmc.gestionUnique && !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursAmcConvention = [
    { condition: (couvAmc: AMCP) => !couvAmc.gestionUnique && !couvAmc.idConvention, message: "Convention non sélectionnée", etat: EControleEtat.ERROR },
  ];

  private erreursInfirmier = [
    // { condition: (infirmier: IContact) => !infirmier.codeCpsSaved, message: "Carte CPS non enregistrée", etat: EControleEtat.ERROR },
  ];

  private erreurDateSituation = [
    { condition: (couvAmo: AMOP) => couvAmo.situation !== ESituation.assuranceMaladie && !DateHelper.isDate(couvAmo.situationDate), message: "Date de situation non renseignée", etat: EControleEtat.ERROR },
  ];

  private erreursSoinsAFacturer = [
    { condition: (seances: StoredSeance[]) => !ArrayHelper.hasElements(seances), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance réalisée", etat: EControleEtat.WARNING },
    { condition: (seances: StoredSeance[]) => !seances.some(x => x.status === EStatusSeance.done || x.status === EStatusSeance.to_be_done || x.status === EStatusSeance.canceled), message: "Aucune séance à facturer", etat: EControleEtat.ERROR },
    //TODO : A voir si on garde ici le cas ou il y a des séances annulées ou non réalisées
    // { condition: (seances: StoredSeance[]) => seances.some(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done), message: "Il y a des séances non réalisées ou annulées", etat: EControleEtat.WARNING },
  ];

  private selectMedecinSubscription: Subscription | null = null;
  private selectMedecinRunning$ = new Subject<boolean>();

  private accordPrealable: IAccordPrealable;

  private checkAccordPrealable: boolean = false;
  private amoCouverture: IAMO;
  private amcCouverture: IAMC;
  private amcPatient: AMCP;
  private currentContactId: string;

  constructor(
    private svcDevice: DeviceService,
    private svcTraitement: TraitementService,
    private svcPatient: PatientsService,
    private router: Router,
    private route: ActivatedRoute,
    private svcSeance: SeanceService,
    private svcContact: ContactsService,
    private svcGroupe: GroupsService,
    private readonly svcCouvertures: CouverturesService,
    private svcPanneau: PanneauService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcAccordPrealable: AccordPrealableService,
    private svcMedecin: MedecinService
  ) {
    super();
  }



  ngOnInit() {
    this.getDateFacturationFromRoute();
    this.idOrdonnance = this.route.snapshot.paramMap.get('id')!;
    if (this.idOrdonnance) {
      this.getOrdonnance();
    }

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });

    merge(
      this.svcSeance.refreshSeanceList$,
      this.svcPanneau.closePanel$
    ).pipe(
      takeUntil(this.destroyed$),
      debounceTime(300),
      switchMap(() =>
        // Si un prescripteur a été sélectionné, alors on attend que l'ordonnance soit mis à jour avant de refresh les contrôles
        this.selectMedecinRunning$.pipe(
          startWith(undefined),
          distinctUntilChanged(),
          switchMap((running: boolean | undefined) => {
            if (running === undefined || !running) {
              this.refreshControleFacturation();
              this.selectMedecinRunning$.complete();
            }
            return of(true);
          })
        )
      )
    ).subscribe();

    this.currentContactId = UserData.current._id.replace(EPrefix.user, EPrefix.contact);
  }

  public refreshControleFacturation() {
    this.controlesFacturation.initControle();
    this.getOrdonnance();
    this.checkIsFacturable();

  }

  // Modifie le tiers payant de l'AMO active tu patient
  toggleTiersPayantAMO = (value: boolean) => {
    if (this.patient && this.patient.AMO && this.patient.AMO.length > 0 && this.patient.AMO[0]) {
      this.patient.AMO[0].tp = value;
    }
  }

  // Modifie le tiers payant de l'AMC active tu patient
  toggleTiersPayantAMC = (value: boolean) => {
    if (this.patient && this.patient.AMC && this.patient.AMC.length > 0 && this.patient.AMC[0]) {
      this.patient.AMC[0].tp = value;
    }
  }

  onClickCancel() {
    this.router.navigate(["facturation"]);
  }

  facturer(event?: Event) {
    //On récupère les ids des remplacants qui sont sélectionnés
    const infRemplacantsIds: string[] = this.controlesFacturation.infirmiers.filter(x => x.etat === EControleEtat.OK && x.tag && x.isChecked).map(x => x.id);
    //On affiche la pop de sélection d'un titulaire uniquement si l'on a séances à facturer avec des remplacants et qu'on a au moins un titulaire et que l'on n'est pas le titulaire
    if (ArrayHelper.hasElements(this.controlesFacturation.infirmiers)
      && this.controlesFacturation.infirmiers.some(x => !x.tag)
      && this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.OK && x.tag && x.isChecked)
      && !this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.OK && x.isChecked && x.id === this.currentContactId)
      && this.seancesFacturablesInfirmiers.filter((seance) => seance.status === EStatusSeance.done).some(x => infRemplacantsIds.includes(x.infirmierId))) {
      this.openSelectionTitulaire(event);
    } else {
      this.openPanneauFacturation();
    }
  }

  private getDateFacturationFromRoute() {
    const state = this.router.getCurrentNavigation()?.extras.state as { dateFacturation: Date; };
    if (state?.dateFacturation) {
      // this.dateFacturation = new Date(state.dateFacturation);
      this.dateFacturation = state.dateFacturation;
    } else {
      this.dateFacturation = history.state.dateFacturation || new Date();
    }
  }

  private openPanneauFacturation = (titulaireSelected?) => {
    this.facturationStart = true;
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Facturation Sesam-Vitale",
        "700px",
        null,
        FacturationSvComponent,
        {
          seancesAFacturer: this.seancesFacturablesInfirmiers,
          seances: this.seances,
          ordonnance: this.ordonnance,
          patient: this.patient,
          couvertureAMO: this.amoCouverture,
          couvertureAMC: this.amcCouverture,
          couvertureAMCPatient: this.amcPatient,
          titulaireSelected: titulaireSelected
        })
    } else {
      this.svcPanneau.open("Facturation Sesam-Vitale", FacturationSvComponent, {
        seancesAFacturer: this.seancesFacturablesInfirmiers,
        seances: this.seances,
        ordonnance: this.ordonnance,
        patient: this.patient,
        couvertureAMO: this.amoCouverture,
        couvertureAMC: this.amcCouverture,
        couvertureAMCPatient: this.amcPatient,
        titulaireSelected: titulaireSelected
      });
    }
  }

  private openSelectionTitulaire(event: Event) {
    this.svcPanneau.close();
    this.svcDrawerPopover.open("Sélectionnez le titulaire",
      "auto",
      event.currentTarget,
      SelectionTitulaireComponent,
      {
        onFacturer: this.openPanneauFacturation,
        infirmiers: this.infirmiers.filter(x => !x.profiles) //On retire les remplacants
      })
  }

  private getOrdonnance() {
    this.facturationStart = false;
    //TODO : à changer en getITraitementANAKIN si on ne récupère pas les documents dans le type Traitement
    this.svcTraitement.getTraitementANAKIN(this.idOrdonnance).pipe(
      tap((ordo: ITraitement) => this.ordonnance = ordo),
      mergeMap(() => {
        const patientId = Traitement.extractPatientId(this.idOrdonnance);
        return this.getPatient(patientId);
      }),
      tap(() => this.checkAccordPrealable = this.ordonnance.actes.some(acte => acte.isPriorAgreement)),
      switchMap(() => {
        if (!this.checkAccordPrealable)
          return of(null)

        return this.svcAccordPrealable.get(this.ordonnance._id).pipe(
          tap((accord: IAccordPrealable) => {
            this.accordPrealable = accord;
          }),
          catchError(() => {
            return of(null)
          })
        )
      }),
      tap(() => { this.getOrdonnanceControles(this.ordonnance) }),
      mergeMap(() => this.svcSeance.selectSeancesByTraitement(this.ordonnance._id)
        .pipe(
          tap((seances: StoredSeance[]) => {
            this.seances = seances;
            const today = new Date();
            const startDate = DateHelper.resetDay(this.ordonnance.prescriptionDate);
            const dateFacturation = this.dateFacturation ?? today;
            const dateFacturationEndOfDay = DateHelper.fillDay(dateFacturation);

            // On compare les dates pour savoir si la date de facturation est celle du jour ou une date du futur
            const isSameDay: boolean = DateHelper.areDayEqual(dateFacturation, today)
            const compareDates: number = DateHelper.compareTwoDates(dateFacturation, today);
            const dateFacturationMax = (isSameDay || compareDates >= 0) ? today : dateFacturationEndOfDay;

            // Contient toutes les séances facturables (aucune séance future)
            this.seancesFacturables = seances.filter((seance: StoredSeance) =>
              DateHelper.isBetweenTwoDates(seance.startDate, startDate, dateFacturationMax)
            );
            this.seancesDisplayed = this.seancesFacturables;
            this.seancesFacturablesInfirmiers = this.seancesFacturables;

            this.getSoinsAFacturerControles(this.seancesDisplayed);
          }),
          switchMap(() => this.svcContact.getSiteContactsAnakin([], EPrefix.contact, true, true)),
          map((contacts: IContact[]) => {
            return contacts.map((contact: IContact) => {
              if (!contact.isDoctor && contact.finess)
                contact.isDoctor = true;
              return contact;
            });
          }),
          tap((contacts: IContact[]) => {
            this.infirmiers = contacts.filter(contact => contact.userId);
          }),
          switchMap(() => this.svcGroupe.getContactsGroups(this.infirmiers.map((infi: IContact) => infi._id))),
          tap((infiGroups: Map<string, IGroup[]>) => {
            this.infirmiers = this.infirmiers.map((inf: IContact) => {
              const groups = infiGroups.get(inf._id) || [];

              if (groups.some(group => group._id === 'grp_remplacant')) {
                //On insert le profile remplacant seulement s'il n'existe pas déjà
                inf.profiles = ArrayHelper.hasElements(inf.profiles)
                  ? Array.from(new Set([...inf.profiles, EProfile.remplacant]))
                  : [EProfile.remplacant];
              }


              return inf;
            });
            this.getInfirmiersControles(this.infirmiers);
          })
        )
      ),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.checkIsFacturable();
    });
  }

  private getPatientControles(patient: IPatient) {
    let controlePatient: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = this.erreursPatient.find(erreur => erreur.condition(patient));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePatient.etat = premiereErreur ? EControleEtat.ERROR : EControleEtat.OK;
    const fullLastName = this.svcPatient.getFullNamePatient(this.patient);
    controlePatient.libellePrincipal = [
      fullLastName.toUpperCase(),
      patient.firstName
        ? patient.firstName.charAt(0).toUpperCase() + patient.firstName.slice(1).toLowerCase()
        : ''
    ].filter(Boolean).join(' ');
    controlePatient.isBold = true;
    controlePatient.libelleSecondaire = [patient.street, patient.city].filter(Boolean).join(', ');

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePatient.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePatient.onClick = this.openPanneauIdentitePatient;

    this.controlesFacturation.patient = controlePatient;

  }

  private openPanneauSecuriteSociale = () => {
    const panneauTitle = "Assurance maladie obligatoire";
    const panneauContent = PanneauPatientAmoComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMO[0] && { couverture: this.patient.AMO[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  public openPanneauMutuelle = () => {
    const panneauTitle = "Mutuelle";
    const panneauContent = PanneauPatientAmcComponent;
    const panneauInputs = {
      patient: this.patient,
      ...(this.patient.AMC[0] && { couverture: this.patient.AMC[0] })
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private openPanneauIdentitePatient = () => {
    const panneauTitle = "Identification du patient";
    const panneauContent = PanneauIdentitePatientComponent;
    const panneauInputs = {
      patient: this.patient
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private ajouterControleOrdonnance(
    erreurs: { condition: (ordonnance: ITraitement) => boolean, etat: EControleEtat, message: string }[],
    ordonnance: ITraitement,
    libelle: string,
    onClick?: () => void
  ) {
    let controle: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = erreurs.find(erreur => erreur.condition(ordonnance));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controle.etat = premiereErreur?.etat ?? EControleEtat.OK;

    // Défini le libellé principal en fonction de l'état
    controle.libellePrincipal = controle.etat !== EControleEtat.ERROR ? libelle : "";

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controle.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controle.onClick = onClick ?? this.openPanneauOrdonnance;

    // Ajoute le contrôle à la facturation
    this.controlesFacturation.ordonnance.push(controle);
  }


  private getOrdonnanceControles(ordonnance: ITraitement) {
    //Prescripteur
    of(ordonnance.prescripteurContactId).pipe(
      switchMap((prescripteurContactId) =>
        // Si prescripteurContactId est défini alors on fait le getContact
        iif(
          () => prescripteurContactId !== undefined,
          this.svcContact.getContact(prescripteurContactId),
          of(undefined)
        )
      ),
      tap((prescripteur) => {
        this.getOrdonnancePrescripteurControles(prescripteur);
        // Contrôle pour la date de l'ordonnance
        const libellePrincipalDate = ["Du", DateHelper.transform(ordonnance.prescriptionDate, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ');
        this.ajouterControleOrdonnance(this.erreursDateOrdonnance, ordonnance, libellePrincipalDate);

        // Contrôle pour le scan de l'ordonnance
        this.ajouterControleOrdonnance(this.erreursScanOrdonnance, ordonnance, "Scan de l'ordonnance (SCOR)");

        // Contrôle pour l'accord préalable de l'ordonnance
        if (this.checkAccordPrealable) {
          this.ajouterControleOrdonnance(this.erreursAccordPrealable, ordonnance, "Accord préalable", this.openPanneauAccordPrealable);
        }
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
  }

  private getOrdonnancePrescripteurControles(prescripteur: IContact) {
    let controlePresc: IControleBase = {} as IControleBase;
    const premiereErreur = this.erreursPrescripteur.find(erreur => erreur.condition(prescripteur));
    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controlePresc.etat = premiereErreur?.etat ?? EControleEtat.OK;
    controlePresc.libellePrincipal = [prescripteur?.isDoctor ? "Dr" : "", prescripteur?.lastName?.toUpperCase(), prescripteur?.firstName].filter(Boolean).join(' ');
    controlePresc.isBold = true;
    controlePresc.libelleSecondaire = prescripteur?.rpps ? `RPPS : ${prescripteur.rpps}` : ''
    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controlePresc.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controlePresc.onClick = this.openPanneauOrdonnance;
    this.controlesFacturation.ordonnance.push(controlePresc);

    // Si le prescripteur est renseigné, on ajoute le contrôle sur le numéro de prescripteur
    if (!premiereErreur) {
      this.ajouterControlePrescripteur(
        this.erreursNumeroPrescripteur,
        prescripteur,
        "Numéro de prescripteur",
        () => this.openContactPrescripteur(prescripteur)
      )
    }

  }

  private openContactPrescripteur(prescripteur: IContact) {
    const drawerPanneauTitle = "Prescripteur";
    const drawerPanneauContent = PanneauFormContactComponent;
    const drawerPanneauInputs = {
      contact: prescripteur
    };
    this.svcPanneau.open(
      drawerPanneauTitle,
      drawerPanneauContent,
      drawerPanneauInputs
    );
  }

  private ajouterControlePrescripteur(
    erreurs: { condition: (Prescripteur: IContact) => boolean, etat: EControleEtat, message: string }[],
    prescripteur: IContact,
    libelle: string,
    onClick?: () => void
  ) {
    let controle: IControleBase = {} as IControleBase;

    // Trouve la première erreur applicable
    const premiereErreur = erreurs.find(erreur => erreur.condition(prescripteur));

    // Défini l'état : s'il y a une erreur, met l'état à ERROR, sinon à OK
    controle.etat = premiereErreur?.etat ?? EControleEtat.OK;

    // Défini le libellé principal en fonction de l'état
    controle.libellePrincipal = controle.etat !== EControleEtat.ERROR ? libelle : "";

    // Défini le libellé d'erreur basé sur la première erreur trouvée
    controle.libelleErreur = premiereErreur ? premiereErreur.message : "";
    controle.onClick = onClick ?? this.openPanneauOrdonnance;

    // Ajoute le contrôle à la facturation
    this.controlesFacturation.ordonnance.push(controle);
  }

  private openPanneauOrdonnance = () => {
    const panneauTitle = "Ordonnance";
    const panneauContent = PanneauOrdonnanceComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );

    if (this.selectMedecinSubscription) {
      this.selectMedecinSubscription.unsubscribe();
    }

    this.selectMedecinSubscription = this.svcMedecin.selectMedecin$.pipe(
      take(1),
      takeUntil(this.destroyed$)
    ).subscribe((medecinId: string) => {
      this.selectMedecinRunning$.next(true);
      this.svcContact.getContact(medecinId).pipe(
        take(1),
        switchMap((medecin: IContact) => {
          this.ordonnance.prescripteurContactId = medecin._id;
          const ordonnanceObject = Traitement.createFromData(this.ordonnance);
          return this.svcTraitement.saveTraitementANAKIN(ordonnanceObject).pipe(
            tap((ordonnance: ITraitement) => {
              this.ordonnance = ordonnance;
            }),
            takeUntil(this.destroyed$)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.selectMedecinRunning$.next(false);
      });
    });
  }

  private openPanneauAccordPrealable = () => {
    const panneauTitle = "Accord préalable";
    const panneauContent = PanneauAccordPrealableComponent;
    const panneauInputs = {
      ordonnance: this.ordonnance,
      accord: this.accordPrealable
    };
    this.svcPanneau.open(
      panneauTitle,
      panneauContent,
      panneauInputs
    );
  }

  private getAmoControles(patient: IPatient) {
    const amoP: AMOP = ArrayHelper.hasElements(patient.AMO) ? patient.AMO.find(x => x.isActiveAnakin) ?? patient.AMO[0] : null;

    //Si on a pas d'amo on grise tous les controles
    let controleTP: IControleBase = {} as IControleBase;
    let controleAmo: IControleBase = {} as IControleBase;
    let controleAmoNir: IControleBase = {} as IControleBase;
    let controleAmoCouv: IControleBase = {} as IControleBase;
    let controleAmoMode: IControleBase = {} as IControleBase;
    let controleAmoDateSitu: IControleBase = {} as IControleBase;

    if (!amoP) {
      controleAmo.etat = EControleEtat.DISABLED;
      controleAmo.libelleErreur = "Régime obligatoire";

      controleAmoNir.etat = EControleEtat.DISABLED;
      controleAmoNir.libelleErreur = "Numéro de sécurité sociale";

      controleAmoCouv.etat = EControleEtat.DISABLED;
      controleAmoCouv.libelleErreur = "Période de droits";

      controleAmoMode.etat = EControleEtat.DISABLED;
      controleAmoMode.libelleErreur = "Contrôle ADRI ou carte vitale";

      controleTP.isDisabled = true;

      this.amoCouverture = null;
    } else {
      const premiereErreurAmo = this.erreursAmo.find(erreur => erreur.condition(amoP));
      const libelleAmo = amoP ? this.svcCouvertures.getLabelCouverture(amoP.amoId, this.etablissementsById) : "";
      this.amoCouverture = amoP ? this.svcCouvertures.getEtablissementById(amoP.amoId, this.etablissementsById) as IAMO : null;
      controleAmo.etat = premiereErreurAmo?.etat ?? EControleEtat.OK;
      controleAmo.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmo : "";
      controleAmo.isBold = true;
      controleAmo.libelleSecondaire = controleAmo.etat !== EControleEtat.ERROR ? amoP.amoId.replace("amo_", "").replace(/-/g, "") : "";
      controleAmo.libelleErreur = premiereErreurAmo ? premiereErreurAmo.message : "";

      const premiereErreurAmoNir = this.erreursAmoNir.find(erreur => erreur.condition(amoP));
      const libelleAmoNir = amoP ? amoP.nir : "";
      controleAmoNir.etat = premiereErreurAmoNir?.etat ?? EControleEtat.OK;
      controleAmoNir.libellePrincipal = libelleAmoNir;
      controleAmoNir.libelleErreur = premiereErreurAmoNir ? premiereErreurAmoNir.message : "";

      const premiereErreurAmoCouv = this.erreursAmoCouv.find(erreur => erreur.condition(amoP));
      const libelleAmoCouv = amoP ? [amoP.dateDebut ? "Du" : "", DateHelper.transform(amoP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amoP.dateDebut ? (amoP.dateFin ? "au" : "") : (amoP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amoP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      if (!amoP.dateDebut && !amoP.dateFin) {
        controleAmoCouv.etat = EControleEtat.DISABLED
        controleAmoCouv.libelleErreur = "Période de droits";
      } else {
        controleAmoCouv.libellePrincipal = libelleAmoCouv;
        controleAmoCouv.etat = premiereErreurAmoCouv?.etat ?? EControleEtat.OK;
        controleAmoCouv.libelleErreur = premiereErreurAmoCouv ? premiereErreurAmoCouv.message : "";
      }

      if (amoP.situation !== ESituation.assuranceMaladie) {
        const premiereErreurAmoDateSitu = this.erreurDateSituation.find(erreur => erreur.condition(amoP));
        const libelleAmoDateSitu = this.situations[amoP.situation];
        const libelleSecAmoDateSitu = amoP ? [amoP.situationDate ? DateHelper.transform(amoP.situationDate, ETimetablePattern.dd_MM_yyyy_slash) : ""].filter(Boolean).join(' ') : "";
        controleAmoDateSitu.isBold = true;
        controleAmoDateSitu.etat = premiereErreurAmoDateSitu?.etat ?? EControleEtat.OK;
        controleAmoDateSitu.libelleSecondaire = controleAmoDateSitu.etat !== EControleEtat.ERROR ? libelleSecAmoDateSitu : "";
        controleAmoDateSitu.libellePrincipal = libelleAmoDateSitu;
        controleAmoDateSitu.libelleErreur = premiereErreurAmoDateSitu ? premiereErreurAmoDateSitu.message : "";
      }

      const premiereErreurAmoMode = this.erreursAmoMode.find(erreur => erreur.condition(amoP));
      const libelleAmoMode = "Contrôle ADRI ou carte vitale";
      controleAmoMode.etat = premiereErreurAmoMode?.etat ?? EControleEtat.OK;
      controleAmoMode.libellePrincipal = controleAmo.etat !== EControleEtat.ERROR ? libelleAmoMode : "";
      controleAmoMode.libelleErreur = premiereErreurAmoMode ? premiereErreurAmoMode.message : "";

      controleTP.onToggleSwitch = this.toggleTiersPayantAMO
    }

    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amoP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";
    this.controlesFacturation.amo.push(controleTP);

    controleAmo.onClick = this.openPanneauSecuriteSociale;
    controleAmoNir.onClick = this.openPanneauSecuriteSociale;
    controleAmoCouv.onClick = this.openPanneauSecuriteSociale;
    controleAmoMode.onClick = this.openPanneauSecuriteSociale;
    this.controlesFacturation.amo.push(controleAmo);
    this.controlesFacturation.amo.push(controleAmoNir);
    this.controlesFacturation.amo.push(controleAmoCouv);
    this.controlesFacturation.amo.push(controleAmoMode);

    if (amoP?.situation !== ESituation.assuranceMaladie) {
      controleAmoDateSitu.onClick = this.openPanneauSecuriteSociale;
      this.controlesFacturation.amo.push(controleAmoDateSitu);
    }

  }

  private getAmcControles(patient: IPatient) {
    const amcP: AMCP = ArrayHelper.hasElements(patient.AMC) ? patient.AMC.find(x => x.isActive) ?? patient.AMC[0] : null;

    let controleTP: IControleBase = {} as IControleBase;
    let controleAmc: IControleBase = {} as IControleBase;
    let controleAmcCouv: IControleBase = {} as IControleBase;
    let controleAmcMode: IControleBase = {} as IControleBase;
    if (!amcP) {
      controleAmc.etat = EControleEtat.DISABLED;
      controleAmc.libelleErreur = "Tiers payeur mutuelle";

      controleAmcCouv.etat = EControleEtat.DISABLED;
      controleAmcCouv.libelleErreur = "Période  de droits";

      controleAmcMode.etat = EControleEtat.DISABLED;
      controleAmcMode.libelleErreur = "Convention de télétransmission";

      controleTP.isDisabled = true;

      this.amcPatient = null;
      this.amcCouverture = null;
    } else {
      const premiereErreurAmc = this.erreursAmc.find(erreur => erreur.condition(amcP));
      const libelleAmc = amcP ? this.svcCouvertures.getLabelCouverture(amcP.amcId, this.etablissementsById) : "";
      this.amcPatient = amcP ?? null;
      this.amcCouverture = amcP ? this.svcCouvertures.getEtablissementById(amcP.amcId, this.etablissementsById) as IAMC : null;
      controleAmc.etat = premiereErreurAmc?.etat ?? EControleEtat.OK;
      controleAmc.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmc : "";
      controleAmc.isBold = true;
      controleAmc.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? amcP.gestionUnique ? `${amcP.amcId.replace("amc_", "")} - GU : ${amcP.mutnum}` : amcP.amcId.replace("amc_", "") : "";
      controleAmc.libelleErreur = premiereErreurAmc ? premiereErreurAmc.message : "";

      const premiereErreurAmcCouv = this.erreursAmcCouv.find(erreur => erreur.condition(amcP));
      const libelleAmcCouv = amcP ? [amcP.dateDebut ? "Du" : "", DateHelper.transform(amcP.dateDebut, ETimetablePattern.dd_MM_yyyy_slash), amcP.dateDebut ? (amcP.dateFin ? "au" : "") : (amcP.dateFin ? "jusqu'au" : ""), DateHelper.transform(amcP.dateFin, ETimetablePattern.dd_MM_yyyy_slash)].filter(Boolean).join(' ') : "";
      controleAmcCouv.etat = premiereErreurAmcCouv?.etat ?? EControleEtat.OK;
      controleAmcCouv.libellePrincipal = libelleAmcCouv;
      controleAmcCouv.libelleErreur = premiereErreurAmcCouv ? premiereErreurAmcCouv.message : "";

      if (!amcP.gestionUnique) {
        const premiereErreurAmcConv = this.erreursAmcConvention.find(erreur => erreur.condition(amcP));
        const libelleAmcMode = !amcP.convention ? "Convention sélectionnée" : [amcP.convention.libelle, amcP.convention.estTp ? "(TP)" : ""].filter(Boolean).join(" ");
        const libelleSecAmcMode = !amcP.convention ? "" : [amcP.convention.typeConvention, amcP.convention.codeOganismeComplementaire, amcP.convention.critereSecondaire].filter(Boolean).join(" - ");
        controleAmcMode.etat = premiereErreurAmcConv?.etat ?? EControleEtat.OK;
        controleAmcMode.libellePrincipal = controleAmc.etat !== EControleEtat.ERROR ? libelleAmcMode : "";
        controleAmcMode.isBold = true;
        controleAmcMode.libelleSecondaire = controleAmc.etat !== EControleEtat.ERROR ? libelleSecAmcMode : "";
        controleAmcMode.libelleErreur = premiereErreurAmcConv ? premiereErreurAmcConv.message : "";
      }

      controleTP.onToggleSwitch = this.toggleTiersPayantAMC
    }
    controleTP.isSwitchOnly = true;
    controleTP.isChecked = amcP?.tp || false;
    controleTP.libellePrincipal = "Tiers payant";

    controleAmc.onClick = this.openPanneauMutuelle
    controleAmcCouv.onClick = this.openPanneauMutuelle
    controleAmcMode.onClick = this.openPanneauMutuelle
    this.controlesFacturation.amc.push(controleTP);
    this.controlesFacturation.amc.push(controleAmc);
    this.controlesFacturation.amc.push(controleAmcCouv);
    if (amcP && !amcP.gestionUnique) {
      this.controlesFacturation.amc.push(controleAmcMode);
    }
  }

  private getSoinsAFacturerControles(seances: StoredSeance[]) {
    this.controlesFacturation.soinsAFacturer = [];
    let controleDateSeance: IControleBase = {} as IControleBase;
    controleDateSeance.etat = EControleEtat.OK;
    controleDateSeance.iconEnd = "";
    controleDateSeance.libellePrincipal = ["jusqu'au", DateHelper.transform(this.dateFacturation, ETimetablePattern.dd_MM_yyyy_slash)].join(" ");
    this.controlesFacturation.soinsAFacturer.push(controleDateSeance);

    let controleSeances: IControleBase = {} as IControleBase;
    const nbSeancesAFacturer = seances.filter(x => x.status === EStatusSeance.done).length;
    const nbSeancesAnnuleesOuNonRealisees = seances.filter(x => x.status === EStatusSeance.canceled || x.status === EStatusSeance.to_be_done).length;
    const aucuneSeances = nbSeancesAFacturer === 0 && nbSeancesAFacturer === 0;
    const premiereErreurSeances = this.erreursSoinsAFacturer.find(erreur => erreur.condition(seances));
    const libelleSeance = [nbSeancesAFacturer, nbSeancesAFacturer > 1 ? "séances" : "séance", "à facturer"].join(" ");
    const libelleSeanceAnnulee = [nbSeancesAnnuleesOuNonRealisees, nbSeancesAnnuleesOuNonRealisees > 1 ? "séances non réalisées ou annulées" : "séance non réalisée ou annulée"].join(" ");
    controleSeances.iconEnd = "chevron_right";
    controleSeances.etat = premiereErreurSeances?.etat ?? (nbSeancesAnnuleesOuNonRealisees ? EControleEtat.WARNING : EControleEtat.OK);
    controleSeances.libellePrincipal = controleSeances.etat !== EControleEtat.ERROR ? libelleSeance : "";
    controleSeances.isBold = true;
    controleSeances.libelleSecondaire = controleSeances.etat !== EControleEtat.ERROR ? "voir le détail" : "";
    controleSeances.libelleErreur = premiereErreurSeances && controleSeances.etat === EControleEtat.WARNING ? libelleSeanceAnnulee : (premiereErreurSeances && controleSeances.etat === EControleEtat.ERROR ? premiereErreurSeances.message : "");
    controleSeances.onClick = this.openPanneauSoinsAFacturer;
    this.controlesFacturation.soinsAFacturer.push(controleSeances);
  }

  public openPanneauSoinsAFacturer = () => {
    if (this.isMobile) {
      const drawerPanneauTitle = "Séances à facturer";
      const drawerPanneauContent = GroupeListeSeancesComponent;
      const drawerPanneauInputs = {
        seances: this.seancesDisplayed,
        ordonnance: this.ordonnance,
        patient: this.patient,
        facturationStart: this.facturationStart,
        isFacturable: this.isFacturable,
        amoCouverture: this.amoCouverture,
        amcCouverture: this.amcCouverture,
        amcPatient: this.amcPatient,
        seancesAFacturer: this.seancesFacturablesInfirmiers
      };
      this.svcPanneau.open(
        drawerPanneauTitle,
        drawerPanneauContent,
        drawerPanneauInputs
      );
    }
    else {
      this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // Ajoute ou retire les séances de l'infirmier ciblé de la liste des séances à facturer
  toggleInfirmier = (value: boolean, id: string) => {
    if (!value) {
      this.seancesDisplayed = this.seancesDisplayed.filter((seance: StoredSeance) => seance.infirmierId !== id);
      this.seancesFacturablesInfirmiers = this.seancesFacturablesInfirmiers.filter((seance: StoredSeance) => seance.infirmierId !== id);
    } else {
      this.seancesDisplayed = [...this.seancesDisplayed, ...this.seancesFacturables.filter((seance: StoredSeance) => seance.infirmierId === id)];
      this.seancesFacturablesInfirmiers = [...this.seancesFacturablesInfirmiers, ...this.seancesFacturables.filter((seance: StoredSeance) => seance.infirmierId === id)];
    }
    this.controlesFacturation.infirmiers.find(x => x.id === id).isChecked = value;
    this.getSoinsAFacturerControles(this.seancesDisplayed);
  }

  private getInfirmiersControles(infirmiers: IContact[]) {
    const infirmierIdsChecked = ArrayHelper.unique(this.seancesDisplayed.map(seance => seance.infirmierId))
    if (ArrayHelper.hasElements(infirmiers)) {
      let controlesInf: IControleBase[] = [];
      infirmiers.forEach((inf) => {
        let controleInf: IControleBase = {} as IControleBase;
        controleInf.libellePrincipal = [inf.firstName, inf.lastName?.toUpperCase()].filter(Boolean).join(" ");
        controleInf.isSwitch = true;
        controleInf.id = inf._id;
        //On ajoute le tag remplacant
        if (inf.profiles?.some(x => x === EProfile.remplacant)) {
          controleInf.tag = this.remplacantTag;
        }
        if (infirmierIdsChecked.includes(inf._id)) {
          //TODO a décommenter lorsque la fonctionnalité de cps sur l'infirmier sera dev
          const premiereErreurInf = this.erreursInfirmier.find(erreur => erreur.condition(inf));
          controleInf.etat = premiereErreurInf?.etat ?? EControleEtat.OK; controleInf.isChecked = true;
          controleInf.onToggleSwitch = (isChecked: boolean) => this.toggleInfirmier(isChecked, inf._id);
        } else {
          controleInf.etat = EControleEtat.DISABLED;
          controleInf.isDisabled = true;
          controleInf.isChecked = false;
          controleInf.libelleSecondaire = 'Aucune séance'
        }
        controlesInf.push(controleInf);

      });
      this.controlesFacturation.infirmiers = controlesInf;
    }
  }



  private getPatient(patientId: string) {
    return this.svcPatient.getPatient(patientId).pipe(
      tap((patient: IPatient) => {
        this.patient = patient;
        this.getPatientControles(patient);
      }),
      switchMap(() => this.svcCouvertures.getPatientSortedCouvertures(this.patient._id, false)),
      switchMap((poCouvertures: { AMOPs: AMOP[]; AMCPs: AMCP[] }) => {

        // AMO active du patient
        const activeAmo: AMOP = ArrayHelper.getFirstElement(poCouvertures.AMOPs.filter((amo: AMOP) => amo.isActiveAnakin));
        this.patient.AMO = activeAmo ? [activeAmo] : [];

        // AMC active du patient
        const activeAmc: AMCP = ArrayHelper.getFirstElement(poCouvertures.AMCPs.filter((amc: AMCP) => amc.isActiveAnakin));
        this.patient.AMC = activeAmc ? [activeAmc] : [];

        const idsCouv: string[] = []
        if (activeAmo) {
          idsCouv.push(activeAmo.amoId)
          activeAmo.nir = this.patient.socialNumber ?? undefined
        }
        if (activeAmc)
          idsCouv.push(activeAmc.amcId)

        //Récupération des établissements liés aux AMO et AMC du patient
        return this.svcCouvertures.getEtablissementsByIds(idsCouv)
      }),
      tap((etablissements: (IAMC | IAMO)[]) => {
        etablissements.forEach((etablissement: IAMC | IAMO) => this.etablissementsById.set(etablissement._id, etablissement));
      }),
      tap(() => {
        this.getAmoControles(this.patient);
        this.getAmcControles(this.patient);
      }),
      takeUntil(this.destroyed$),
      catchError(error => {
        console.error('Erreur lors de la récupération du patient et de ses couvertures ', error);
        return of(null);
      })
    );
  }

  private checkIsFacturable() {
    //TODO : ajouter des exceptions en fonction des retours, attente NIE
    this.isFacturable = this.seancesDisplayed.some(x => x.status === EStatusSeance.done)
      && !(this.controlesFacturation.patient?.etat === EControleEtat.ERROR
        || this.controlesFacturation.ordonnance.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amo.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.amc.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.infirmiers.some(x => x.etat === EControleEtat.ERROR)
        || this.controlesFacturation.soinsAFacturer.some(x => x.etat === EControleEtat.ERROR))
  }

}
