<div class="lua-color-picker" [ngClass]="{'full-width': fullWidth}">
  <div *ngIf="label || secondaryLabel" class="text-container">
    <p class="label">{{ label }}</p>
    <small class="secondary-label">{{ secondaryLabel }}</small>
  </div >
    <div class="item-color-container"  >
      <lua-color-item
      *ngFor="let option of colors"
        [color]="option.name"
        [isSelected]="isSelected(option)"
        (click)="onOptionClick(option)"
      ></lua-color-item>
    </div>
  </div>