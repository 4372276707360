<button class="lua-bouton-icone" 
	[ngClass]="{
		'size-s': size === 'small',
		'size-m': size === 'medium',
		'size-l': size === 'large',
		disabled: isDisabled
	}" 
	(click)="handleClick($event)" 
	type="button">
	<lua-icone [color]="color" [iconName]="iconName" [fontSize]="fontSize" [isIcon]="isIcon" [mode]="mode"></lua-icone>
</button>