import { Injectable } from '@angular/core';
import { ArrayHelper } from '@osapp/helpers';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EDatabaseRole, EPrefix, IDataSource, IStoreDocument } from '../../../../../../../libs/osapp/src/model';
import { Store } from '../../../../../../../libs/osapp/src/services/store.service';
import { IRetrocession } from '../../../../modules/patients/model/IRetrocession';
import { IRetrocessionNumber } from '../../../../modules/patients/model/IRetrocessionNumber';

@Injectable({
	providedIn: "root"
})
export class RetrocessionService {

	constructor(private svcStore: Store,
	) { }

	private refreshRetrocessionSubject = new Subject<string>();
	refreshRetrocessionList$ = this.refreshRetrocessionSubject.asObservable();


	public triggerRefreshRetrocessionList(idSelectedRetro?: string) {
		this.refreshRetrocessionSubject.next(idSelectedRetro);
	}

	/** Creation ou update du document de retrocession
	 * @param retro Le document de retrocession.
	 */
	public createOrUpdate(retro: IRetrocession) {
		const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		return this.svcStore.put(retro, databaseId);
	}

	/** Get du document de retrocession
	 * @param retrocessionId id de retrocession.
	 */
	public get(retrocessionId: string): Observable<IRetrocession> {
		const databaseId = ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		const loDataSource: IDataSource = {
			databaseId: databaseId,
			viewParams: {
				include_docs: true,
				keys: [`${EPrefix.retrocession}${retrocessionId}`]
			}
		};

		return this.svcStore.getOne(loDataSource).pipe(
			map((doc: IStoreDocument) => {
				return doc as IRetrocession;
			}),
			catchError((error: any) => {
				return throwError(error);
			})
		);
	}

	/** Delete du document retrocession
	 * @param retrocession 
	 */
	public delete(retrocession: IRetrocession) {
		return this.svcStore.delete(retrocession);

	}

	generateNumber(): Observable<number> {
		const databaseId = ArrayHelper.getFirstElement(
			this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)
		);

		const dataSource: IDataSource = {
			databaseId: databaseId,
			viewParams: {
				include_docs: true,
				keys: [`seed_retrocession_number`],
			},
		};

		return this.svcStore.getOne(dataSource).pipe(
			map((doc: IStoreDocument) => doc as IRetrocessionNumber),
			map((docNumber: IRetrocessionNumber) => {
				const currentNumber = docNumber.lastNumber++;
				return { docNumber, currentNumber };
			}),
			mergeMap(({ docNumber, currentNumber }) =>
				this.svcStore.put(docNumber).pipe(map(() => currentNumber))
			)
		);
	}

	public getAll(): Observable<IRetrocession[]> {
		const dataSource = {
			databasesIds: this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewName: "retrocessions/by_id",
		};
		return this.svcStore.get<IRetrocession>(dataSource);
	}
}
