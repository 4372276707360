import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Credentials, ESecurityFlag, IContact, IUser, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, FlagService, SecurityService, Store } from '@osapp/services';
import { from, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { MenuCompteUtilisateurComponent } from '../menu-compte-utilisateur/menu-compte-utilisateur.component';
import { NavController } from '@ionic/angular';
import { AuthenticatorService } from '../../services/authenticator.service';
import { EPermissionsFlag } from '@osapp/modules/permissions/models/EPermissionsFlag';

@Component({
	selector: "di-entete-desktop",
	templateUrl: './entete-desktop.component.html',
	styleUrls: ['./entete-desktop.component.scss'],
})
export class EnteteDesktopComponent extends DestroyableComponentBase implements OnInit{
	@ViewChild('menuUtilisateur', { static: false }) menuUtilisateur!: ElementRef<HTMLDivElement>;

	public firstName: string;
	public color: string;
	public lastName: string;
	public siteName: string;
	public siteCity: string;
	public showPopover: boolean= false;
	public contact: IContact;

	constructor(
		private svcDrawerPopover : DrawerPopoverService,
		private svcContact: ContactsService,
		private svcSecurity: SecurityService,
		private ioNavController: NavController,
		private svcAuthenticator: AuthenticatorService,
		private svcFlag: FlagService,
		private svcStore: Store
		) { super()}

	public ngOnInit(): void {
		this.siteName = UserData.currentSite?.name;
		this.siteCity = UserData.currentSite?.city;

		this.svcContact.reloadContact$
		.pipe(
			takeUntil(this.destroyed$),
			switchMap(() => {
				this.loadContact();
				return of(true);
			})
		)
		.subscribe();
		this.loadContact();
	}

	private loadContact() {
		this.svcContact.getContact(ContactsService.getContactIdFromUserId(UserData.current._id)).pipe(
			tap((contact: IContact) => {
				this.contact = contact;
				this.firstName = this.contact.firstName;
				this.lastName = this.contact.lastName;
				this.color = this.contact.avatarCouleur ?? 'CouleurPrimaire'
			})
		).subscribe();
	}

	public openMenu(): void{
			this.showPopover =true;
			this.svcDrawerPopover.open("","25%",this.menuUtilisateur?.nativeElement, MenuCompteUtilisateurComponent, {}, ()=>this.showPopover = false)
	}

  public closeMenu(): void{
    this.showPopover = false;
    this.svcDrawerPopover.close();
  }

	public switchWorkspace(): void {
		this.svcSecurity.getUserCredentials().pipe(
			switchMap((credentials: Credentials) => this.svcSecurity.getLocalUserDocument(credentials.login)),
			tap((user: IUser) => {
				this.svcStore.deleteWorkspaceDatabases();
				UserData.current.workspaceInfos = user.workspaceInfos;
				this.svcAuthenticator.setAuthenticationStatus(false);
				this.svcFlag.setFlagValue(ESecurityFlag.authenticated, false);
				this.svcFlag.setFlagValue(EPermissionsFlag.isLoaded, false);
			}),
			switchMap(() => from(this.ioNavController.navigateRoot("/selectionWorkspace")))
		).subscribe()
	}
}
