import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-lighting-up-angular',
  template: ' <p>lighting-up-angular works!</p> ',
  styles: [],
})
export class LightingUpAngularComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
