import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { ISeance } from '../../../../../model/ISeance';
import { Traitement } from '../../../../../model/Traitement';
import { IPatient } from '../../../../../modules/patients/model/IPatient';
import { StoredSeance } from '../../../../models/StoredSeance';
import { PanneauService } from '../../services/panneau.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { DeviceService } from '../../services/device.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { FacturationSvComponent } from '../../../facturation/components/facturation-sv/facturation-sv.component';
import { IAMO } from 'apps/idl/src/modules/patients/model/iamo';
import { IAMC } from 'apps/idl/src/modules/patients/model/iamc';
import { AMCP } from 'apps/idl/src/modules/patients/model/amc-p';
import { Router } from '@angular/router';

@Component({
  selector: 'di-groupe-liste-seance',
  templateUrl: './groupe-liste-seances-component.html',
  styleUrls: ['./groupe-liste-seances-component.scss']
})
export class GroupeListeSeancesComponent extends DestroyableComponentBase implements OnInit, OnChanges {


  @Input() ordonnance: Traitement;
  @Input() patient: IPatient;
  @Input() seances?: StoredSeance[];
  @Input() facturationStart: boolean = false;
  @Input() isFacturable: boolean = false;
  @Input() seancesAFacturer: StoredSeance[] = [];
  @Input() amoCouverture: IAMO;
  @Input() amcCouverture: IAMC;
  @Input() amcPatient: AMCP;

  public groupedSeances: { date: string, seances: ISeance[] }[] = [];
  public isMobile: boolean = false;

  constructor(
    private svcPanneau: PanneauService,
    private svcDrawerPopover: DrawerPopoverService,
    private router: Router,
    private svcDevice: DeviceService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.groupSeancesByDate();

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seances']) {
      this.groupSeancesByDate();
    }
  }

  facturer() {
    this.openPanneauFacturation();
  }

  private openPanneauFacturation() {
    this.facturationStart = true;
    if (this.isMobile) {
      this.svcPanneau.close();
      this.svcDrawerPopover.open("Facturation Sesam-Vitale",
        "700px",
        null,
        FacturationSvComponent,
        {
          seancesAFacturer: this.seancesAFacturer,
          seances: this.seances,
          ordonnance: this.ordonnance,
          patient: this.patient,
          couvertureAMO: this.amoCouverture,
          couvertureAMC: this.amcCouverture,
          couvertureAMCPatient: this.amcPatient
        })
    } else {
      this.svcPanneau.open("Facturation Sesam-Vitale", FacturationSvComponent, {
        seancesAFacturer: this.seancesAFacturer,
        seances: this.seances,
        ordonnance: this.ordonnance,
        patient: this.patient,
        couvertureAMO: this.amoCouverture,
        couvertureAMC: this.amcCouverture,
        couvertureAMCPatient: this.amcPatient
      });
    }
  }

  onClickCancel() {
    this.router.navigate(["facturation"]);
  }


  private groupSeancesByDate(): void {
    const seancesByDate: { [key: string]: ISeance[] } = {};
    const today = new Date();


    this.seances.forEach(seance => {
      const dateKey = this.getFormattedDate(new Date(seance.startDate), today);
      if (!seancesByDate[dateKey]) {
        seancesByDate[dateKey] = [];
      }
      seancesByDate[dateKey].push(seance);
    });

    this.groupedSeances = [];
    this.groupedSeances = Object.keys(seancesByDate).map(date => ({
      date: date,
      seances: seancesByDate[date]
    }));
  }

  public getFormattedDate(date: Date, today: Date): string {

    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const isToday = DateHelper.compareTwoDates(dateOnly, todayOnly) === 0;

    return isToday
      ? `Aujourd'hui, ${date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`
      : date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }
}
