<div class="panneau-patient-amc__container">
	<div class="container__content">
		<form [formGroup]="amcForm" (ngSubmit)="handleSubmit($event)">
			<div class="content__form-inputs">
				<div class="form-inputs form-inputs__bg-white">
					<div class="form-inputs__cpam">
						<lua-input [fullWidth]="true" placeholder="Mutuelle" label="Mutuelle" formControlName="mutuelle"
							(valueChange)="onInputChange($event)" (click)="onInputClick()" (focusin)="onInputClick()"
							(keydown)="onInputClick()"></lua-input>
						<div *ngIf="showOptions" class="cpam__options">
							<div *ngFor="let option of filteredMutuelle" (click)="onOptionClick(option)" class="options__item">
								<span>{{ option.label }}</span>
								<span>{{ getFormatedId(option)}}</span>
							</div>
						</div>
					</div>
					<lua-switch (clickSwitch)="toggleTierPayant($event)" label="Pratiquer le tiers payant AMC"
						[isChecked]="tierPayant"></lua-switch>
					<lua-input [fullWidth]="true" placeholder="Numéro adhérent" label="Numéro adhérent"
						formControlName="numeroAdherent"></lua-input>
					<lua-switch (clickSwitch)="toggleGestionUnique($event)" label="Gestion unique"
						[isChecked]="gestionUnique"></lua-switch>
					<lua-input *ngIf="gestionUnique" [fullWidth]="true" placeholder="Mutnum" label="Mutnum"
						formControlName="mutnum"></lua-input>
					<lua-select label="Pièce justificative" [options]="piecesJustificatives" formControlName="pieceJustificative"
						class="mb-10">
					</lua-select>
					<h2 class="content__subtitle">Période de couverture</h2>
					<lua-input [fullWidth]="true" placeholder="Début de couverture" label="Début de couverture" type="date"
						formControlName="dateDebut"></lua-input>
					<lua-input [fullWidth]="true" placeholder="Fin de couverture" label="Fin de couverture" type="date"
						formControlName="dateFin"></lua-input>
					<h2>Scan carte mutuelle</h2>
					<ng-container *ngFor="let image of this.listDoc">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteOldFile($event)"></di-carte-photo>
					</ng-container>
					<ng-container *ngFor="let image of this.listNewFile">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteNewFile($event)"></di-carte-photo>
					</ng-container>
					<lua-alerte *ngIf="isErrorPhoto" [filled]="true" [fullWidth]="true" type="error" iconName="info"
						[message]="errorMessage"></lua-alerte>
					<di-input-file *ngIf="listNewFile.length == 0 && listDoc.length == 0"
						(inputClick)="addAttestation($event)"></di-input-file>
				</div>
			</div>
			<div class="content__form-buttons">
				<div class="form-buttons__buttons">
					<div #selectConvention>
						<lua-alerte *ngIf="isError" [filled]="true" type="error" [message]="errorMessage"></lua-alerte>
						<lua-action-principale libelle="Enregistrer" [fullWidth]="true" (click)="handleSubmit($event)"
							[isDisabled]="amcForm.invalid"></lua-action-principale>
					</div>
					<lua-bouton-texte [fullWidth]="true" libelle="Annuler" (click)="goBack()"></lua-bouton-texte>
				</div>
			</div>
			<div class="content__footer">
				<div class="footer__container">
					<lua-divider-horizontal></lua-divider-horizontal>
					<p>{{ creationDateMessage }}</p>
					<lua-bouton-texte *ngIf="couverture" [fullWidth]="true" color="Error" libelle="Supprimer" startIcon="delete"
						(click)="deleteAmc($event)"></lua-bouton-texte>
				</div>
			</div>
		</form>
	</div>
</div>