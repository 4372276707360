import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateHelper } from '@osapp/helpers';
import { IContact } from '@osapp/model';
import { IRetrocession } from 'apps/idl/src/modules/patients/model/IRetrocession';

@Component({
	selector: 'di-list-retrocessions',
	templateUrl: './list-retrocessions.component.html',
	styleUrls: ['./list-retrocessions.component.scss'],
})
export class ListRetrocessionsComponent implements OnInit, OnChanges {
	@Input() public retrocessions: IRetrocession[];
	@Input() public utilisateurs: IContact[];
	@Input() public isMobile: boolean = false;
	@Output() public onSelectRetrocession = new EventEmitter<IRetrocession>();
	@Output() public onCreateRetrocession = new EventEmitter<void>();

	@Input() public selectedRetrocessionId: string = "";
	public years: Map<number, Map<number, { dateSeparateur: string, retrocessions: IRetrocession[] }>> = new Map();
	public yearsArray: {
		year: number;
		months: {
			month: number;
			dateSeparateur: string;
			retrocessions: IRetrocession[];
		}[];
	}[];



	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.sortRetrocessions();
	}

	private sortRetrocessions(): void {
		this.years = new Map();
		this.retrocessions.forEach((retrocession: IRetrocession) => {
			const dateFin: Date = new Date(retrocession.dateFin);
			const year: number = dateFin.getFullYear();
			const month: number = dateFin.getMonth();
			if (!this.years.has(year)) {
				this.years.set(year, new Map());
			}
			const yearMap = this.years.get(year);
			const dateSeparateur = `${DateHelper.C_MONTH_NAMES[month]} ${year}`;
			let retrocessions: IRetrocession[] = [];
			if (yearMap.has(month)) {
				retrocessions = yearMap.get(month).retrocessions;
			}
			retrocessions.push(retrocession);
			retrocessions.sort((a, b) => new Date(b.dateCreation).getTime() - new Date(a.dateCreation).getTime());
			yearMap?.set(month, { dateSeparateur, retrocessions });
		});
		this.yearsArray = Array.from(this.years.entries()).map(([year, monthsMap]) => ({
			year,
			months: Array.from(monthsMap.entries()).map(([month, data]) => ({
				month,
				...data
			})).sort((a, b) => b.month - a.month),
		})).sort((a, b) => b.year - a.year);
	}


	public wrappedOnRetrocessionSelected = (event: { retrocession: IRetrocession }) => {
		this.selectedRetrocessionId = event.retrocession._id;
		this.onSelectRetrocession.emit(event.retrocession);
	}

	public navigateToCreateRetrocession(): void {
		this.onCreateRetrocession.emit();
	}
}

