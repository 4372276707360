import { Exclude, Expose } from 'class-transformer';
import { EMoments } from '../../../../../../apps/idl/src/anakin/features/shared/enums/EMoments';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { OsappError } from '../../errors/model/OsappError';
import { ResolveModel } from '../../utils/models/decorators/resolve-model.decorator';
import { DurationTypeError } from '../errors/DurationTypeError';
import { DayRepetition } from './day-repetition';
import { EDuree } from './eduree';
import { ERepetition } from './erepetition';
import { C_HOURS_MINUTES_REPETITION_TYPE, HoursMinutesRepetition } from './hours-minutes-repetition';
import { IDayRepetition } from './iday-repetition';
import { IRecurrence } from './irecurrence';

export class Recurrence implements IRecurrence {

	//#region FIELDS

	/** Heure de début de journée par défaut. */
	private static readonly C_DEFAULT_START_HOUR = 8;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(DayRepetition)
	public dayRepetitions: DayRepetition[] = [];

	@Exclude()
	private meDurationType: EDuree;
	/** @implements */
	@Expose()
	public get durationType(): EDuree {
		return this.meDurationType;
	}
	public set durationType(peDurationType: EDuree) {
		if (peDurationType !== this.meDurationType)
			this.meDurationType = peDurationType;
	}

	@Exclude()
	private moDurationValue: number | Date[];
	/** @implements */
	@Expose()
	public get durationValue(): number | Date[] {
		return this.moDurationValue;
	}
	public set durationValue(poDurationValue: number | Date[]) {
		if (poDurationValue !== this.moDurationValue)
			this.moDurationValue = poDurationValue;
	}

	@Exclude()
	private meRepetitionType: ERepetition;
	/** @implements */
	@Expose()
	public get repetitionType(): ERepetition {
		return this.meRepetitionType;
	}
	public set repetitionType(peRepetitionType: ERepetition) {
		if (peRepetitionType !== this.meRepetitionType)
			this.meRepetitionType = peRepetitionType;
	}

	@Exclude()
	private moRepetitionValue: number | number[];
	/** @implements */
	@Expose()
	public get repetitionValue(): number | number[] {
		return this.moRepetitionValue;
	}
	public set repetitionValue(poRepetitionValue: number | number[]) {
		if (poRepetitionValue !== this.moRepetitionValue) {
			if (poRepetitionValue instanceof Array)
				this.moRepetitionValue = poRepetitionValue.sort((pnA: number, pnB: number) => pnA === 0 ? 1 : pnB === 0 ? -1 : pnA - pnB); // Le 0 (dimanche) doit être à la fin
			else
				this.moRepetitionValue = poRepetitionValue;
		}
	}

	/** @implements */
	public from?: Date;
	/** @implements */
	public to?: Date;
	/** @implements */
	public sundayAndPublicHolidays?: boolean;

	//#endregion

	//#region METHODS

	constructor(poData?: IRecurrence) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	public generateDates(
		pdDefaultDate: Date,
		pnEveryNWeeks?: number,
		pfOnDateAdded?: (pdDate: Date, pnIndex: number, pbLocked?: boolean) => void
	): Date[] {
		let loDates = new Map<number, Date[]>();
		const ldFrom = new Date(this.from ?? pdDefaultDate);
		const lnDurationDays: number = this.getDurationDays(ldFrom);
		if (Array.isArray(this.durationValue) && this.durationType != EDuree.jusquADate) {
			let index = 0;
			for (const date of this.durationValue) {
				const ldDate: Date = date instanceof Date ? date : new Date(date);
				if (!ArrayHelper.hasElements(this.dayRepetitions)) {
					this.dayRepetitions.push(new HoursMinutesRepetition(
						{
							hours: 8,
							minutes: 0
						}));
				}
				this.createWeekPattern(ldDate, loDates, index, lnDurationDays, pfOnDateAdded ?
					(pdDate: Date, index: number, pbLocked?: boolean) => {
						if (this.repetitionType === ERepetition.xFoisParMois) {
							lnMonthDiff = DateHelper.diffCalendarMonths(ldFrom, pdDate);
							const laMonthDates: Date[] = loDatesByMonth.get(lnMonthDiff) ?? [];
							laMonthDates.push(pdDate);
							loDatesByMonth.set(lnMonthDiff, laMonthDates);
						}
						pfOnDateAdded(pdDate, index++, pbLocked);
					} :
					undefined);
				index++;
			}
			return ArrayHelper.flat(MapHelper.valuesToArray(loDates));

		}

		let ldDate = new Date(ldFrom);
		let lnDayInterval: number = this.getRepetitionIntervalDay(ldDate);
		const loDatesByMonth = new Map<number, Date[]>();
		let lnMonthDiff = 0;
		let lnDateIndex = 0;

		// On boucle sans incrémenter le compteur de durée, on le fera par la suite.
		for (let lnDurationIndex = 0; lnDurationIndex < lnDurationDays; lnDurationIndex += lnDayInterval) {
			if (lnDurationIndex > 0) { // On ignore pour le premier tour de boucle car c'est la valeur initiale qui sera toujours bonne
				if (this.repetitionType === ERepetition.xFoisParMois)
					lnMonthDiff = DateHelper.diffCalendarMonths(ldFrom, ldDate);
				const ldDateToContinue: Date = this.continueWithDate(
					ldDate,
					ldFrom,
					pnEveryNWeeks,
					this.repetitionType === ERepetition.xFoisParMois ? (loDatesByMonth.get(lnMonthDiff) ?? []) : []
				);
				if (DateHelper.isDate(ldDateToContinue)) {
					const ldOldDate: Date = ldDate;
					ldDate = ldDateToContinue;
					lnDurationIndex += DateHelper.diffDays(ldDate, ldOldDate);
				}
			}

			if (lnDurationIndex >= lnDurationDays)
				break;

			if (this.sundayAndPublicHolidays === false) {
				while (DateHelper.isPublicHoliday(ldDate) || ldDate.getDay() === 0)
					ldDate = DateHelper.addDays(ldDate, 1);
			}

			ldDate.setHours(Recurrence.C_DEFAULT_START_HOUR); // Début d'une journée à 8h par défaut.

			this.createWeekPattern(ldDate, loDates, lnDurationIndex, lnDurationDays, pfOnDateAdded ?
				(pdDate: Date, index: number, pbLocked?: boolean) => {
					if (this.repetitionType === ERepetition.xFoisParMois) {
						lnMonthDiff = DateHelper.diffCalendarMonths(ldFrom, pdDate);
						const laMonthDates: Date[] = loDatesByMonth.get(lnMonthDiff) ?? [];
						laMonthDates.push(pdDate);
						loDatesByMonth.set(lnMonthDiff, laMonthDates);
					}

					pfOnDateAdded(pdDate, lnDateIndex++, pbLocked);
				} :
				undefined);

			lnDayInterval = this.getRepetitionIntervalDay(ldDate);

			ldDate = DateHelper.addDays(ldDate, lnDayInterval);
		}

		return ArrayHelper.flat(MapHelper.valuesToArray(loDates));
	}

	public continueWithDate(pdDate: Date, pdFrom: Date, pnEveryNWeeks: number, paMonthDates: Date[]): Date {
		let ldDate: Date;
		if (this.repetitionType === ERepetition.tsLesXMois && typeof this.repetitionValue === "number") {
			const lnDiffMonth: number = DateHelper.diffCalendarMonths(pdDate, pdFrom); // Si tous les x mois et même jour que le mois d'avant.
			const lnDelta: number = lnDiffMonth % this.repetitionValue;
			if (lnDelta !== 0 || pdFrom.getDate() !== pdDate.getDate())
				ldDate = DateHelper.addMonths(pdDate, this.repetitionValue - lnDelta);
		}
		else if (pnEveryNWeeks > 0) { // Si on doit sauter des semaines, on le fait avant de boucler.
			const lnDeltaWeek: number = DateHelper.diffCalendarWeeks(pdDate, pdFrom) % pnEveryNWeeks;
			if (lnDeltaWeek !== 0)
				ldDate = DateHelper.addWeeks(pdDate, pnEveryNWeeks - lnDeltaWeek);
		}
		else if (this.repetitionType === ERepetition.xFoisParMois && paMonthDates.length >= this.repetitionValue)
			ldDate = DateHelper.resetMonth(DateHelper.addMonths(pdDate, 1));
		return ldDate;
	}

	private getDurationDays(pdFrom: Date): number {
		switch (this.durationType) {
			case EDuree.jour:
				return this.durationValue as number;
			case EDuree.mois:
				return DateHelper.diffDays(DateHelper.addMonths(pdFrom, (this.durationValue as number)), pdFrom);
			case EDuree.dates:
				return (this.durationValue as Date[]).length;
			case EDuree.jusquADate:
				// +1 pour compter le jour cible
				return DateHelper.diffDays(this.durationValue[0], pdFrom) + 1;
			case EDuree.semaines:
				return this.durationValue as number * 7;
			default:
				throw new DurationTypeError(this.durationType);
		}
	}

	private createDayPattern(pdDate: Date, pfOnDateAdded?: (pdDate: Date, index?: number, pbLocked?: boolean) => void): Date[] {
		const laDates: Date[] = [];
		if (ArrayHelper.hasElements(this.dayRepetitions)) {
			// Création du pattern d'une journée, on boucle sur la répétition par jour.
			for (let lnRepetitionIndex = 0; lnRepetitionIndex < this.dayRepetitions.length; ++lnRepetitionIndex) {
				const loDayRepetition: DayRepetition = this.dayRepetitions[lnRepetitionIndex];
				const ldDate = lnRepetitionIndex > 0 ? new Date(pdDate) : pdDate; // Optimisation pour ne pas créer de date pour la  première répétition.
				let lbLocked: boolean;

				if (loDayRepetition.type === C_HOURS_MINUTES_REPETITION_TYPE) { // TODO Remplacer par instanceof lorsque le bug du ModelResolver dans jest sera corrigé.
					const loRangeRepetition: HoursMinutesRepetition = loDayRepetition as HoursMinutesRepetition;
					ldDate.setHours(loRangeRepetition.hours, loRangeRepetition.minutes);
					lbLocked = true;
				}

				laDates.push(ldDate);
				if (pfOnDateAdded)
					pfOnDateAdded(ldDate, 0, lbLocked); // 0 pour initialiser 
			}
		}
		else {
			if (pfOnDateAdded)
				pfOnDateAdded(pdDate);

			laDates.push(pdDate);
		}

		return laDates;
	}

	/** Donne une représentation en jour de l'intervalle de temps correspondant.
	 * @param pdDate
	 */
	private getRepetitionIntervalDay(pdDate: Date): number {
		if (this.repetitionValue instanceof Array)
			return DateHelper.diffDays(DateHelper.nextStartOfWeek(pdDate), pdDate) || 1;
		else if (this.repetitionType === ERepetition.tsLesXJours)
			return this.repetitionValue;
		else if (this.repetitionType === ERepetition.xFoisParMois)
			return Math.floor(DateHelper.getMonthAmountOfDays(pdDate) / this.repetitionValue);
		else
			return 1;
	}

	private createWeekPattern(
		pdFrom: Date,
		loDates: Map<number, Date[]>,
		pnInitialIndex: number,
		pnDurationDays: number,
		pfOnDateAdded?: (pdDate: Date, index: number, pbLocked?: boolean) => void
	): void {
		let lnDurationIndex: number = pnInitialIndex;

		if (this.repetitionValue instanceof Array) {
			if (!ArrayHelper.hasElements(this.repetitionValue))
				throw new OsappError("Aucune valeur de répétition renseignée.");

			const lnFromDay: number = pdFrom.getDay();
			if (lnFromDay === 0 && this.repetitionValue.includes(lnFromDay) && this.sundayAndPublicHolidays)  // Si dimanche, alors fin de semaine donc pas besion de boucler.
				loDates.set(lnDurationIndex, this.createDayPattern(pdFrom, pfOnDateAdded));
			else { // Sinon on rempli la semaine.
				for (let lnIndex = 0; lnIndex < this.repetitionValue.length; ++lnIndex) {
					if (lnIndex !== 0 && ++lnDurationIndex >= pnDurationDays)
						break;

					const lnDay: number = this.repetitionValue[lnIndex];

					if (lnDay === 0 || lnDay >= lnFromDay)  // On laisse un exception pour le dimanche.
						loDates.set(lnDurationIndex, this.createDayPattern(DateHelper.setWeekDay(pdFrom, lnDay), pfOnDateAdded));
				}
			}
		}
		else
			loDates.set(lnDurationIndex, this.createDayPattern(pdFrom, pfOnDateAdded));
	}

	getMoments(): EMoments[] {
		let momentsSet = new Set<EMoments>(); 
		this.dayRepetitions.forEach((repetition: IDayRepetition) => {
			if (repetition.type === "range") {
				let hour: number = repetition.from.hours;
				// Issue 2708: changement de l'heure du matin de 6h à 8h
				if (hour >= 6 && hour < 12) {
					momentsSet.add(EMoments.MATIN);
				}
				if (hour >= 12 && hour < 18) {
					momentsSet.add(EMoments.APRES_MIDI);
				}
				if (hour >= 18 && hour < 23) {
					momentsSet.add(EMoments.SOIR);
				}
				if (hour < 6 || hour >= 23) {
					momentsSet.add(EMoments.NUIT);
				}
			}
		});
		return Array.from(momentsSet);
	}

	static getRepetition(moment: EMoments): IDayRepetition {
		switch (moment) {
			case EMoments.MATIN:
				return {
					"type": "range",
					"from": {
						"hours": 8,
						"minutes": 0
					},
					"to": {
						"hours": 12,
						"minutes": 0
					}
				};
			case EMoments.APRES_MIDI:
				return {
					"type": "range",
					"from": {
						"hours": 12,
						"minutes": 0
					},
					"to": {
						"hours": 18,
						"minutes": 0
					}
				};
			case EMoments.SOIR:
				return {
					"type": "range",
					"from": {
						"hours": 18,
						"minutes": 0
					},
					"to": {
						"hours": 23,
						"minutes": 0
					}
				};
			case EMoments.NUIT:
				return {
					"type": "range",
					"from": {
						"hours": 23,
						"minutes": 0
					},
					"to": {
						"hours": 8,
						"minutes": 0
					}
				};
			default:
				throw new Error("Invalid moment for repetition");
		}
	}
	//#endregion
}
