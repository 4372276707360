<div class="di-menu-selection-infirmier">
	<div class="infirmier-list">
		<ng-container *ngIf="infirmiers">
			<div class="infirmier" *ngFor="let infirmier of infirmiers; let i = index">
        <lua-checkbox 
        [checked]="idsInfirmiersSelected.includes(infirmier._id)" 
        (checkedChange)="onSelection($event, infirmier)"
        ></lua-checkbox>
				<!-- <lua-objet-utilisateur 
          [firstName]="infirmier.firstName" 
          [lastName]="infirmier.lastName.toUpperCase()"
          [avatarColor]="infirmier.avatarCouleur" 
          [tags]="[remplacantTag]">
        </lua-objet-utilisateur> -->
				<lua-objet-utilisateur 
          [firstName]="infirmier.firstName" 
          [lastName]="infirmier.lastName.toUpperCase()"
          [avatarColor]="infirmier.avatarCouleur">
        </lua-objet-utilisateur>
			</div>
		</ng-container>
	</div>
	<lua-action-secondaire 
    libelle="Afficher" 
    size="large"
    [fullWidth]="false" 
    (click)="afficher()">
  </lua-action-secondaire>
</div>