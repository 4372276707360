<div class="lua-stepper">
  <div *ngFor="let step of steps; let index = index" class="step-container">
    <div
      *ngIf="index > 0"
      class="step-divider"
      [ngClass]="dividerClasses[index].left"
    ></div>
    <div
      class="step"
      [ngClass]="stepClasses[index]"
      (click)="cliquable ? handleStepClick(index) : null"
    >
      <strong class="step-icon">{{ index + 1 }}</strong>
      <small class="step-label">{{ step.name }}</small>
    </div>
    <div
      *ngIf="index < steps.length - 1"
      class="step-divider"
      [ngClass]="dividerClasses[index].right"
    ></div>
  </div>
</div>
<ng-container *ngIf="withContent">
  <ng-container
    *ngTemplateOutlet="steps[currentStep]?.content || null"
  ></ng-container>
</ng-container>
