import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { C_HOURS_MINUTES_REPETITION_TYPE } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IDayRepetition } from '@osapp/modules/event-markers/models/iday-repetition';
import { C_RANGE_REPETITION_TYPE } from '@osapp/modules/event-markers/models/range-repetition';
import { Recurrence } from '@osapp/modules/event-markers/models/recurrence';
import { Evenement } from 'apps/idl/src/anakin/models/Evenement';
import { StoredSeance } from 'apps/idl/src/anakin/models/StoredSeance';
import { moments } from "apps/idl/src/anakin/shared/moments.constants";
import { EStatusSeance } from 'apps/idl/src/model/EStatusSeance';
import { EEtatActe } from '../../../shared/enums/EEtatActe';
import { EMoments } from '../../../shared/enums/EMoments';
import { DrawerPopoverService } from '../../../shared/services/drawer-popover.service';
import { MenuGroupeJourneeComponent } from '../menu-groupe-journee/menu-groupe-journee.component';

@Component({
  selector: 'di-liste-seance-tournees',
  templateUrl: './liste-seance-tournees.component.html',
  styleUrls: ['./liste-seance-tournees.component.scss'],
})
export class ListeSeanceTourneesComponent implements OnInit, OnChanges {
  @Input() date: Date = new Date();
  @Input() seances: StoredSeance[] = [];
  @Input() events: Evenement[] = [];

  public sortedSeances: { [value: string]: { type: 'seance' | 'event', data: StoredSeance | Evenement }[] } = {};

  public nbSoinRealise: number = 0;
  public totalMtRealises: number = 0;
  public labelSoinRealise: string = "Aucun soin réalisé";

  public moments;
  public momentSeances: StoredSeance[];

  constructor(
    private svcDrawerPopover: DrawerPopoverService
  ) { }

  ngOnInit() {
    // Initialisation au démarrage du composant
    this.initMoments();
    this.sortSeancesByTimeOfDay();
    this.getSoinRealise();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Appelé chaque fois qu'une des propriétés @Input change
    if (changes['seances'] || changes['events']) {
      this.sortSeancesByTimeOfDay();
      this.getSoinRealise();
    }
  }

  private initMoments(){
    this.moments = moments.map((moment) => ({
      ...moment,
      labelNoData: this.getLabelNoData(moment.value)
    }))
  }

  private getLabelNoData(moment: string): string {
    switch (moment) {
      case EMoments.MATIN:
        return "Aucune séance n'est prévue le matin";
      case EMoments.APRES_MIDI:
        return "Aucune séance n'est prévue l'après-midi";
      case EMoments.SOIR:
        return "Aucune séance n'est prévue le soir";
      case EMoments.NUIT:
        return "Aucune séance n'est prévue la nuit";
      default:
        return "Aucune séance n'est prévue";
    }
  }

  private sortSeancesByTimeOfDay() {
    this.sortedSeances = {}; // Reset the sorted data

    // Sort Seances
    this.seances.forEach((seance: StoredSeance) => {
      const moment = this.determineMomentTournee(new Date(seance.startDate));
      if (!this.sortedSeances[moment]) {
        this.sortedSeances[moment] = [];
      }
      this.sortedSeances[moment].push({ type: 'seance', data: seance });
    });

    // Sort Events
    this.events.forEach((event: Evenement) => {
      let moment;

      // Check if the moment type is 'hours-minutes'
      if (event.moment?.type === 'hours-minutes') {

        const hour = event.moment?.hours;

        // Determine the moment based on the hour
        switch (true) {
					case (hour >= 0 && hour < 12):
            moment = EMoments.MATIN;
            break;
          case (hour >= 12 && hour < 18):
            moment = EMoments.APRES_MIDI;
            break;
          case (hour >= 18 && hour < 23):
            moment = EMoments.SOIR;
            break;
					case (hour >= 23):
							moment = EMoments.NUIT;
            break;
          default:
            throw new Error("Invalid time range for moment");
        }
      } else {
        // Assuming the moment determination logic is the same for other cases
        moment = this.determineMomentTournee(event.moment);
      }

      // Push the event data into the sortedSeances array
      if (!this.sortedSeances[moment]) {
        this.sortedSeances[moment] = [];
      }
      this.sortedSeances[moment].push({ type: 'event', data: event });
    });


  }

	private determineMomentTournee(input: IDayRepetition | Date): EMoments {
		let hour: number;
		let isRange: boolean = false;
		if (input instanceof Date) {
			hour = input.getHours();
		} else if (input.type === C_RANGE_REPETITION_TYPE) {
			hour = input.from.hours;
			isRange = true;
		} else {
			if (input.type === C_HOURS_MINUTES_REPETITION_TYPE) {
				hour = input.hours;
			} else {
				throw new Error("Invalid input to determine moment");
			}
		}
	
		switch (true) {
			case (hour >= 0 && hour < 12):
				return EMoments.MATIN;
			case (hour >= 12 && hour < 18):
				return EMoments.APRES_MIDI;
			case (hour >= 18 && hour < 23):
				return EMoments.SOIR;
			case (hour >= 23):
				return EMoments.NUIT;
			default:
				throw new Error("Invalid time range for moment");
		}
	}

  private getSoinRealise() {
    let seancesRealisees: StoredSeance[] = this.seances.filter(x => x.status === EStatusSeance.done);
    this.nbSoinRealise = seancesRealisees?.length;
    this.getMontantSoinRealise(seancesRealisees);
    this.getLabelSoinRealise();
  }

  private getMontantSoinRealise(seancesRealisees: StoredSeance[]) {
    this.totalMtRealises = seancesRealisees.reduce((total, seance) => {
      let sommeActes = seance.actes?.reduce((total, acte) => {
        // Seuls les actes réalisés sont pris en compte pour le montant
        if (acte.etat === EEtatActe.done)
          return total + acte.price
        else
          return total
      }, 0) || 0;
      let sommeMajorations = seance.majorations?.reduce((total, majoration) => total + majoration.price, 0) || 0;
      let sommeIndemnites = seance.indemnites?.reduce((total, indemnite) => total + indemnite.price, 0) || 0;

      return total + sommeActes + sommeMajorations + sommeIndemnites;
    }, 0);
  }

  private getLabelSoinRealise() {
    if (this.nbSoinRealise === 0) {
      this.labelSoinRealise = "Aucun soin realisé";
    } else if (this.nbSoinRealise === 1) {
      this.labelSoinRealise = "1 soin realisé";
    } else {
      this.labelSoinRealise = `${this.nbSoinRealise} soins realisés`;
    }
  }

  getSeancesByMoment(moment: EMoments) {
    const repetition = Recurrence.getRepetition(moment);
    this.momentSeances = this.seances.filter((seance) => {
      const hour = new Date(seance.startDate).getHours();
      if (repetition.from.hours < repetition.to.hours) {
        return hour >= repetition.from.hours && hour < repetition.to.hours;
      } else {
        return hour >= repetition.from.hours || hour < repetition.to.hours;
      }
    });
  }

  handleClickMomentMenu(event: MouseEvent, momentLabel: string) {
    const momentEnum = Object.values(EMoments).find(value => value === momentLabel);
    this.getSeancesByMoment(momentEnum);
    this.svcDrawerPopover.open(
      momentEnum,
      "30%",
      event.currentTarget,
      MenuGroupeJourneeComponent,
      {
        momentLabel: momentEnum,
				date : this.date,
        seances: this.momentSeances,
      }
    );
  }
}