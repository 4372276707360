import {Component, Input, OnInit} from '@angular/core';
import {ESize} from '../../enums/esize';

@Component({
  selector: 'lua-alerte',
  templateUrl: './alerte.component.html',
  styleUrls: ['./alerte.component.css'],
})
export class AlerteComponent implements OnInit {
  @Input() filled? = false;
  @Input() type: 'info' | 'success' | 'warning' | 'error' = 'info';
  @Input() message = '';
  @Input() iconName?: string;
  @Input() buttonText?: string;
  @Input() buttonIconName?: string;
  @Input() onClickAction?: Function;
  @Input() public fullWidth = true;

  public buttonColor = 'Info';
  public buttonSize = ESize.S;
  public buttonIconSize = ESize.M;
  public iconMap: {[key: string]: string} = {
    info: 'info',
    success: 'check_circle',
    warning: 'warning',
    error: 'error',
  };

  constructor() {}

  ngOnInit(): void {
    this.setButtonColor();
  }

  public setClasses() {
    return {
      filled: this.filled,
      'info-filled': this.filled && this.type === 'info',
      info: this.type === 'info' && !this.filled,
      'error-filled': this.filled && this.type === 'error',
      error: this.type === 'error' && !this.filled,
      'warning-filled': this.filled && this.type === 'warning',
      warning: this.type === 'warning' && !this.filled,
      'success-filled': this.filled && this.type === 'success',
      success: this.type === 'success' && !this.filled,
      'full-width': this.fullWidth,
    };
  }

  private setButtonColor(): void {
    if (this.filled) {
      this.buttonColor = 'Blanc';
    } else {
      switch (this.type) {
        case 'info':
          this.buttonColor = 'Info';
          break;
        case 'success':
          this.buttonColor = 'OK';
          break;
        case 'warning':
          this.buttonColor = 'Warning';
          break;
        case 'error':
          this.buttonColor = 'Error';
          break;
        default:
          this.buttonColor = 'Info';
          break;
      }
    }
  }

  public handleClick() {
    if (this.onClickAction) {
      this.onClickAction();
    }
  }
}
