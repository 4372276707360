import { Component, OnInit } from '@angular/core';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { EInvoiceType } from '../../../modules/facturation/models/einvoice-type.enum';
import { Invoice } from '../../../modules/facturation/models/invoice';
import { DeviceService } from '../../features/shared/services/device.service';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { LoaderService } from '../../features/shared/services/loader.service';
import { PanneauService } from '../../features/shared/services/panneau.service';
import { PanneauMenuMoisComponent } from './panneau-menu-mois/panneau-menu-mois.component';
import { forkJoin } from 'rxjs';
import { ContactsService } from '@osapp/services';
import { DrawerPopoverService } from '../../features/shared/services/drawer-popover.service';
import { FiltreInfirmiersComponent } from '../../features/facturation/components/filtres/filtre-infirmiers/filtre-infirmiers.component';
import { IContact } from '@osapp/model';
import { EEtatsReglement } from '../../features/shared/enums/EEtatsReglement';
import { FiltrePartPatientComponent } from '../../features/facturation/components/filtres/filtre-part-patient/filtre-part-patient.component';
import { EEtatsFacture } from '../../features/shared/enums/EEtatsFacture';
import { FiltreTiersPayantComponent } from '../../features/facturation/components/filtres/filtre-tiers-payant/filtre-tiers-payant.component';

@Component({
	selector: 'di-suivi-facturation',
	templateUrl: './suivi-facturation.page.html',
	styleUrls: ['./suivi-facturation.page.scss'],
})
export class SuiviFacturationPage extends DestroyableComponentBase implements OnInit {

	public libelleNbInvoices: string = "";
	public libelleNbInvoicesFiltered: string = "";
	public montantTotalInvoice: number = 0;
	public invoices: Invoice[] = [];
	public filteredInvoices: Invoice[] = [];

	public patientsMap: Map<string, IPatient> = new Map();
	public infirmiers: IContact[] = [];

	/* "ID" des filtres sélectionnés, permet de savoir lesquels sont sélectionnés ou non */
	public idsInfirmiersSelected: string[] = [];
	public etatsPPSelected: string[] = [];
	public etatsAMOSelected: string[] = [];
	public etatsAMCSelected: string[] = [];

	/* Permet d'afficher les libellés des filtres sélectionnés dans le lua-filtres */
	public filterInfirmiersSelected: string[] = [];
	public filterPPSelected: string[] = [];
	public filterAMOSelected: string[] = [];
	public filterAMCSelected: string[] = [];

	public etatsReglement = Object.values(EEtatsReglement);
	public etatsFacture = Object.values(EEtatsFacture);

	public filters = []
	public searchedValue: string = "";

	public isMobileView: boolean = false;
	public mainTextAucuneFacture: string = "Aucune facture trouvés";
	public subTextAucuneFacture = "Essayer de modifier votre recherche";


	public dateMonthDisplayed: string;
	public dateSelected: Date;

	public showMenu: boolean;

	constructor(
		private svcDevice: DeviceService,
		private svcFacturation: FacturationService,
		private svcPatient: PatientsService,
		private svcContact: ContactsService,
		private svcLoader: LoaderService,
		private svcPanneau: PanneauService,
		private svcDrawerPopover: DrawerPopoverService,
	) {
		super();
	}
	ngOnInit(): void {
		this.svcDevice.isMobile$.subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});

		this.svcFacturation.invoiceChanges$
			.pipe(
				takeUntil(this.destroyed$))
			.subscribe(() => {
				if (this.dateSelected) {
					const mois = this.dateSelected.getMonth();
					const annee = this.dateSelected.getFullYear();
					this.initListeFactures(mois, annee, true);
				}
			});

		const today = new Date();
		this.initListeFactures(today.getMonth(), today.getFullYear());
	}

	initFilter() {
		this.etatsPPSelected = [];
		this.etatsAMOSelected = [];
		this.etatsAMCSelected = [];
		this.idsInfirmiersSelected = [];
		this.searchedValue = "";
		this.filterAMOSelected = [];
		this.filterAMCSelected = [];
		this.filterPPSelected = [];
		this.filterInfirmiersSelected = [];
	}

	openMenuFiltrePartPatient(event: Event) {
		this.svcDrawerPopover.open(
			"",
			"",
			event.currentTarget,
			FiltrePartPatientComponent,
			{
				etatsSelected: this.etatsPPSelected,
				onFilter: this.selectionNewEtatPP
			}
		);
	}

	selectionNewEtatPP = (etats: string[]): void => {
		this.etatsPPSelected = etats;
		this.filterPPSelected = this.etatsReglement
			.filter(x => this.etatsPPSelected.includes(x.status))
			.map(x => `${x.labelTier}}`);

		this.svcDrawerPopover.close();
		this.applyFilter();
	}

	openMenuFiltreAmo(event: Event) {
		this.svcDrawerPopover.open(
			"",
			"",
			event.currentTarget,
			FiltreTiersPayantComponent,
			{
				etatsSelected: this.etatsAMOSelected,
				onFilter: this.selectionNewEtatAmo
			}
		);
	}

	selectionNewEtatAmo = (etats: string[]): void => {
		this.etatsAMOSelected = etats;
		this.filterAMOSelected = this.etatsFacture
			.filter(x => this.etatsAMOSelected.includes(x.status))
			.map(x => `${x.labelTier}}`);

		this.svcDrawerPopover.close();
		this.applyFilter();
	}

	openMenuFiltreAmc(event: Event) {
		this.svcDrawerPopover.open(
			"",
			"",
			event.currentTarget,
			FiltreTiersPayantComponent,
			{
				etatsSelected: this.etatsAMCSelected,
				onFilter: this.selectionNewEtatAmc
			}
		);
	}

	selectionNewEtatAmc = (etats: string[]): void => {
		this.etatsAMCSelected = etats;
		this.filterAMCSelected = this.etatsFacture
			.filter(x => this.etatsAMCSelected.includes(x.status))
			.map(x => `${x.labelTier}}`);

		this.svcDrawerPopover.close();
		this.applyFilter();
	}

	openMenuFiltreInfirmier(event: Event) {
		this.svcDrawerPopover.open(
			"",
			this.isMobileView ? "250px" : "",
			event.currentTarget,
			FiltreInfirmiersComponent,
			{
				infirmiers: this.infirmiers,
				idsInfirmiersSelected: this.idsInfirmiersSelected,
				onFilter: this.selectionNewInfirmiers
			}
		);
	}

	selectionNewInfirmiers = (idsInfirmiers: string[]): void => {
		this.idsInfirmiersSelected = idsInfirmiers;
		this.filterInfirmiersSelected = this.infirmiers
			.filter(x => this.idsInfirmiersSelected.includes(x._id))
			.map(x => {
				const firstLetter = x.firstName?.trimStart().charAt(0).toUpperCase() || "";
				return `${firstLetter}${x.firstName?.slice(1) || ""} ${x.lastName.toUpperCase()}`;
			});
		this.svcDrawerPopover.close();
		this.applyFilter();
	}

	public filterInvoicesByInfirmier() {
		this.filteredInvoices = this.filteredInvoices.filter(x => {
			return this.idsInfirmiersSelected.includes(x.intervenantId);
		});
	}

	public filterInvoicesByPP() {
		this.filteredInvoices = this.filteredInvoices.filter(x => {
			//Si jamais on ne trouve pas de status sur la part patient, c'est qu'une valeur par défaut a été mise,
			//il faut donc afficher la ligne de facture si on choisi dans la popup le filtre par défaut "a régler"
			//mais uniquement si on a une part patient
			return this.etatsPPSelected.includes(x.statusPat)
				|| (x.totalPartPatient && this.etatsPPSelected.includes(x.statusPat || 'aRegler'));
		});
	}

	public filterInvoicesByAmo() {
		this.filteredInvoices = this.filteredInvoices.filter(x => {
			//Si jamais on ne trouve pas de status sur la part AMO, c'est qu'une valeur par défaut a été mise,
			//il faut donc afficher la ligne de facture si on choisi dans la popup le filtre par défaut "a envoyer"
			//mais uniquement si on a une part AMO
			return this.etatsAMOSelected.includes(x.statusAMO)
				|| (x.totalPartAMO && this.etatsAMOSelected.includes(x.statusAMO || 'aEnvoyer'));
		});
	}

	public filterInvoicesByAmc() {
		this.filteredInvoices = this.filteredInvoices.filter(x => {
			//Si jamais on ne trouve pas de status sur la part AMC, c'est qu'une valeur par défaut a été mise,
			//il faut donc afficher la ligne de facture si on choisi dans la popup le filtre par défaut "a envoyer"
			//mais uniquement si on a une part AMC
			return this.etatsAMCSelected.includes(x.statusAMC)
				|| (x.totalPartAMC && this.etatsAMCSelected.includes(x.statusAMC || 'aEnvoyer'));
		});
	}

	public filterInvoicesByTerm() {
		const searchTerms = this.searchedValue.split(' ').filter(term => term);
		this.filteredInvoices = this.filteredInvoices.filter(invoice => {
			const patient = this.patientsMap.get(invoice.patientId);
			if (!patient) return false;

			const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
			const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
			const lastNameUsuel = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';


			const matchesOnlyFirstName = searchTerms.length === 1 &&
				searchTerms[0] && firstName.includes(searchTerms[0]);

			const matchesOnlyLastName = searchTerms.length === 1 &&
				searchTerms[0] && lastName.includes(searchTerms[0]);

			const matchesOnlyLastNameusuel = searchTerms.length === 1 &&
				searchTerms[0] && lastNameUsuel.includes(searchTerms[0]);

			const matchesFirstLast = searchTerms.length === 2 &&
				firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

			const matchesLastFirst = searchTerms.length === 2 &&
				lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

			const matchesFirstLastUsuel = searchTerms.length === 2 &&
				firstName.includes(searchTerms[0]) && lastNameUsuel.includes(searchTerms[1]);

			const matchesLastusuelFirst = searchTerms.length === 2 &&
				lastNameUsuel.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

			const matchesInvoiceNumber = invoice.invoiceNumber?.startsWith(this.searchedValue);
			const matchesFSENumber = invoice.securisationData?.ref.startsWith(this.searchedValue);

			return matchesOnlyFirstName || matchesOnlyLastName || matchesOnlyLastNameusuel || matchesFirstLastUsuel || matchesLastusuelFirst || matchesFirstLast || matchesLastFirst || matchesInvoiceNumber || matchesFSENumber;
		});
	}

	public searchInvoicesByTerm(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
		this.applyFilter();
	}

	private applyFilter() {
		this.filteredInvoices = this.invoices;

		if (this.idsInfirmiersSelected?.length > 0) {
			this.filterInvoicesByInfirmier();
		}

		if (this.etatsAMOSelected?.length > 0) {
			this.filterInvoicesByAmo();
		}

		if (this.etatsAMCSelected?.length > 0) {
			this.filterInvoicesByAmc();
		}

		if (this.etatsPPSelected?.length > 0) {
			this.filterInvoicesByPP();
		}

		if (this.searchedValue?.length >= 2) {
			this.filterInvoicesByTerm();
		}

		this.getLibelleNbInvoice();
		this.getMontantTotalInvoice();
	}

	public initListeFactures = (mois: number, annee: number, keepFilter: boolean = false) => {
		this.dateSelected = new Date(annee, mois);
		this.dateMonthDisplayed = this.dateSelected.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
		this.svcLoader.showLoader("Chargement des factures...");
		this.svcFacturation.getInvoicesByMonth(mois, annee)
			.pipe(
				tap((invoices: Invoice[]) => {
					this.invoices = invoices;
				}),
				switchMap(() => {
					let patientIdsSet: Set<string> = new Set();
					let contactIdsSet: Set<string> = new Set();

					this.invoices.forEach(inv => {
						if (inv.patientId) {
							patientIdsSet.add(inv.patientId);
						}

						// Récupération de l'intervenantId de chaque invoice
						if (inv.intervenantId) {
							contactIdsSet.add(inv.intervenantId);
						}
					});

					let patientIds = Array.from(patientIdsSet).filter(id => id);
					let contactIdsTab = Array.from(contactIdsSet).filter(id => id);

					// Appels en parallèles pour récupérer les patients et les contacts
					return forkJoin({
						patients: this.svcPatient.getPatientsByIds(patientIds),
						infirmiers: this.svcContact.getContactsByIds(contactIdsTab)
					});
				}),
				tap(({ patients, infirmiers }) => {
					this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));

					// Mappage des infirmiers pour accéder rapidement via leur ID
					this.infirmiers = infirmiers;
					const infirmiersMap = new Map(infirmiers.map(inf => [inf._id, inf]));

					// Assignation des patients et auteurs
					this.invoices.forEach(invoice => {
						invoice.infirmier = infirmiersMap.get(invoice.intervenantId);
						invoice.patient = this.patientsMap.get(invoice.patientId);
					});

					this.filteredInvoices = this.invoices;
					this.getLibelleNbInvoice();
					this.getMontantTotalInvoice();
					if (keepFilter) {
						//On veut garder les filtres (par exemple lors de la mise à jour de la liste après une action)
						this.applyFilter();
					} else {
						//On reset les filtres si on change de mois
						this.initFilter();
					}
					this.svcLoader.hideLoader();
				}),
				tap(() => {
					if (this.isMobileView) {
						this.svcPanneau.close();
					}
				})
			)
			.subscribe();
	}

	public getLibelleNbInvoice() {
		this.libelleNbInvoices = (this.filteredInvoices?.length || 0) + ' facture' + (this.filteredInvoices.length > 1 ? 's' : '');
	}

	public getMontantTotalInvoice() {
		this.montantTotalInvoice = this.filteredInvoices.reduce((totalInvoice, invoice) => {
			// Addition des part AMO/AMC/PP de chaque acte dans la facture
			// const totalActes = invoice.actes.reduce((totalActe, acte) => {
			// 	return totalActe + (acte.honoraires || 0); // Sécurité si honoraires est absent
			// }, 0);
			const totalActes = invoice.totalPartAMC + invoice.totalPartAMO + invoice.totalPartPatient;

			if (invoice.invoiceType === EInvoiceType.avoir) {
				return totalInvoice - totalActes;
			} else {
				return totalInvoice + totalActes;
			}
		}, 0);
	}

	public openPanneauFactureMois() {
		const panneauTitle = "Suivi des factures";
		const panneauContent = PanneauMenuMoisComponent;
		const panneauInputs = {
			onMonthSelected: this.initListeFactures
		};
		this.svcPanneau.open(
			panneauTitle,
			panneauContent,
			panneauInputs
		);
	}
}

