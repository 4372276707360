<div class="di-filtre-tiers-payant">
  <div class="liste-etats">
    <ng-container *ngFor="let etat of etatsFacture">
      <div class="etat">
        <lua-checkbox 
        [label]="etat.labelTier"
        [checked]="etatsSelected.includes(etat.status)"
        (checkedChange)="onSelection($event, etat.status)">
      </lua-checkbox>
        <di-tag-suivi-facture [etat]="etat.status"></di-tag-suivi-facture>
      </div>
    </ng-container>
  </div>
  <lua-action-secondaire libelle="Afficher" size="large" [fullWidth]="false" (click)="afficher()"></lua-action-secondaire>
</div>
