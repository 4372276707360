<div class="lua-tableau-entete" (click)="avecTri && handleEnteteClick(entete)">
  <div class="entete" [ngClass]="{ triee: triee, 'entete-right': position === 'right', 'entete-center': position === 'center', 'entete-left': position === 'left' }">{{ entete }}</div>
  <div *ngIf="avecTri" class="tri-icone">
    <lua-icone
      *ngIf="triee"
      [iconName]="ordreCroissant ? 'arrow_upward' : 'arrow_downward'"
      color="Indigo"
      fontSize="15px"
    ></lua-icone>
  </div>
</div>
