import {Component, Input, OnInit} from '@angular/core';

interface Tags {
  tagLabel: string;
  tagColor: string;
  tagMode: 'filled' | 'outlined';
}
@Component({
  selector: 'lua-objet-utilisateur',
  templateUrl: './objet-utilisateur.component.html',
  styleUrls: ['./objet-utilisateur.component.css'],
})
export class ObjetUtilisateurComponent implements OnInit {
  public classes: {[key: string]: boolean} = {};
  constructor() {}

  ngOnInit(): void {
    if (!this.avatarColor) {
      this.avatarColor = 'CouleurPrimaire';
    }
    this.updateClasses();
  }

  @Input() firstName = '';

  @Input() lastName = '';

  @Input() info? = '';

  @Input() avatarColor = 'CouleurPrimaire';

  @Input() tags: Tags[] = [];

  @Input() fullWidth = false;

  private updateClasses() {
    this.classes = {
      'full-width': this.fullWidth,
    };
  }

  getClasses() {
    return this.classes;
  }
}
