import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'lua-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() options: {
    value: string;
    label: string;
  }[] = [];
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() fullWidth = false;

  @Input() value = '';
  onChange = (value: string) => {
    console.log(value);
  };
  onTouched = () => {};

  ngOnInit(): void {}

  // ControlValueAccessor Methods
  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Event Handler for Selection Change
  onSelectionChange(event: MatSelectChange): void {
    this.value = event.value;
    this.onChange(this.value);
    this.onTouched();
  }

  // Helper Method for Class Binding
  getClasses(): {[key: string]: boolean} {
    return {
      'has-value': !!this.value,
      'full-width': this.fullWidth,
    };
  }
}
