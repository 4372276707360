import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'di-confirmation-annulation',
  templateUrl: './confirmation-annulation.component.html',
  styleUrls: ['./confirmation-annulation.component.scss'],
})
export class ConfirmationAnnulationComponent implements OnInit {

  constructor() { }

  ngOnInit() { }


  @Input() confirm: () => void;
  @Input() cancel: () => void;

  onConfirm() {
    if (this.confirm) {
      this.confirm();
    }
  }

  onCancel() {
    if (this.cancel) {
      this.cancel();
    }
  }
}
