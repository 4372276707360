<div class="menu-options-retrocession">
		<lua-list-item 
      class="list-item" 
      text="Modifier les critères de rétrocession" 
      iconColor="CouleurPrimaire" 
      iconName="edit"
			(click)="handleEditClick($event)">
    </lua-list-item>
		<lua-list-item 
      class="list-item" 
      text="Télécharger la facture de rétrocession" 
      iconName="download" 
      (click)="handleDownloadClick($event)"
      iconColor="CouleurPrimaire">
    </lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item 
      class="list-item" 
      text="Supprimer la rétrocession" 
      iconColor="Error" 
      iconName="delete"
        (click)="handleDeleteClick($event)">
    </lua-list-item>
</div>