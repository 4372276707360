import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'lua-input-filled',
  templateUrl: './input-filled.component.html',
  styleUrls: ['./input-filled.component.css'],
})
export class InputFilledComponent implements OnInit {
  @Input()
  public value = '';

  @Input()
  public libelle = '';

  @Input()
  public type = 'texte';

  @Input()
  public color = 'Indigo';

  @Input()
  public iconName = '';

  @Input()
  public fullWidth = true;

  @Input()
  public isError = false;

  @Input()
  public isAutoFocus = false;
  @Input() isMultiline = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClickIcon: EventEmitter<Event> = new EventEmitter<Event>();

  public buttonColor = 'CouleurPrimaire';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.isError) {
      this.buttonColor = 'Error';
    }
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }

  onChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.valueChange.emit(this.value);
  }

  onClikIcon(event: Event): void {
    this.onClickIcon.emit(event);
  }

  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      isError: this.isError,
      multiline: this.isMultiline,
    };
  }
}
