import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-separateur-groupe',
  templateUrl: './separateur-groupe.component.html',
  styleUrls: ['./separateur-groupe.component.css'],
})
export class SeparateurGroupeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.labelSeparateur = this.letter
      ? this.label.charAt(0).toUpperCase()
      : this.label;
  }

  @Input() letter = true;
  @Input() label = '';
  @Input() iconName?: string;
  @Input() onClickIcon?: () => void;

  public labelSeparateur = '';
}
