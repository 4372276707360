import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { CameraSource, ImageOptions } from '@capacitor/camera';
import { CameraService } from '@osapp/modules/camera/services/camera.service';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { takeUntil, tap } from 'rxjs/operators';
import { DeviceService } from '../../services/device.service';

@Component({
	selector: 'di-input-file',
	templateUrl: './input-file.component.html',
	styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent extends DestroyableComponentBase implements OnInit {
	@ViewChild('fileInput', { static: true }) fileInput!: ElementRef;

	public cameraOptions: ImageOptions;
	public isMobile: boolean = false;
	private readonly moGuidBuilder = new LongGuidBuilder();

	constructor(
		private svcCamera: CameraService,
		private svcDevice: DeviceService
	) {
		super()
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		this.cameraOptions = this.svcCamera.getAndSetCameraOptions(this.cameraOptions);

	}

	@Input() title: string = '';
	@Input() isPhoto?: boolean = false;
	@Output() inputClick = new EventEmitter<File>();

	public onAddFile(event: Event) {
		event.stopPropagation();
		if (this.isMobile) {
			this.cameraOptions.source = CameraSource.Photos;
			this.svcCamera.getPicture(this.cameraOptions).pipe(
				tap((file: File) => {
					this.inputClick.emit(file)
				}
				),
				takeUntil(this.destroyed$),
			).subscribe();
		}
		else {
			this.fileInput.nativeElement.click();
		}
	}

	public takePicture(event: Event) {
		event.stopPropagation();
		this.cameraOptions.source = CameraSource.Camera;
		this.svcCamera.takePicture(this.cameraOptions).pipe(
			tap(
				(file: File) => {
					this.inputClick.emit(file)
				}
			),
			takeUntil(this.destroyed$),
		).subscribe()
	}

	public onFileSelected(event: Event) {
		const file = (event.target as HTMLInputElement).files?.[0];
		if (file) {
			const allowedExtensions = /(\.exe|\.bat|\.cmd|\.sh|\.bin|\.msi|\.dll)$/i;
			if (allowedExtensions.test(file.name)) {
				this.fileInput.nativeElement.value = '';
			} else {
				this.inputClick.emit(file)
			}
		}
	}
}
