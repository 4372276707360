import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
})
export class ListItemComponent implements OnInit {
  constructor(private el: ElementRef) {}

  @Input() isSelected = false;
  @Input() isDisabled = false;
  @Input() iconColor = '';
  @Input() iconMode: 'filled' | 'outlined' | '' = 'outlined';
  @Input() textColor = '';
  @Input() iconName = '';
  @Input() text = '';
  @Input() endText = '';
  @Input() endIconName = '';
  @Input() iconEndColor = '';
  @Input() titre = false;
  @Input() subtext?: string;

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-icon-color',
      `var(--${this.iconColor})`
    );
    this.el.nativeElement.style.setProperty(
      '--my-text-color',
      `var(--${this.textColor})`
    );
  }
}
