<div class="lua-objet-patient" [ngClass]="{ deceased: this.deceased }">
  <lua-icone
    *ngIf="showIcon()"
    [isIcon]="true"
    [customIconName]="customIconName"
    [fontSize]="iconFontSize"
    [width]="iconWidth"
    [height]="iconHeight"
    [color]="getIconColor()"
  ></lua-icone>
  <div class="text-container">
    <div class="first-line">
      <strong class="name">{{
        (lastName | uppercase) + ' ' + firstName
      }}</strong>
      <lua-tag *ngIf="deceased" [mode]="'outlined'" label="Décédé"></lua-tag>
    </div>
    <small>{{ info }}</small>
  </div>
</div>
