import {Component, Input, OnInit, ElementRef} from '@angular/core';

@Component({
  selector: 'lua-papier',
  templateUrl: './papier.component.html',
  styleUrls: ['./papier.component.css'],
})
export class PapierComponent implements OnInit {
  @Input()
  mode: 'filled' | 'outlined' = 'filled';

  @Input() fullWidth = true;
  @Input() selected = false;
  @Input() color = 'Indigo';
  @Input() selectionnable = false;
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty(
      '--my-color',
      `var(--${this.color})`
    );
  }
  getClasses(): {[key: string]: boolean} {
    return {
      'full-width': this.fullWidth,
      filled: this.mode === 'filled',
      outlined: this.mode === 'outlined',
      selected: this.selected,
      selectionnable: this.selectionnable,
    };
  }
}
