<div class="lua-select">
  <mat-form-field 
  appearance="outline" 
  [ngClass]="{
    'has-value': !!value,
    'full-width': fullWidth
  }">
    <mat-label>{{label}}</mat-label>
    <mat-select [disabled]="disabled" [(value)]="value" (selectionChange)="onSelectionChange($event)" [attr.readOnly]="readOnly ? true : null">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field> 
</div>