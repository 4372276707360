<div class="lua-objet-workspace" 
[ngClass]="{
  'size-m': size === 'medium',
  'size-l': size === 'large'
}">
  <lua-icone
    [iconName]="iconName"
    [customIconName]="customIconName"
    [width]="iconWidth"
    [height]="iconHeight"
    [fontSize]="iconFontSize"
    [isIcon]="!!customIconName"
    [color]="'CouleurPrimaire'"
  ></lua-icone>
  <div class="title">
    {{ title }}
  </div>
</div>
