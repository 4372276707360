import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'lua-bouton-toolbar',
  templateUrl: './bouton-toolbar.component.html',
  styleUrls: ['./bouton-toolbar.component.css'],
})
export class BoutonToolbarComponent implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.color) {
      this.el.nativeElement.style.setProperty(
        '--my-color',
        `var(--${this.color})`
      );
    }
  }

  @Input() iconName = '';

  @Input() label = '';

  @Input() labelType: 'none' | 'horizontal' | 'vertical' = 'none';

  @Input() disabled = false;

  @Input() primary = false;

  @Input() color?: string;

  @Input() mode: 'filled' | 'outlined' | '' = 'outlined';

  @Output() onClick = new EventEmitter<Event>();

  handleClick(event: Event) {
    this.onClick.emit(event);
  }

  getColorIcon() {
    return this.primary ? 'Blanc' : 'CouleurPrimaire';
  }

  getClasses() {
    return {
      default: this.labelType === 'none',
      horizontal: this.labelType === 'horizontal',
      vertical: this.labelType === 'vertical',
      primary: this.primary,
      disabled: this.disabled,
    };
  }
}
