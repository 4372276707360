<div class="lua-tuile" [ngClass]="{ disabled: disabled }">
  <lua-badge [number]="badgeCount">
    <div class="icon-container">
      <lua-icone
        [mode]="'outlined'"
        [fontSize]="'30px'"
        [iconName]="iconName"
      ></lua-icone>
    </div>
    <div class="label">{{ label }}</div>
  </lua-badge>
</div>
