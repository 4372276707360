<div class="lua-list-item" [class.selected]="isSelected" [class.disabled]="isDisabled">
	<div class="icon">
		<lua-icone *ngIf="iconName" [mode]="iconMode" [iconName]="iconName" [color]="iconColor"></lua-icone>
	</div>
	<ng-container *ngIf="titre">
		<h2>{{text}}</h2>
	</ng-container>
	<ng-container *ngIf="!titre">
		<div class="text-container">
			<p>{{ text }}</p>
			<small *ngIf="subtext">{{subtext}}</small>
		</div>
	</ng-container>
	<div class="end-content">
		<p *ngIf="endText">{{ endText }}</p>
		<div class="icon-end">
			<lua-icone *ngIf="endIconName" [mode]="iconMode" [iconName]="endIconName" [color]="iconColor"></lua-icone>
		</div>
	</div>
</div>