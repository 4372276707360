import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'lua-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
})
export class StepperComponent implements AfterContentInit, OnChanges {
  @ContentChildren(TemplateRef) stepContents!: QueryList<TemplateRef<any>>;
  @Input() stepNames: string[] = [];
  @Input() withContent = false;
  @Input() currentStep = 0;
  @Input() cliquable = false;
  @Output() onStepClick = new EventEmitter<number>();

  steps: {name: string; content: TemplateRef<any> | null}[] = [];

  // Type amélioré pour les classes
  stepClasses: Record<string, boolean>[] = [];
  dividerClasses: {
    left: Record<string, boolean>;
    right: Record<string, boolean>;
  }[] = [];

  constructor() {}

  ngAfterContentInit() {
    if (this.withContent) {
      this.steps = this.stepContents.toArray().map((content, index) => ({
        name: this.stepNames[index] || `Step ${index + 1}`,
        content,
      }));
    } else {
      this.steps = this.stepNames.map((name, index) => ({
        name: name || `Step ${index + 1}`,
        content: null,
      }));
    }
    this.updateClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentStep'] && !changes['currentStep'].firstChange) {
      this.goToStep(changes['currentStep'].currentValue);
    }
  }

  handleStepClick(currentStep: number) {
    this.onStepClick.emit(currentStep);
  }

  goToStep(index: number) {
    this.currentStep = index;
    this.updateClasses();
  }

  // Cette méthode met à jour toutes les classes en fonction de l'étape actuelle
  updateClasses() {
    this.stepClasses = this.steps.map(
      (step, index): Record<string, boolean> => {
        return {
          completed: index < this.currentStep,
          current: index === this.currentStep,
          upcoming: index > this.currentStep,
          'first-step': index === 0,
          'last-step': index === this.steps.length - 1,
        };
      }
    );

    this.dividerClasses = this.steps.map(
      (
        step,
        index
      ): {left: Record<string, boolean>; right: Record<string, boolean>} => {
        return {
          left: {'completed-divider': index <= this.currentStep},
          right: {'completed-divider': index < this.currentStep},
        };
      }
    );
  }
}
