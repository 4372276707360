import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lua-loader-circulaire',
  templateUrl: './loader-circulaire.component.html',
  styleUrls: ['./loader-circulaire.component.css'],
})
export class LoaderCirculaireComponent implements OnInit {
  @Input() size = 60;
  @Input() color = 'Raspberry';
  @Input() speed = 1; // Vitesse en secondes
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.setProperty('--loader-size', `${this.size}px`);

    this.el.nativeElement.style.setProperty(
      '--loader-color',
      `var(--${this.color})`
    );

    this.el.nativeElement.style.setProperty('--loader-speed', `${this.speed}s`);
  }
}
