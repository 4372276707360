<div class="panneau-comment-transmission">
	<div class="panneau-comment-transmission__container">
		<div class="container__content">
			<h2>Commentaire</h2>
			<form [formGroup]="commentForm" (ngSubmit)="handleSubmit($event)">
				<div class="content__formulaire">
					<div class="formulaire__input">
						<lua-input [fullWidth]="true" label="Transmission, donnée, intervention, résultat"
							formControlName="description" multiline="true"></lua-input>
					</div>
					<ng-container *ngFor="let image of this.listDoc">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteOldFile($event)"></di-carte-photo>
					</ng-container>
					<ng-container *ngFor="let image of this.listNewFile">
						<di-carte-photo [photoFile]="image" [edit]="true" (delete)="deleteNewFile($event)"></di-carte-photo>
					</ng-container>
					<div class="formulaire__lua-bouton-texte">
						<di-input-file (inputClick)="handleFileSelected($event)"></di-input-file>
					</div>
					<lua-alerte *ngIf="isError" [filled]="true" [fullWidth]="true" type="error" iconName="info"
						[message]="errorMessage"></lua-alerte>
					<div class="formulaire__lua-action-principale">
						<lua-action-principale [fullWidth]="true" libelle="Enregistrer le commentaire"
							(click)="handleSubmit($event)"></lua-action-principale>
					</div>
					<div class="formulaire__lua-bouton-texte">
						<lua-bouton-texte [fullWidth]="true" libelle="Annuler" (click)="close()"></lua-bouton-texte>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>