import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ErrorService } from 'apps/idl/src/anakin/features/connexion/services/error.service';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { IdHelper } from '../helpers/idHelper';
import { MapHelper } from '../helpers/mapHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { IGroup } from '../model';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { UserData } from '../model/application/UserData';
import { Version } from '../model/application/Version';
import { ConfigData } from '../model/config/ConfigData';
import { EPrefix } from '../model/EPrefix';
import { WorkspaceSelectionCancelledError } from '../model/errors/WorkspaceSelectionCancelledError';
import { ESuffix } from '../model/ESuffix';
import { Database } from '../model/store/Database';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../model/store/EStoreEventStatus';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { ESyncType } from '../model/store/ESyncType';
import { IDatabaseConfig } from '../model/store/IDatabaseConfig';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IStoreEvent } from '../model/store/IStoreEvent';
import { EInput } from '../model/uiMessage/EInput';
import { IWorkspace } from '../model/workspaces/IWorkspace';
import { IWorkspaceInfo } from '../model/workspaces/IWorkspaceInfo';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { ISite } from '../modules/sites/models/isite';
import { PatternsHelper } from '../modules/utils/helpers/patterns.helper';
import { ApplicationService } from './application.service';
import { DatabaseDocumentInitializerService } from './databaseDocumentInitializer.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { PageManagerService } from './pageManager.service';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';

@Injectable({ providedIn: "root" })
export class WorkspaceService {

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private isvcStore: Store,
		/** Service de gestion des popups et toasts. */
		private isvcUiMessage: UiMessageService,
		private isvcPageManager: PageManagerService,
		private svcError: ErrorService,
		private ioNavController: NavController,
		/** Service de gestion de l'application. */
		psvcApplication: ApplicationService,
		psvcDatabaseDocInit: DatabaseDocumentInitializerService,
		private httpClient: HttpClient,) {

		this.subscribePreparingDatabaseInitEvent(psvcApplication);
		this.waitEndInitDatabases(psvcApplication, psvcDatabaseDocInit);
	}

	private subscribePreparingDatabaseInitEvent(psvcApplication: ApplicationService): void {
		psvcApplication.appEvent$
			.pipe(
				filter((poEvent: IApplicationEvent) => poEvent.type === EApplicationEventType.StoreEvent &&
					(poEvent as IStoreEvent).data.status === EStoreEventStatus.preparing
				),
				tap(
					_ => this.prepareWorkspaceDatabases(),
					poError => console.error("WS.S:: Erreur récupération événement d'application :", poError)
				)
			)
			.subscribe();
	}

	/** Attend la fin de l'initialisation des bases de données afin de demander la création des documents spécifiques des bases de données de workspace.
	 * @param psvcApplication Service de l'application permettant de savoir quand les bases de données sont initialisées.
	 * @param psvcDesignDocInit Service d'initialisation des documents de bases de données (designs docs et doc spéciaux).
	 */
	private waitEndInitDatabases(psvcApplication: ApplicationService, psvcDesignDocInit: DatabaseDocumentInitializerService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(
				tap(_ => {
					this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace, false)
						.forEach((psId: string) => {
							const lsWorkspaceGuid: string = IdHelper.extractIdWithoutPrefixAndSuffix(psId, EPrefix.workspace, ESuffix.workspace);
							const loWorkspace: IWorkspace = {
								_id: EPrefix.workspace + lsWorkspaceGuid,
								name: `Espace de ${UserData.current.firstName} ${UserData.current.lastName}`.trim(),
								userId: UserData.current.name,
								databases: this.isvcStore.getDatabaseIdsByFragmentId(lsWorkspaceGuid),
								permissions: []
							};
							psvcDesignDocInit.addDocument({ document: loWorkspace, databaseIds: [psId] });
						});
				})
			)
			.subscribe();
	}

	/** Construit l'identifiant d'une base en fonction du domaine courant et de l'identifiant d'un workspace.
	 * @param peRole Rôle de la base de données du workspace.
	 * @param psWorkspaceId Identifiant du workspace.
	 */
	private buildDatabaseIdFromWorkspaceId(peRole: EDatabaseRole, psWorkspaceId: string = null): string {
		return this.getDatabaseId(psWorkspaceId, peRole);
	}

	/** Récupère le document descriptif de l'espace de travail.
	 * @param psWorkspaceId 
	 * @returns 
	 */
	public getWorkspaceDocument<T extends IWorkspace = IWorkspace>(psWorkspaceId: string): Observable<T> {
		const lsWorkspaceGuid: string = IdHelper.extractIdWithoutPrefixAndSuffix(psWorkspaceId, EPrefix.workspace, ESuffix.workspace);
		const loDataSource: IDataSource = {
			databaseId: this.buildDatabaseIdFromWorkspaceId(EDatabaseRole.workspace, psWorkspaceId),
			viewParams: {
				key: EPrefix.workspace + lsWorkspaceGuid,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<T>(loDataSource, false);
	}

	/** Retourne un tableau de workspaces qui correspondent aux identifiants en paramètres.
	 * @param paWorkspaceIds Identifiants des workspaces à récupérer.
	 */
	private getWorkspacesFromIds(paWorkspaceIds: Array<string>): Observable<Array<IWorkspace>> {
		return from(paWorkspaceIds)
			.pipe(
				mergeMap((psWorkspaceId: string) => {
					const loDataSource: IDataSource = {
						databaseId: `${ConfigData.appInfo.coreDatabasePrefix}${psWorkspaceId}${ESuffix.workspace}`,
						viewParams: { key: psWorkspaceId, include_docs: true }
					};
					return this.isvcStore.get(loDataSource);
				}),
				filter((paResults: IWorkspace[]) => ArrayHelper.hasElements(paResults)),
				map((paResults: IWorkspace[]) => ArrayHelper.getFirstElement(paResults)),
				toArray()
			);
	}

	/** Renvoie un identifiant de base de données.
	 * @param psId Identifiant du workspace.
	 * @param peRole Rôle de la base de données.
	 */
	private getDatabaseId(psId: string, peRole: EDatabaseRole): string {
		const laStoreDatabasesIdsByRole: string[] = this.isvcStore.getDatabasesIdsByRole(peRole);
		let lsDataBaseId: string;

		if (laStoreDatabasesIdsByRole.length === 1)
			return ArrayHelper.getFirstElement(laStoreDatabasesIdsByRole);
		else {
			if (!StringHelper.isBlank(psId)) {
				lsDataBaseId = MapHelper.valuesToArray(this.isvcStore.getDatabases())
					.find((poDatabase: Database) => poDatabase.hasRole(peRole) && poDatabase.id.indexOf(psId) !== -1)
					?.id;
			}

			return lsDataBaseId;
		}
	}

	/** Affiche une alerte permettant de choisir un workspace de destination et retourne l'id de la base sélectionnée. 
	 * @param peRole Rôle de la base de données du workspace.
	 */
	public requestWorkspaceSelection(peRole: EDatabaseRole): Observable<string> {
		const lsDbIdWithoutWorkspace: string = this.buildDatabaseIdFromWorkspaceId(peRole);

		if (lsDbIdWithoutWorkspace)
			return of(lsDbIdWithoutWorkspace);
		else {
			return this.getWorkspacesFromIds(UserData.current.workspaceInfos.map((poWorkspace: IWorkspaceInfo) => poWorkspace.id))
				.pipe(
					mergeMap((paWorkspaces: IWorkspace[]) => this.innerRequestWorkspaceSelection_createAlert(paWorkspaces)),
					mergeMap((poAlert: HTMLIonAlertElement) => {
						poAlert.present();
						return from(poAlert.onDidDismiss());
					}),
					mergeMap((poSelectedItem: OverlayEventDetail<{ values: string }>) => poSelectedItem.data && !StringHelper.isBlank(poSelectedItem.data.values) ?
						of(this.buildDatabaseIdFromWorkspaceId(peRole, poSelectedItem.data.values)) : throwError(new WorkspaceSelectionCancelledError())
					),
					catchError((poError) => {
						if (poError instanceof WorkspaceSelectionCancelledError)
							console.warn(`WS.S:: Sélection annulée.`);
						else
							console.error("WS.S:: Erreur lors de l'ouverture de la fenêtre de séléction des workspaces.", poError);

						return throwError(poError);
					})
				);
		}
	}

	public selectCurrentWorkspace(): Observable<IWorkspaceInfo> {
		let loPopupParams: ShowMessageParamsPopup;
		const loAppVersion: Version = Version.fromString(ConfigData.appInfo.appVersion);
		const laWorkspaceInfos: IWorkspaceInfo[] = UserData.current.workspaceInfos.filter((poWorkspaceInfo: IWorkspaceInfo) => {
			const loWorkSpaceRange: { minVersion: Version, maxVersion: Version } = {
				maxVersion: poWorkspaceInfo.maxVersion ? Version.fromString(poWorkspaceInfo.maxVersion) : Version.maxVersion,
				minVersion: poWorkspaceInfo.minVersion ? Version.fromString(poWorkspaceInfo.minVersion) : Version.zeroVersion
			};

			return loAppVersion.compareTo(loWorkSpaceRange.minVersion) >= 0 && loAppVersion.compareTo(loWorkSpaceRange.maxVersion) <= 0;
		});

		if (laWorkspaceInfos.length === 0) {
			loPopupParams = new ShowMessageParamsPopup({
				header: "Attention !",
				message: "Vous ne disposez d'aucun espace de travail, veuillez contacter votre administrateur.",
				backdropDismiss: false,
				cssClass: "z-index-max"
			}); // z-index-max pour éviter la superposition avec le loader d'initialisation
		}
		else if (laWorkspaceInfos.length === 1)
			return of(ArrayHelper.getFirstElement(laWorkspaceInfos));
		else {
			loPopupParams = new ShowMessageParamsPopup({
				header: "Sélectionnez un espace de travail",
				inputs: laWorkspaceInfos.map((poWs: IWorkspaceInfo) => ({ type: EInput.radio, name: poWs.name, value: poWs, label: poWs.name, placeholder: "", id: "", checked: false })),
				buttons: [{ text: "Valider", cssClass: "", role: undefined, handler: ((poResult: IWorkspaceInfo) => !ObjectHelper.isNullOrEmpty(poResult)) }],
				backdropDismiss: false,
				cssClass: "z-index-max"
			});
		}

		return from(this.isvcUiMessage.ioAlertController.create(loPopupParams))
			.pipe(
				mergeMap((poAlert: HTMLIonAlertElement) => {
					poAlert.present();
					return from(poAlert.onDidDismiss());
				}),
				map((poSelectedItem: OverlayEventDetail<{ values: IWorkspaceInfo }>) => poSelectedItem.data.values),
				tap((poWorksapceInfo: IWorkspaceInfo) => UserData.current.workspaceInfos = [poWorksapceInfo]),
				catchError((poError) => {
					if (poError instanceof WorkspaceSelectionCancelledError)
						console.warn(`WS.S:: Sélection annulée.`);
					else
						console.error("WS.S:: Erreur lors de l'ouverture de la fenêtre de séléction des workspaces.", poError);

					return throwError(poError);
				})
			);
	}

	public selectCurrentWorkspaceAnakin(): Observable<IWorkspaceInfo> {
		const loAppVersion: Version = Version.fromString(ConfigData.appInfo.appVersion);
		const laWorkspaceInfos: IWorkspaceInfo[] = UserData.current.workspaceInfos.filter((poWorkspaceInfo: IWorkspaceInfo) => {
			const loWorkSpaceRange: { minVersion: Version, maxVersion: Version } = {
				maxVersion: poWorkspaceInfo.maxVersion ? Version.fromString(poWorkspaceInfo.maxVersion) : Version.maxVersion,
				minVersion: poWorkspaceInfo.minVersion ? Version.fromString(poWorkspaceInfo.minVersion) : Version.zeroVersion
			};

			return loAppVersion.compareTo(loWorkSpaceRange.minVersion) >= 0 && loAppVersion.compareTo(loWorkSpaceRange.maxVersion) <= 0;
		});

		if (laWorkspaceInfos.length === 0) {
			const messageError = "Vous ne disposez d'aucun espace de travail, veuillez contacter votre administrateur";
			this.svcError.setErrorConnexion(messageError);
			return throwError(messageError);
		} else if (laWorkspaceInfos.length === 1) {
			return of(ArrayHelper.getFirstElement(laWorkspaceInfos));
		} else {
			return of(null);
		}
	}

	public getWorkspaces(): IWorkspaceInfo[] {
		const appVersion: Version = Version.fromString(ConfigData.appInfo.appVersion);
		const workspaceInfos: IWorkspaceInfo[] = UserData.current.workspaceInfos.filter((workspaceInfo: IWorkspaceInfo) => {
			const workSpaceRange: { minVersion: Version, maxVersion: Version } = {
				maxVersion: workspaceInfo.maxVersion ? Version.fromString(workspaceInfo.maxVersion) : Version.maxVersion,
				minVersion: workspaceInfo.minVersion ? Version.fromString(workspaceInfo.minVersion) : Version.zeroVersion
			};

			return appVersion.compareTo(workSpaceRange.minVersion) >= 0 && appVersion.compareTo(workSpaceRange.maxVersion) <= 0;
		});
		return workspaceInfos;
	}

	private innerRequestWorkspaceSelection_createAlert(paWorkspaces: IWorkspace[]): Promise<HTMLIonAlertElement> {
		const loPopupParams = new ShowMessageParamsPopup();
		loPopupParams.buttons = [
			{ text: "Annuler", cssClass: "", role: undefined, handler: (psResult?: string) => { this.isvcPageManager.closeAlert(); return false; } },
			{ text: "Réessayer", cssClass: "", role: undefined, handler: ((psResult: string) => !StringHelper.isBlank(psResult)) }
		];
		loPopupParams.inputs = paWorkspaces.map((poWs: IWorkspace) => ({ type: EInput.radio, name: poWs.name, value: poWs._id, label: poWs.name, placeholder: "", id: "", checked: false }));
		loPopupParams.header = "Sélectionnez l'espace de travail";
		return this.isvcUiMessage.ioAlertController.create(loPopupParams);
	}

	/** Prépare l'initialisation des bases de données en appiquant les patterns des bases de données de workspace.
	 * @param poDocument Objet de config des bases de données récupéré sur couch.
	 */
	private prepareWorkspaceDatabases(): Array<IDatabaseConfig> {
		// Si l'utilisateur courant est renseigné, vérification tablerau non vide sinon un log d'erreur est levé.
		if (UserData.current && ArrayHelper.hasElements(UserData.current.workspaceInfos) && ArrayHelper.hasElements(ConfigData.workspaceDatabases)) {
			// Pour chaque base de données de workspace, on la transforme en base de données standard en appliquant les potentiels patterns présents dans leur id.
			ConfigData.workspaceDatabases.forEach((poItem: IDatabaseConfig) =>
				ConfigData.databases = ConfigData.databases.concat(this.transformWorkspaceDatabaseToDatabases(ObjectHelper.clone(poItem)))
			);
		}

		return ConfigData.databases;
	}

	public getUserWorkspaceInfo(psWorkspaceId: string): IWorkspaceInfo {
		try {
			const loWorkspaceInfo: IWorkspaceInfo =
				ArrayHelper.getFirstElement(UserData.current.workspaceInfos.filter((poWorkspaceInfo: IWorkspaceInfo) => poWorkspaceInfo.id === psWorkspaceId));

			if (loWorkspaceInfo)
				return loWorkspaceInfo;
			else
				throw new Error("No workspace info available.");
		}
		catch (poError) {
			const lsErrorMessage = `Cannot retreive workspace info for ID "${psWorkspaceId}".`;
			console.error(`WS.S:: ${lsErrorMessage}`, poError);
			throw new Error(lsErrorMessage);
		}
	}

	/** Transforme le pattern de l'identifiant de base de données de workspace en identifiant "normal" : "db_{{pattern1}}\_{{pattern2}}" -> "db_truc_muche".
	 * 0 à n patterns peuvent être utilisés.
	 * @param poWorkspaceDatabase Objet récupéré sur couch qui caractérise la base de données.
	 * @param psPatternedDatabaseId Identifiant de la base de données de workspace à créer.
	 * @param paDatabases Bases de données de workspace à créer.
	 * @param pnStartIndex Index de départ à analyser.
	 */
	private transformWorkspaceDatabaseToDatabases(poWorkspaceDatabase: IDatabaseConfig, psPatternedDatabaseId: string = "",
		paDatabases: Array<IDatabaseConfig> = [], pnStartIndex: number = 0): Array<IDatabaseConfig> {

		let lnEndIndex: number = poWorkspaceDatabase.id.indexOf(PatternsHelper.C_START_PATTERN);

		if (lnEndIndex >= 0) { // présence d'un pattern.
			psPatternedDatabaseId += poWorkspaceDatabase.id.substring(pnStartIndex, lnEndIndex);
			pnStartIndex = lnEndIndex;
			lnEndIndex = poWorkspaceDatabase.id.indexOf(PatternsHelper.C_END_PATTERN);
			const lsPattern: string = poWorkspaceDatabase.id.substring(pnStartIndex, lnEndIndex + PatternsHelper.C_END_PATTERN.length); // Début pattern à lnStartIndex, donc pour supprimer les accolades, on ajoute 2.
			pnStartIndex = lnEndIndex;

			if (lsPattern.indexOf("*user.workspaceIds") >= 0) { // Tableau d'identifiants.
				if (ArrayHelper.hasElements(UserData.current.workspaceInfos)) {
					UserData.current.workspaceInfos.forEach((poWorkspace) => {
						paDatabases.push(this.createDatabaseConfig(poWorkspaceDatabase.id.replace(lsPattern, poWorkspace.id), poWorkspace.id, poWorkspaceDatabase));
					});
				}
			}
			else if (lsPattern.indexOf("role") >= 0) { // Rôle.
				const leRole: EDatabaseRole = ArrayHelper.hasElements(poWorkspaceDatabase.roles) ?
					ArrayHelper.getFirstElement(poWorkspaceDatabase.roles) : poWorkspaceDatabase.role;

				if (paDatabases.length === 0)
					psPatternedDatabaseId = psPatternedDatabaseId.replace(lsPattern, leRole);
				else {
					for (let lnIndex = paDatabases.length - 1; lnIndex >= 0; --lnIndex) {
						paDatabases[lnIndex].id = paDatabases[lnIndex].id.replace(lsPattern, leRole);
					}
				}
			}

			poWorkspaceDatabase.id = poWorkspaceDatabase.id.substring(lnEndIndex + 2); // On supprime le morceau de l'identifiant déjà traité.
			return this.transformWorkspaceDatabaseToDatabases(poWorkspaceDatabase, psPatternedDatabaseId, paDatabases, pnStartIndex);
		}
		else // Pas de pattern.
			return ArrayHelper.hasElements(paDatabases) ? paDatabases : [this.createDatabaseConfig(psPatternedDatabaseId, null, poWorkspaceDatabase)];
	}

	private createDatabaseConfig(psDatabaseId: string, psWorkspaceId: string, poWorkspaceDatabaseConfig: IDatabaseConfig): IDatabaseConfig {
		const loWorkspaceInfo: IWorkspaceInfo = this.getUserWorkspaceInfo(psWorkspaceId);

		return {
			...poWorkspaceDatabaseConfig,
			id: psDatabaseId,
			syncType: (loWorkspaceInfo && loWorkspaceInfo.shared) ? poWorkspaceDatabaseConfig.syncType : ESyncType.none,
			workspaceId: psWorkspaceId
		};
	}

	/** Récupère l'identifiant du workspace en fonction d'un identifiant de base de données.
	 * @param psDatabaseId Identifiant de la base de données dont on veut récupérer l'identifiant de workspace.
	 * @returns null si le workspace ID ne peut être déterminé.
	 */
	public getWorkspaceIdFromDatabaseId(psDatabaseId: string): string {
		try {
			if (StringHelper.isBlank(psDatabaseId))
				return null;
			else {
				const lnWsPrefixIndex = psDatabaseId.indexOf(EPrefix.workspace.toString());

				if (lnWsPrefixIndex < 0)
					return null;
				else
					return psDatabaseId.substring(lnWsPrefixIndex, psDatabaseId.lastIndexOf("_"));
			}
		}
		catch (poError) {
			return null;
		}
	}

	/** Récupère l'identifiant de la base de données de workspace associée à la base de données passée en paramètre, "undefined" si non trouvée.
	 * @param psDatabaseId Identifiant de la base de données dont il faut retrouver la base de données de workspace associée.
	 */
	public getWorkspaceDatabaseIdFromDatabaseId(psDatabaseId: string): string {
		const lsWorkspaceId: string = this.getWorkspaceIdFromDatabaseId(psDatabaseId);
		return StringHelper.isBlank(lsWorkspaceId) ? this.getDefaultWorkspaceDatabaseId() : this.getDatabaseIdFromWorkspaceIdAndRole(lsWorkspaceId, EDatabaseRole.workspace);
	}

	/** Récupère l'identifaint du worskpace d'où provient le modèle
	 * @param poModel Modèle dont il faut retrouver l'identifiant du workapce de provenance.
	 */
	public getWorkspaceIdFromModel<T extends IStoreDocument>(poModel: T): string {
		return this.getWorkspaceIdFromDatabaseId(StoreHelper.getDatabaseIdFromCacheData(poModel));
	}

	/** Récupère l'identifiant d'une base de données dont on indique l'identifiant du workspace et le rôle qu'elle a.
	 * @param psWorkspaceId Identifiant de workspace pour retrouver l'identifiant de la base de données à récupérer.
	 * @param peRole Rôle de la base de données à récupérer.
	 */
	public getDatabaseIdFromWorkspaceIdAndRole(psWorkspaceId: string, peRole: EDatabaseRole): string {
		return StringHelper.isBlank(psWorkspaceId) || !peRole ? "" : this.isvcStore.getDatabasesIdsByRole(peRole).find((psId: string) => psId.indexOf(psWorkspaceId) !== -1);
	}

	public getDefaultWorkspaceDatabaseId(): string {
		const laResults: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace).filter((psId: string) => psId.endsWith(ESuffix.workspace));

		if (!ArrayHelper.hasElements(laResults))
			throw new Error("No default workspace database found.");
		else if (laResults.length > 1)
			throw new Error("More than one default workspace database found.");
		else
			return ArrayHelper.getFirstElement(laResults);
	}

	/** Contruit les infos de workspace de l'invité. 
	 * @param psUserContactPath Chemin vers le contact.
	*/
	public buildGuestWorkspaceInfo(psUserContactPath: string): IWorkspaceInfo[] {
		const lsWorkspaceId: string = Store.getDatabaseIdFromDocumentPath(psUserContactPath);
		const laWorkspaceIdFragments: Array<string> = lsWorkspaceId.split("_");

		if (laWorkspaceIdFragments.length > 3) {
			return [{
				id: `${laWorkspaceIdFragments[2]}_${laWorkspaceIdFragments[3]}`,
				name: "Espace invité",
				shared: true,
				appId: ConfigData.appInfo.appId
			}];
		}
		else
			return null;
	}

	public isDocumentFromWorkspace(poDocument: IStoreDocument): boolean {
		return StoreHelper.getDatabaseIdFromCacheData(poDocument, "", false).indexOf(EPrefix.workspace) >= 0;
	}

	/** Récupère le nom d'un workspace à partir d'un identifiant de workspace (supprimer le préfixe `ws_`).
	 * @param psWorkspaceId Identifiant du workspace dont on veut récupérer le nom.
	 */
	public getWorkspaceNameFromId(psWorkspaceId: string): string {
		return ArrayHelper.getLastElement(psWorkspaceId.split('_'));
	}

	/** Récupère le nom d'un worskpace d'où provient le modèle.
	 * @param poModel Modèle dont il faut retrouver l'identifiant du workapce de provenance.
	 */
	public getWorkspaceNameFromModel<T extends IStoreDocument>(poModel: T): string {
		return this.getWorkspaceNameFromId(this.getWorkspaceIdFromModel(poModel));
	}

	/** Récupère un tableau des identifiants d'espace de travail auquel appartient l'utilisateur courant. */
	public getUserWorkspaceIds(): string[] {
		return UserData.current.workspaceInfos.map((poWorkspaceInfo: IWorkspaceInfo) => poWorkspaceInfo.id);
	}

	/** Récupère un tableau des identifiants d'espace de travail partagés auquel appartient l'utilisateur courant. */
	public getSharedUserWorkspaceIds(): string[] {
		return UserData.current.workspaceInfos.filter((poItem: IWorkspaceInfo) => poItem.shared)
			.map((poItem: IWorkspaceInfo) => poItem.id);
	}

	/** Récupère le site selon l'identifiant, `undefined` si non trouvé.
	 * @param psId 
	 */
	public getSite(psId: string): Observable<ISite> {
		const loDatasource: IDataSource = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId,
				include_docs: true
			}
		};

		return this.isvcStore.getOne<ISite>(loDatasource, false);
	}

	/** Sauvegarde le workspace.
	 * @param poWorkspace 
	 */
	public saveWorkspace(poWorkspace: IWorkspace): Observable<IStoreDataResponse> {
		return this.isvcStore.put(poWorkspace).pipe(
			switchMap((firstResponse: IStoreDataResponse) => {
				return from(
					this.httpClient.put<any>(
						`${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.getUserWorkspaceIds())}/updateUsers`,
						{ nameWs: poWorkspace.name },
						AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
					)
				).pipe(
					switchMap(() => {
						return of(firstResponse);
					})
				);
			})
		);
	}

	public saveWorkspaceANAKIN(name:string,color:string): Observable<boolean> {
		return from(
			this.httpClient.put<any>(
				`${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.getUserWorkspaceIds())}/updateUsers`,
				{ 
					nameWs: name,
					color : color
				},
				AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
			)
		).pipe(
			switchMap(() => {
				return of(true);
			})
		);
	}


	private httpPostWorkspace(targetUrl: string): Observable<Object> {
		return this.httpClient.post(`${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.getUserWorkspaceIds())}/${targetUrl}`, {}, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	public inviteUserToWorkspace(email: string, group: IGroup): Observable<Object> {
		return this.httpPostWorkspace(`invite/${email}?groupId=${group._id}`)
			.pipe(
				catchError(error => {
					console.error("Invite user to workspace failed: ", error);
					return throwError(error);
				})
			);
	}

	public inviteUserToWorkspaceANAKIN(email: string, groups: string[],profil:string,color:string,isAdmin:boolean): Observable<Object> {

		let dataJson = {
			email: email,
			profil: profil,
			color: color,
			sites: groups,
			isAdmin : isAdmin
		}
		return this.httpClient.post(
			`${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${ArrayHelper.getFirstElement(this.getUserWorkspaceIds())}/invitation/${email}`,
			dataJson,
			AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
		)			
		.pipe(
				catchError(error => {
					console.error("Invite user to workspace failed: ", error);
					return throwError(error);
				})
			);
	}

	//#endregion

}