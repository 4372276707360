import { EPlace } from "../../../model/EPlace";
import { EComplement } from "./ecomplement.enum";
import { IInvoiceLine } from "./iinvoice-line";

export class InvoiceLine implements IInvoiceLine {

	//#region PROPERTIES

	/** @implements */
	public acteId: string;
	/** @implements */
	public lettreCle: string;
	/** @implements */
	public coefficient: number;
	/** @implements */
	public abattement?: number;
	/** @implements */
	public price = 0;
	/** @implements */
	public extraCharge = 0;
	/** @implements */
	public partAMO = 0;
	/** @implements */
	public partAMC = 0;
	/** @implements */
	public partPatient = 0;
	/** @implements */
	public honoraires?: number;
	/** @implements */
	public date: Date;
	/** @implements */
	public place: EPlace;
	/** @implements */
	public complement?: EComplement;
	/** @implements */
	public prestationId: string;
	/** @implements */
	public seanceId?: string;
	/** @implements */
	public description: string;
	/** @implements */
	public quantity?: number;

	/** Indique si la cpam prend en charge l'acte à 100% ou non (ald exonérante) */
	/** @implements */
	public isAldExonerante?: boolean;

	/** Indique le numéro de l'acte qui sera envoyé à intellio pour faire le matching avec les actes envoyés entre l'appel et le retour à transmettreFacture */
	/** @implements */
	public numero?: number;

	/** @implements */
	public isFree?: boolean;
	//#endregion

}
